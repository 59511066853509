/**
 * @ngdoc directive
 * @name flowIconSquare
 * @module flowingly.runner.directives
 * @description This is a component for displaying a coloured square for a flow type.
 * @usage
 * ```
 * <flow-icon-square name="$ctrl.flow.name" flow-code="" class="mr-15"></flow-icon-square>
 * ```
 * ### Notes
 *  Styling for this directive will be handled by the component that is including this directive
 */

///
/// Styling for this directive will be handled by the component that is including this directive
///
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular from 'angular';
angular.module('flowingly.runner.directives').component('flowIconSquare', {
  bindings: {
    //the flow name
    name: '<',
    //the shorthand code (if not set one will be generated)
    flowCode: '<'
  },
  controller: [
    '$element',
    'avatarService',
    function (
      $element: JQuery<HTMLElement>,
      avatarService: SharedAngular.AvatarService
    ) {
      this.$onInit = function () {
        this.flowCodeToDisplay = avatarService.getFlowInitials(
          this.name,
          this.flowCode
        );
      };
    }
  ],
  template: `
            <div class ="flow-logo" title="{{$ctrl.name}}">
                {{$ctrl.flowCodeToDisplay}}
            </div>
          `
});
