'use strict';
import angular, { IWindowService } from 'angular';
import { LoginSettings } from '../interfaces/flowingly.window';
import { IStateParamsService, IStateService } from 'angular-ui-router';
import { SharedAngular } from '@Client/@types/sharedAngular';

/**
 * @ngdoc type
 * @module flowingly.runner.login
 * @name runnerLoginController
 *
 * @description Controller for login page.
 */

angular
  .module('flowingly.runner.login')
  .controller('runnerLoginController', runnerLoginController);

runnerLoginController.$inject = [
  '$state',
  '$stateParams',
  'APP_CONFIG',
  'authService',
  'sessionService',
  '$window',
  'brandingService'
];

function runnerLoginController(
  $state: IStateService,
  $stateParams: IStateParamsService,
  APP_CONFIG: AppConfig,
  authService: AuthService,
  sessionService: SharedAngular.SessionService,
  $window: IWindowService,
  brandingService: SharedAngular.BrandingService
) {
  const ctrl = this;
  ctrl.authService = authService;

  ctrl.model = {
    email: undefined,
    password: undefined
  };

  init();

  function init() {
    if (
      !authService.isAuthenticated() ||
      !sessionService.getUser() ||
      $stateParams.forceRelogin
    ) {
      ctrl.errorMessage = undefined;

      // get pre-login settings. getLoginSettings return undefined when error so dont need catch block here
      sessionService
        .getLoginSettings($window.location.hostname)
        .then((loginSettings) => {
          APP_CONFIG.populateLoginSettings(loginSettings);
          login(APP_CONFIG);
        });
    } else {
      $state.go('app.runner.flowsactive');
    }
  }

  /**
   * Function to login user in settings preference
   * @param {*} loginSettings
   */
  function login(loginSettings: LoginSettings) {
    const {
      welcomeText,
      loginAlertMessage,
      auth0UseUniversalLogin,
      logoUrl,
      favIcon
    } = loginSettings ?? {};

    brandingService.setPageTitle(loginSettings.title);
    brandingService.setFavIcon(loginSettings.favIcon);

    const loginOptions = {
      welcomeText,
      loginAlertMessage,
      auth0UseUniversalLogin,
      logoUrl,
      favIcon,
      redirectUrl: $stateParams.redirectUrl
    };

    authService.login(loginOptions);
  }
}
