/**
 * @ngdoc directive
 * @name libraryStatusBadge
 * @module flowingly.runner.directives
 * @description A simple directive for displaying an (approporiate) empty state message.
 * @usage
 * ```
 *   <flow-nothing-todo-message items="ctrl.flows"></flow-nothing-todo-message>
 * ```
 */

'use strict';
import angular from 'angular';

angular.module('flowingly.runner.library').component('libraryStatusBadge', {
  bindings: {
    status: '<'
  },
  controller: [
    function () {
      const $ctrl = this;
      this.$onInit = function () {
        const draft = 'Draft';
        const published = 'Published';
        const validated = 'Validated';

        switch ($ctrl.status) {
          case draft:
            $ctrl.class = 'val-badge--draft';
            return;

          case validated:
            console.error(
              'libraryStatusBadge: validation status 2 (Validated) no longer used'
            );
            return;

          case published:
            $ctrl.class = 'val-badge--published';
            return;
        }
      };
    }
  ],
  template: `<span class="val-badge" ng-class="$ctrl.class">{{::$ctrl.status}}</span>`
});
