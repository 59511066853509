/**
 * @ngdoc directive
 * @name runner.flow.users
 * @module flowingly.runner.setup
 * @description  This comppnent is the root for teams
 * @usage
 * ```
     
 * ``` 
 * ### Notes
 * See Also: 
 * ### Properties
 * #### Inputs
 * * users: the list of available users (JSON[])
 */
'use strict';

angular.module('flowingly.runner.setup').component('flowUsers', {
  bindings: {
    users: '<'
  },
  templateUrl:
    'Client/runner.setup/runner.setup.users/runner.setup.users.tmpl.html',
  controller: [
    'sessionService',
    'userApiService',
    'tokenService',
    function (sessionService, userApiService, tokenService) {
      const $ctrl = this;
      $ctrl.businessName =
        tokenService.getTenant().name || sessionService.getUser().businessName;
      $ctrl.handleUsersUpdated = handleUsersUpdated;

      function handleUsersUpdated() {
        // For an array that is bounded via one-way binding, a watch expression is added that does not check for object equality
        // but uses reference checking. This means that adding an element to the array will never fire the '$onChanges' method,
        // since the watcher will never be 'dirty'. Therefore, we use Angular copy ensure a new reference

        $ctrl.users = angular.copy(userApiService.users);
      }
    }
  ]
});
