'use strict';
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular, { IScope } from 'angular';
import { IStateService } from 'angular-ui-router';

class RunnerActiveController {
  static $inject = [
    '$scope',
    'flowListManager',
    'pubsubService',
    'flowsUtilityService',
    'lodashService',
    'permissionsService',
    'flowinglyConstants',
    '$state'
  ];

  public categories = [];
  public selectedCategory = {};
  public isMobile = false;
  public isReady = false;

  private allCategoriesId = 'all-categories';

  constructor(
    private $scope: IScope,
    private flowListManager: FlowListManager,
    private pubsubService: SharedAngular.PubSubService,
    private flowsUtilityService: SharedAngular.FlowsUtilityService,
    private _: Lodash,
    private permissionsService: SharedAngular.PermissionsService,
    private flowinglyConstants: SharedAngular.FlowinglyConstants,
    private $state: IStateService
  ) {
    if (
      !this.permissionsService.currentUserHasPermission(
        this.flowinglyConstants.permissions.FLOW_START
      )
    ) {
      this.$state.go('app.runner.flowsin');
      return;
    }
    this.refreshFlows().then(() => this.init());
    this.isMobile = this.flowsUtilityService.isMobileDevice();
  }

  init() {
    const activeFlowsSubscriberId = 'flowingly.runner.flows.active';
    this.pubsubService.subscribe(
      'SIGNALR_WORKFLOW_NAME_CHANGED',
      this.onFlowModelNameChanged,
      activeFlowsSubscriberId
    );
    this.pubsubService.subscribe(
      'SIGNALR_SETUP_CATEGORY_DELETED',
      this.onCategoryDeleted,
      activeFlowsSubscriberId
    );
    this.pubsubService.subscribe(
      'SIGNALR_SETUP_FLOW_MODEL_DELETED',
      this.onFlowModelDeleted,
      activeFlowsSubscriberId
    );
    this.pubsubService.subscribe(
      'SIGNALR_WORKFLOW_PUBLISHED',
      this.refreshFlows,
      activeFlowsSubscriberId
    );
    this.pubsubService.subscribe(
      'SIGNALR_WORKFLOW_UNPUBLISHED',
      this.refreshFlows,
      activeFlowsSubscriberId
    );
    this.pubsubService.subscribe(
      'SIGNALR_ACTOR_DELETED',
      (event, params) => this.removeDeletedActor(params),
      activeFlowsSubscriberId
    );

    this.$scope.$on('$destroy', () => {
      this.pubsubService.unsubscribeAll(activeFlowsSubscriberId);
    });
    this.isReady = true;
  }

  categoryClicked(categoryId) {
    this.selectedCategory = this.categories.find((c) => c.id === categoryId);
  }

  selectAllOption() {
    this.categoryClicked(this.allCategoriesId);
  }

  onCategoryDeleted() {
    this.refreshFlows();
  }

  onFlowModelDeleted() {
    this.refreshFlows();
  }

  onFlowModelNameChanged(msg, data) {
    const parsedData = JSON.parse(data);
    const flowUpdated = this.categories.some((category) =>
      category.flowModels.some((flow) => {
        if (flow.id === parsedData.id) {
          flow.name = parsedData.name;
          return true;
        }
        return false;
      })
    );
    if (flowUpdated) {
      this.triggerRenderOfUpdatedFlows();
      this.flowListManager.updateFlowNames(parsedData);
    }
  }

  addAllFlowsCategory(categories) {
    if (!categories || categories.find((c) => c.id === this.allCategoriesId)) {
      return;
    }
    const copyOfAllFlows = this._.flatMap(categories, 'flowModels');
    categories.unshift({
      name: 'All',
      id: this.allCategoriesId,
      flowModels: copyOfAllFlows
    });
  }

  refreshFlows() {
    return this.flowListManager.getCategorizedWorkflows().then((categories) => {
      this.flowsUtilityService.orderItemsAlphabetically(categories);
      this.addAllFlowsCategory(categories);
      this.categories = categories;
      this.selectAllOption();
    });
  }

  removeDeletedActor(jsonParams) {
    if (!Array.isArray(this.categories)) {
      return;
    }

    const params = JSON.parse(jsonParams);
    const updatedFlowModelIds = [];
    this.categories.forEach((category) => {
      category.flowModels.forEach((flowModel) => {
        if (flowModel.processOwnerName === params.actorName) {
          flowModel.processOwnerName = null;
          updatedFlowModelIds.push(flowModel.id);
        }
      });
    });

    if (updatedFlowModelIds.length > 0) {
      this.triggerRenderOfUpdatedFlows();
      this.flowListManager.removeDeletedProcessOwner(updatedFlowModelIds);
    }
  }

  triggerRenderOfUpdatedFlows() {
    this.categories = angular.copy(this.categories);
    this.$scope.$digest();
  }
}

angular
  .module('flowingly.runner.flows.active')
  .controller('runnerActiveController', RunnerActiveController);
