/**
 * @ngdoc type
 * @name TeamDetailController
 * @module flowingly.runner.setup
 * @description This controller is used to control add/edit team modal dialog
 */
(function () {
  'use strict';

  angular
    .module('flowingly.runner.setup')
    .controller('teamDialogController', teamDetailController);

  teamDetailController.$inject = [
    '$scope',
    'APP_CONFIG',
    'teamApiService',
    'notificationService',
    'validationService',
    'flowinglyConstants'
  ];

  function teamDetailController(
    $scope,
    APP_CONFIG,
    teamApiService,
    notificationService,
    validationService,
    flowinglyConstants
  ) {
    let $ctrl = this;

    $ctrl.team = {
      name: '',
      email: '',
      ppoc: '',
      users: []
    };

    angular.extend($ctrl.team, $scope.ngDialogData.team);

    $ctrl.isNewTeam = $ctrl.team.id === undefined;
    $ctrl.emailValidationPattern =
      validationService.getEmailValidationPattern();
    $ctrl.isFormReadonly = !!APP_CONFIG.disableAdminTeamEdit;
    $ctrl.modalTitle = $ctrl.isNewTeam
      ? 'Add'
      : $ctrl.isFormReadonly
      ? 'View'
      : 'Edit';
    $ctrl.selectedList = [];
    if ($ctrl.team.id) {
      teamApiService.getUsersInTeam($ctrl.team.id).then(function (response) {
        $ctrl.team.users = response;
        $ctrl.selectedList = decorateUserForSmartList($ctrl.team.users);
      });
    }

    $ctrl.editTeam = () => {
      if (APP_CONFIG.disableAdminTeamEdit) return;

      $ctrl.team.users = $ctrl.selectedList; // Recap with latest selected users list

      teamApiService.saveTeam($ctrl.team).then(
        () => {
          $scope.closeThisDialog(true);
          notificationService.showSuccessToast('Team saved');
        },
        (response) => {
          $ctrl.currentForm.$invalid = true;
          $ctrl.errorOnSubmit = true;
          $ctrl.errorMessagesOnSubmit =
            response.data && response.data.message
              ? response.data.message
              : response.statusText;
          notificationService.showErrorToast('Error when save team');
        }
      );
    };

    $ctrl.cancelEditTeam = () => {
      $scope.closeThisDialog();
    };

    function decorateUserForSmartList(users) {
      let usersWithDisplayName = [];

      users.forEach((u) => {
        u.displayName = u.fullName;
        u.searchEntityType = flowinglyConstants.searchEntityType.USER;
        usersWithDisplayName.push(u);
      });

      return usersWithDisplayName;
    }
  }
})();
