interface ImportError {
  issueId: number;
  issue: string;
  affectedRows: number[];
  howToFix: string;
}

// this enum is a reflection of
// UserIMportRequest's enum
const ROW_STATUS = {
  PENDING: 0,
  SKIPPED: 1,
  FAILED: 400,
  FAILED_DUPLICATE_ROW: 401,
  FAILED_MANAGER_MISSING: 410,
  FAILED_MISSING_HEADERS: 500,
  FAILED_BAD_HEADER: 501,
  FAILED_BAD_EMAIL: 506,
  FAILED_BAD_CONNECTION_TYPE: 505,
  DONE: 100
};

class ImportError {
  public issueId: number;
  public issue: string;
  public howToFix: string;
  public affectedRows: number[];

  constructor({
    issue = null,
    howToFix = null,
    issueId = null,
    affectedRows = []
  } = {}) {
    this.issue = issue;
    this.howToFix = howToFix;
    this.issueId = issueId;
    this.affectedRows = affectedRows;
  }

  public clone(): ImportError {
    const err = new ImportError();
    err.issue = this.issue;
    err.issueId = this.issueId;
    err.howToFix = this.howToFix;
    return err;
  }
}

const ErrorTypes = {
  [ROW_STATUS.FAILED]: new ImportError({
    issueId: ROW_STATUS.FAILED,
    issue: 'Import has failed for an unknown reason',
    howToFix: 'Try again after ensuring the csv is correct'
  }),
  [ROW_STATUS.FAILED_MANAGER_MISSING]: new ImportError({
    issueId: ROW_STATUS.FAILED_MANAGER_MISSING,
    issue: 'Manager email is neither in the CSV nor in the database',
    howToFix:
      'Ensure the manager email exists in either the CSV or the database'
  }),
  [ROW_STATUS.FAILED_DUPLICATE_ROW]: new ImportError({
    issueId: ROW_STATUS.FAILED_DUPLICATE_ROW,
    issue: 'Duplicate row of the same email found',
    howToFix: 'Change the email or remove the duplicate row'
  }),
  [ROW_STATUS.FAILED_BAD_HEADER]: new ImportError({
    issueId: ROW_STATUS.FAILED_BAD_HEADER,
    issue: 'Unknown header found',
    howToFix: 'Please check the spelling or the casing of the csv headers'
  }),
  [ROW_STATUS.FAILED_BAD_CONNECTION_TYPE]: new ImportError({
    issueId: ROW_STATUS.FAILED_BAD_CONNECTION_TYPE,
    issue: 'Bad connection_type entry',
    howToFix: 'Please either input only sso, flowingly or leave it blank'
  }),
  [ROW_STATUS.FAILED_MISSING_HEADERS]: new ImportError({
    issueId: ROW_STATUS.FAILED_MISSING_HEADERS,
    issue: 'Missing column headers',
    howToFix:
      'Please add first_name, last_name & email as column headers to row 1 in your CSV file'
  }),
  [ROW_STATUS.FAILED_MISSING_HEADERS]: new ImportError({
    issueId: ROW_STATUS.FAILED_MISSING_HEADERS,
    issue: 'Missing column headers',
    howToFix:
      'Please add first_name, last_name & email as column headers to row 1 in your CSV file'
  })
};

class ImportErrorService {
  static $inject = ['lodashService'];
  public ROW_STATUS = ROW_STATUS;

  private errors: ImportError[] = [];
  constructor(private _) {}

  public getErrors() {
    return this.errors;
  }

  public hasErrors(): boolean {
    return this.errors.length > 0;
  }

  /**
   * Try and merge with an existing error first before attempting to
   * push a new error unto the error stack.
   * @param newErr
   */
  public addError(newErr: ImportError) {
    const errFound = this.errors.find((err) => err.issueId == newErr.issueId);
    if (errFound == null) {
      const clone = ErrorTypes[newErr.issueId].clone();
      if (newErr.affectedRows && newErr.affectedRows.length) {
        clone.affectedRows = this._.chain(clone.affectedRows)
          .concat(newErr.affectedRows)
          .uniq()
          .sort()
          .value();
      }
      this.errors.push(clone);
    } else {
      errFound.affectedRows = this._.chain(errFound.affectedRows)
        .concat(newErr.affectedRows)
        .uniq()
        .sort()
        .value();
    }
  }

  public setEmpty() {
    this.errors = [];
  }

  public removeError(error: ImportError) {
    this._.remove(
      this.errors,
      (err: ImportError) =>
        err.issue == error.issue && err.affectedRows == error.affectedRows
    );
  }
}

angular
  .module('flowingly.runner.import')
  .service('importErrorService', ImportErrorService);
