import { SharedAngular } from '@Client/@types/sharedAngular';
import angular, { IScope } from 'angular';
import { IStateService } from 'angular-ui-router';

class RunnerProcessMapsV2Controller {
  allowProcessMap: boolean;
  hasLoaded: boolean;
  categoriesCount: number;

  constructor(
    private $scope: IScope,
    private $state: IStateService,
    private pubsubService: SharedAngular.PubSubService,
    private APP_CONFIG: AppConfig,
    private sessionService: SharedAngular.SessionService
  ) {
    this.sessionService.onReady(() => {
      this.allowProcessMap = this.APP_CONFIG.allowProcessMap;
    });
  }

  $onInit() {
    const processMapSubscriberId = 'flowingly.runner.processMapV2Controller';
    this.pubsubService.subscribe(
      'PROCESSMAPV2_LOADED',
      this.onProcessMapLoaded.bind(this),
      processMapSubscriberId
    );

    this.pubsubService.subscribe(
      'PROCESSMAPV2_CLICKED',
      this.onProcessMapClicked.bind(this),
      processMapSubscriberId
    );
    this.$scope.$on('$destroy', () => {
      this.pubsubService.unsubscribeAll(processMapSubscriberId);
    });
  }
  onProcessMapLoaded(event: Event, data) {
    this.hasLoaded = data.loaded;
    this.categoriesCount = data.count;
  }
  onProcessMapClicked(event: Event, processMapId: string) {
    this.$state.go('app.runner.processmapviewv2', { processMapId }, {});
  }
}

angular
  .module('flowingly.runner.processmapsv2')
  .controller('runnerProcessMapsV2Controller', [
    '$scope',
    '$state',
    'pubsubService',
    'APP_CONFIG',
    'sessionService',
    function ($scope, $state, pubsubService, APP_CONFIG, sessionService) {
      return new RunnerProcessMapsV2Controller(
        $scope,
        $state,
        pubsubService,
        APP_CONFIG,
        sessionService
      );
    }
  ]);
