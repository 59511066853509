'use strict';
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular, { IQService } from 'angular';
import { IStateParamsService, IStateProvider } from 'angular-ui-router';

// Register the application's routes configuration.
angular.module('flowingly.runner.library').config(config);

// Inject the dependencies.
config.$inject = ['$stateProvider'];

function config($stateProvider: IStateProvider) {
  $stateProvider.state('app.runner.library', {
    url: 'library',
    params: { flowId: null, title: 'Library' },
    template: `<flow-library flow-models="$ctrl.flowModels"></flow-library>`,
    controllerAs: '$ctrl',
    controller: [
      '$stateParams',
      'flowModels',
      'pubsubService',
      function (
        $stateParams: IStateParamsService,
        flowModels: any,
        pubsubService: SharedAngular.PubSubService
      ) {
        const $ctrl = this;
        //intialise the grid with some data
        $ctrl.flowModels = flowModels;

        pubsubService.subscribe(
          'SIGNALR_WORKFLOW_NAME_CHANGED',
          onFlowModelNameChanged,
          'runnerLibraryRoute'
        );
        pubsubService.subscribe(
          'SIGNALR_WORKFLOW_UNPUBLISHED',
          onFlowUnPublished,
          'runnerLibraryRoute'
        );
        pubsubService.subscribe(
          'SIGNALR_WORKFLOW_PUBLISHED',
          onFlowPublished,
          'runnerLibraryRoute'
        );
        pubsubService.subscribe(
          'SIGNALR_WORKFLOW_UNPUBLISHED_CHANGES',
          onFlowUnPublishedChanges,
          'runnerLibraryRoute'
        );

        function onFlowModelNameChanged(msg, data) {
          //update the name of the flow that just changed
          const msgDetails = JSON.parse(data);
          updateFlowModel({
            flowModelId: msgDetails.id,
            name: msgDetails.name
          });
        }
        function onFlowPublished(msg, data) {
          //update the workflow status of the flow that just changed
          const msgDetails = JSON.parse(data);
          updateFlowModel({
            flowModelId: msgDetails.flowModelId,
            status: 'Published',
            hasUnPublishedChanges: false
          });
        }
        function onFlowUnPublished(msg, data) {
          //update the workflow status of the flow that just changed
          const msgDetails = JSON.parse(data);
          updateFlowModel({
            flowModelId: msgDetails.flowModelId,
            status: 'Draft',
            hasUnPublishedChanges: false
          });
        }
        function onFlowUnPublishedChanges(msg, data) {
          //update the workflow status of the flow that just changed
          const msgDetails = JSON.parse(data);
          updateFlowModel({
            flowModelId: msgDetails.flowModelId,
            hasUnPublishedChanges: msgDetails.hasUnpublishedChanges
          });
        }

        function updateFlowModel(value) {
          const copy = angular.copy(flowModels);
          const flow = copy.find((f) => {
            return f.id === value.flowModelId;
          });

          if (!flow) return;
          // add updated the properties here
          flow.name = value.name || flow.name;
          flow.status = value.status || flow.status;
          flow.hasUnPublishedChanges =
            value.hasUnPublishedChanges || flow.hasUnPublishedChanges;

          $ctrl.flowModels = copy;
        }
      }
    ],
    resolve: {
      flowModels: [
        '$q',
        'flowModelApiService',
        'categoryApiService',
        function (
          $q: IQService,
          flowModelApiService: FlowModelApiService,
          categoryApiService: CategoryApiService
        ) {
          const models = flowModelApiService.getFlowModels();
          const cats = categoryApiService.getCategories();
          return $q.all([models, cats]).then(() => {
            return models;
          });
        }
      ]
    }
  });
}
