'use strict';
//
// This state is the base for the Template admin pages
//
import * as angular from 'angular';

angular.module('flowingly.runner.templates').config(config);

config.$inject = ['$stateProvider'];

function config($stateProvider: angular.ui.IStateProvider) {
  $stateProvider.state('app.runner.templates.admin', {
    url: '/admin',
    abstract: true,
    template: '<div ui-view></div>',
    controller: [
      '$state',
      'permissionsService',
      'flowinglyConstants',
      'sessionService',
      'APP_CONFIG',
      function (
        $state,
        permissionsService,
        flowinglyConstants,
        sessionService,
        APP_CONFIG
      ) {
        sessionService.onReady(() => {
          if (
            !APP_CONFIG.enableTemplateManagement ||
            !permissionsService.currentUserHasPermission(
              flowinglyConstants.permissions.TEMPLATE_ADMIN
            )
          ) {
            $state.go('app.runner.flowsactive');
          }
        });
      }
    ]
  });
}
