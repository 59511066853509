import { SharedAngular } from '@Client/@types/sharedAngular';
import { useService } from '@Client/runner.hooks/useService';
import { Box, Slider, Stack } from '@mui/material';
import { Diagram } from 'FlowGoJS';
import React, { useEffect, useRef, useState } from 'react';
import CustomButton from '../CustomButton/CustomButton';

type Props = {
  processMapId: string;
  onSelectedNodeDataChange: (selectedNode: unknown) => void;
  onProcessMapLoad: (map: unknown) => void;
  isVisible: boolean;
};

let diagram: Diagram;

export const ProcessMap = (props: Props) => {
  const [diagramLoaded, setDiagramLoaded] = useState(false);
  const [zoomValue, setZoomValue] = useState(100);
  const permissionsService =
    useService<SharedAngular.PermissionsService>('permissionsService');

  const appConfig = useService<AppConfig>('APP_CONFIG');

  const tempModelerUrlService = useService<SharedAngular.TempModelerUrlService>(
    'tempModelerUrlService'
  );

  const flowinglyConstants =
    useService<SharedAngular.FlowinglyConstants>('flowinglyConstants');
  const isEdit =
    permissionsService.currentUserHasPermission(
      flowinglyConstants.permissions.FLOWMODEL_UPDATE
    ) || appConfig.fmoCanPublish;

  const DEADLINETYPES: unknown = {
    none: 'None'
  };

  const initialScaleRef = useRef(null);
  let nodeDetails: unknown;
  let selectedNode: unknown;
  let processMap: unknown;

  const {
    isVisible,
    processMapId,
    onSelectedNodeDataChange,
    onProcessMapLoad
  } = props;

  const flowApiService = useService<FlowApiService>('flowApiService');
  const avatarService =
    useService<SharedAngular.AvatarService>('avatarService');
  const pubSubService =
    useService<SharedAngular.PubSubService>('pubsubService');
  const flowinglyDiagramService =
    useService<SharedAngular.FlowinglyDiagramService>(
      'flowinglyDiagramService'
    );

  const goService = useService<GoJS>('goService');

  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isVisible) {
      getDiagram().clearSelection();
    }
  }, [isVisible]);

  useEffect(() => {
    renderProcessMapDiagram();
  }, []);

  const getDiagram = () => {
    if (divRef.current === null) return null;
    const diagram = goService.Diagram.fromDiv(divRef.current);
    return diagram;
  };

  const clearDiagram = () => {
    const diagram = getDiagram();
    if (diagram !== null) {
      diagram.div = null;
    }
  };

  const sumOfHeightOfOtherParts = 250;
  const _dynamicInitialHt = window.innerHeight - sumOfHeightOfOtherParts;
  const renderDiagram = (map: any) => {
    diagram = flowinglyDiagramService.generateProcessModel({
      flow: map,
      applyAvatar: false,
      modelCustomArgs: {
        scrollMode: goService.Diagram.DocumentScroll
      },
      allowSelect: true,
      dynamicInitialHeight: _dynamicInitialHt
    });

    diagram.commandHandler.canIncreaseZoom = false;
    diagram.commandHandler.canDecreaseZoom = false;

    if (typeof diagram !== 'undefined') {
      diagram.addDiagramListener('ChangedSelection', onChangedSelection);
      (window as any).diagram = diagram;
    }

    (window as any).processMap = map;
    processMap = map;
    onProcessMapLoad(map);
    pubSubService.publish('PROCESSMAPVIEWV2_LOADED', {
      name: map.name
    });
  };

  const renderProcessMapDiagram = async () => {
    clearDiagram();
    const map = await fetchFlowData();
    map.Id = processMapId;
    map.isProcessMap = true;
    renderDiagram(map);
    setDiagramLoaded(true);
  };

  const fetchFlowData = async () => {
    const map = await flowApiService.getProcessMap(processMapId, true);
    nodeDetails = JSON.parse(map?.flowSchema ?? '[]');
    return map;
  };

  const onChangedSelection = () => {
    selectedNode = diagram.selection.first();
    const selectedNodeData =
      selectedNode instanceof goService.Node ? selectedNode.data : null;

    if (selectedNodeData == null) {
      onSelectedNodeDataChange(null);
      return;
    }

    const selectedStep = processMap.steps.find(
      (n) => n.id == selectedNodeData.id
    );

    if (typeof selectedStep !== 'undefined') {
      if (selectedStep.stepType === flowinglyConstants.taskType.CUSTOM_EMAIL) {
        selectedNode = null;
      } else {
        if (!selectedStep.fields || selectedStep.fields.length === 0) {
          flowApiService
            .getProcessMapNodeElement(selectedNodeData.id, false)
            .then((data) => {
              selectedStep.fields = data;
              selectedNode = selectedStep;
              setSeletedNodeValues(selectedNode);
              onSelectedNodeDataChange(selectedNode);
            });
        } else {
          selectedNode = selectedStep;
          setSeletedNodeValues(selectedNode);
        }
      }
    } else {
      selectedNode = selectedStep;
    }
    onSelectedNodeDataChange(selectedNode);
  };

  const setSeletedNodeValues = (selectedNode) => {
    selectedNode.deadLine = formatDisplay(
      selectedNode.deadLineNumber,
      selectedNode.deadLineType
    );
    selectedNode.plannedTime = formatDisplay(
      selectedNode.costTimeNumber,
      selectedNode.costTimeType
    );
    selectedNode.stepActor = nodeDetails.nodeDataArray.find(
      (n) => n.id == selectedNode.id
    ).actorName;
    selectedNode.reminder = formatDisplay(
      selectedNode.reminderNumber,
      selectedNode.reminderType
    );
    setupStepActorAvatar();
    selectedNode.fields.forEach((f) => {
      f.isNodeFromRunnerProcessMap = true;
      f.type = convertNumericToEnumFieldType(f.type);
    });
  };

  const formatDisplay = (num, type) => {
    let deadline = null;

    if (type == DEADLINETYPES.none) {
      return DEADLINETYPES.none;
    }

    if (num && type) {
      if (num == 1 && type != DEADLINETYPES.none) {
        type = type.slice(0, type.length - 1);
      }
      deadline = num + ' ' + type;
    }
    return deadline;
  };

  const setupStepActorAvatar = () => {
    if (selectedNode.stepActor) {
      selectedNode.actorFirstLetter = avatarService.getUserInitial(
        selectedNode.stepActor
      );
      const avatarBgColor =
        avatarService.getBgColorForProcessMapRightPaneAvatar(
          selectedNode.actorFirstLetter
        );
      $('#pmap-avatar').css('background', avatarBgColor);
    } else {
      selectedNode.stepActor.firstLetter = '?';
    }
  };

  const convertNumericToEnumFieldType = (numfield) => {
    switch (numfield) {
      case 0:
        return flowinglyConstants.formFieldType.APPROVAL_COMMENT;
      case 1:
        return flowinglyConstants.formFieldType.APPROVAL_RULE;
      case 3:
        return flowinglyConstants.formFieldType.CHECKBOX;
      case 4:
        return flowinglyConstants.formFieldType.CURRENCY;
      case 5:
        return flowinglyConstants.formFieldType.EMAIL;
      case 6:
        return flowinglyConstants.formFieldType.FILE_UPLOAD;
      case 7:
        return flowinglyConstants.formFieldType.INSTRUCTION;
      case 8:
        return flowinglyConstants.formFieldType.TABLE;
      case 9:
        return flowinglyConstants.formFieldType.TEXT;
      case 10:
        return flowinglyConstants.formFieldType.TEXTAREA;
      case 12:
        return flowinglyConstants.formFieldType.NUMBER;
      case 13:
        return flowinglyConstants.formFieldType.PASSWORD;
      case 14:
        return flowinglyConstants.formFieldType.RADIO_BUTTON_LIST;
      case 15:
        return flowinglyConstants.formFieldType.SELECT_LIST;
      case 17:
        return flowinglyConstants.formFieldType.TASK_LIST;
      case 18:
        return flowinglyConstants.formFieldType.MULTISELECT_LIST;
      case 19:
        return flowinglyConstants.formFieldType.DATE;
      case 20:
        return flowinglyConstants.formFieldType.DATETIME;
      case 21:
        return flowinglyConstants.formFieldType.SIGNATURE;
      case 23:
        return flowinglyConstants.formFieldType.LOOKUP;
      case 24:
        return flowinglyConstants.formFieldType.FORMULA;
      case 25:
        return flowinglyConstants.formFieldType.ATTACH_DOCUMENT;
      case 26:
        return flowinglyConstants.formFieldType.IMAGE;
      default:
        return numfield;
    }
  };

  function onEditFlowModelHandler(): void {
    tempModelerUrlService.openModeler(processMapId, false, false, null);
  }

  const handleSliderChange = (_event: Event, newValue: number) => {
    setZoomValue(newValue);
    if (initialScaleRef.current === null) {
      initialScaleRef.current = diagram.scale;
    }
    diagram.scale =
      initialScaleRef.current *
      Math.pow(diagram.commandHandler.zoomFactor, newValue - 100);
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey) {
        if (event.key === '+' || event.key === '=') {
          const newZoomValue = Math.min(zoomValue + 1, 200);
          handleSliderChange(null, newZoomValue);
          event.preventDefault();
        } else if (event.key === '-') {
          const newZoomValue = Math.max(zoomValue - 1, 0);
          handleSliderChange(null, newZoomValue);
          event.preventDefault();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [zoomValue]);

  return (
    <div>
      <Box id={processMapId} ref={divRef}></Box>

      {diagramLoaded && (
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Box
            width={250}
            paddingLeft={2}
            paddingRight={2}
            className={'process-map-v2-view-left-panel-process-map'}
          >
            <Stack spacing={2} direction="row" alignItems="center">
              <i className="fas fa-minus"></i>
              <Slider
                className={'process-map-v2-view-left-panel-process-map-slider'}
                min={0}
                max={200}
                value={zoomValue}
                step={1}
                onChange={handleSliderChange}
              />
              <i className="fas fa-plus"></i>
              <span style={{ marginLeft: '5px', width: '60px' }}>
                {zoomValue}%
              </span>
            </Stack>
          </Box>
          <div>
            {isEdit ? (
              <CustomButton
                buttonText="Edit Flow Model"
                onClick={onEditFlowModelHandler}
              />
            ) : (
              <CustomButton
                buttonText="View Flow Model"
                onClick={onEditFlowModelHandler}
              />
            )}
          </div>
        </Box>
      )}
    </div>
  );
};

export default ProcessMap;
