(function () {
  'use strict';

  angular
    .module('flowingly.runner.setup')
    .controller(
      'runnerSetupEditDatabaseController',
      runnerSetupEditDatabaseController
    );

  runnerSetupEditDatabaseController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$timeout',
    'APP_CONFIG',
    'sessionService',
    'notificationService',
    'databaseApiService',
    'lodashService',
    'dialogService',
    'validationService',
    'browserUtilsService',
    'userApiService',
    '$q'
  ];

  function runnerSetupEditDatabaseController(
    $scope,
    $state,
    $stateParams,
    $timeout,
    APP_CONFIG,
    sessionService,
    notificationService,
    databaseApiService,
    lodashService,
    dialogService,
    validationService,
    browserUtilsService,
    userApiService,
    $q
  ) {
    var $ctrl = this;
    var exportFlag = false;
    var exportColumnOnly = false;

    $ctrl.isMobile = $scope.isMobile;
    $ctrl.data = {
      name: '',
      columns: []
    };

    $ctrl.dataTypeOptions = [
      { value: 'text', text: 'text' },
      { value: 'number', text: 'number' },
      { value: 'currency', text: 'currency' },
      { value: 'user', text: 'user' }
    ];

    $ctrl.databaseName = $stateParams.dbName;
    $ctrl.options = {
      scrollable: true,
      pageable: true,
      sortable: true,
      resizable: true,
      columns: [],
      toolbar: [],
      editable: 'inline',
      cellClose: function (e) {
        var lccv = e.container.find("input[placeholder='Enter a User']").val(); //get the last closed cell value; originally - .find(".k-input").val()
        var isFound = $ctrl.knownListPair.find((u) => u.name == lccv);

        setTimeout(function () {
          if (
            typeof lccv === 'string' &&
            lccv != '' &&
            typeof isFound === 'undefined'
          )
            e.container.append('<div style="color:red">Invalid User</div>');
        });
      }
    };

    let isFlowModelAdmin = sessionService.isFlowModelAdmin();
    $ctrl.hideActions =
      !!APP_CONFIG.disableAdminDatabaseDelete && isFlowModelAdmin;

    $scope.$watch(
      function () {
        return APP_CONFIG.disableAdminDatabaseDelete;
      },
      function (value) {
        $ctrl.hideActions = !!value && isFlowModelAdmin;
        let grid = angular.element('#editDatabaseGrid').data('kendoGrid');
        if ($ctrl.hideActions && grid) grid.hideColumn('actions');
      }
    );

    $ctrl.$onInit = () => {
      loadData();
    };

    $ctrl.export = (headerOnly) => {
      let headers = [];
      getImportColumns().forEach((col) => headers.push(col.name));
      let CSV = headers.join(','),
        len = $ctrl.hideActions ? headers.length : headers.length - 1;

      var val;

      if (!headerOnly) {
        CSV += '\n';

        $ctrl.records.records.forEach((r) => {
          for (let i = 0; i < len; i++) {
            val = r[headers[i].replace(/ /g, '___').toLowerCase()];

            if (typeof val === 'string') {
              val = val.replace(/"/g, '""');
              if (
                headers[i] !== 'customdatabaseid' &&
                headers[i] !== 'delete_record'
              )
                val = '"' + val + '"';
            }
            CSV += val + ',';
          }
          CSV += '\n';
        });
      }

      if (browserUtilsService.isIE()) {
        var IEwindow = window.open();
        IEwindow.document.write(CSV);
        IEwindow.document.close();
        IEwindow.document.execCommand(
          'SaveAs',
          true,
          $stateParams.dbName + '.csv'
        );
        IEwindow.close();
      } else {
        var a = document.createElement('a');
        a.textContent = 'download';
        a.download = `${$stateParams.dbName}${
          headerOnly ? '_template' : ''
        }.csv`;
        if (!$ctrl.records.isUserExtension) CSV = CSV.replace(',_userid_', '');
        a.href = 'data:text/csv;charset=utf-8,' + escape(CSV);
        a.click();
      }
    };

    $ctrl.import = () => {
      dialogService
        .showDialog({
          template: 'Client/runner.import/runner.import.dialog.tmpl.html',
          controller: 'runnerImportDialogController',
          appendClassName: 'ngdialog-normal',
          scope: $scope,
          data: {
            modalTitle: 'Import Records',
            getCSVTemplate: () => {
              $ctrl.export(true);
            },
            isImportUser: false,
            customDbName: $stateParams.dbName,
            columns: getImportColumns()
          }
        })
        .then(function (result) {
          if (dialogService.isCloseModalWithCancelAction(result)) {
            //user closed modal by clicking on overlay (or cancel or press Esc key)
            return;
          }
          if (result) {
            $state.go('app.runner.setup.databases');
          }
        });
    };

    $ctrl.onSaveClick = () => {
      let grid = angular.element('#editDatabaseGrid').data('kendoGrid');
      let options = grid.getOptions();

      // transform to api ready payload
      var records = [];
      lodashService.forEach(options.dataSource.data, (d) => {
        //if (d.dirty) {
        if (!d.customdatabaseid) {
          delete d.dataType;
          d.customdatabaseid = '';
        }

        let record = {};
        options.columns.forEach((c) => {
          if (c.field != 'actions') {
            let value = d[c.field]; // make it work with zeroes and strings

            if (c.type === 'user' && value) {
              let found = $ctrl.knownListPair.find((lp) => lp.name == value);
              value = found ? found.id : value;
            }

            if (value) {
              value = value.trim ? value.trim() : value;
            }
            record[c.field] = value;
          }
        });

        records.push(record);
        //}
      });

      // we need to include the undef columns as a blank string during send so that we can validate it
      var dataString = JSON.stringify(records, (k, v) =>
        v === undefined ? '' : v
      );

      if (dataString === '[]') return;

      databaseApiService
        .saveRecords($stateParams.dbName, dataString)
        .then(() => {
          notificationService.showSuccessToast('Database saved');
          $state.go('app.runner.setup.databases');
        });
    };

    $ctrl.onDeleteClick = (e) => {
      if (!e.model.customdatabaseid && !e.model.id) return;

      let id = e.model.customdatabaseid || e.model.id;

      databaseApiService
        .deleteRecord($stateParams.dbName, id) //e.model.customdatabaseid
        .then(
          () => {
            notificationService.showSuccessToast('Item Deleted');
          },
          () => {
            notificationService.showErrorToast('Error when save database');
          }
        );
    };

    /// PRIVATE METHODS //////////////////////////////////
    function getTextEditor(container, options) {
      $('<input data-bind="value:[\'' + options.field + '\']" disabled/>')
        .appendTo(container)
        .kendoMaskedTextBox();
    }

    function getNumberEditor(container, options) {
      $('<input data-bind="value:[\'' + options.field + '\']" disabled/>')
        .appendTo(container)
        .kendoNumericTextBox({
          decimals: 0,
          format: 'n'
        });
    }

    function getCurrencyEditor(container, options) {
      $('<input data-bind="value:[\'' + options.field + '\']" disabled/>')
        .appendTo(container)
        .kendoNumericTextBox({
          decimals: 2,
          format: 'c'
        });
    }

    function getUsersEditor(container, options) {
      $('<input data-bind="value:[\'' + options.field + '\']" disabled/>')
        .appendTo(container)
        .kendoAutoComplete({
          placeholder: 'Enter a User',
          filter: 'startswith', //startswith/endswith/contains
          minLength: 1,
          enforceMinLength: true,
          filtering: function (e) {
            e.preventDefault();

            $timeout(() => {
              userApiService
                .searchUsers({ term: e.sender._prev })
                .then((newSearchResults) => {
                  lodashService.forEach(newSearchResults, (user) => {
                    if (
                      $ctrl.knownListFullName.indexOf(user.fullNameWithEmail) ==
                      -1
                    ) {
                      $ctrl.knownListFullName.push(user.fullNameWithEmail);
                      $ctrl.knownListPair.push({
                        id: user.id,
                        name: user.fullNameWithEmail
                      });
                    }
                  });

                  this.setDataSource($ctrl.knownListFullName);
                  this.dataSource.read();
                });
            });
          }
        });
    }

    function loadData() {
      $q.all([
        userApiService.searchUsers({}),
        databaseApiService.getColumns($stateParams.dbName),
        databaseApiService.getRecords($stateParams.dbName)
      ]).then(([onPageLoadInitialList, columns, records]) => {
        $ctrl.columns = columns;
        $ctrl.records = records;

        //we keep track of 2 lists so we can easily check if we are going to add more records from existing records and upcoming searches later
        $ctrl.knownListFullName = onPageLoadInitialList.map(
          (u) => u.fullNameWithEmail
        );
        $ctrl.knownListPair = onPageLoadInitialList.map((u) => ({
          id: u.id,
          name: u.fullNameWithEmail
        }));

        lodashService.forEach($ctrl.records.columns, (c) => {
          let column = {
            field: c.fieldName,
            title: c.name,
            validation: { required: true },
            template: (dataItem) => {
              return dataItem[c.fieldName] != null ? dataItem[c.fieldName] : '';
            }
          };
          if (c.dataType === 'number') {
            column.editor = getNumberEditor;
            column.type = 'number';
          } else if (c.dataType === 'currency') {
            column.editor = getCurrencyEditor;
            column.format = '{0:c}';
            column.type = 'currency';
          } else if (
            c.name !== 'Id' &&
            c.fieldName !== 'customdatabaseid' &&
            c.fieldName !== '_userid_' &&
            c.dataType === 'Guid'
          ) {
            column.editor = getUsersEditor;
            column.type = 'user';

            lodashService.forEach($ctrl.records.records, (r) => {
              let temp = r[c.fieldName];

              //get to display the name part
              r[c.fieldName] = JSON.parse(temp) ? JSON.parse(temp).name : null;

              //add the retrieved records into the "known" existing search list
              if (
                $ctrl.knownListFullName.indexOf(r[c.fieldName]) == -1 &&
                temp != null
              ) {
                $ctrl.knownListFullName.push(r[c.fieldName]);
                $ctrl.knownListPair.push(JSON.parse(temp));
              }
            });
          } else {
            column.editor = getTextEditor;
            column.type = 'text';
          }
          $ctrl.options.columns.push(column);
        });

        $ctrl.options.columns.push({
          command: {
            text: ' ',
            className: 'list-actions__action',
            iconClass: 'fa-light fa-pencil',
            click: onEditClick
          },
          title: '&nbsp;&nbsp;Edit',
          field: 'actions',
          width: '75px'
        });

        if (!$ctrl.hideActions) {
          $ctrl.options.columns.push({
            command: {
              name: 'destroy',
              text: ' ',
              className: 'list-actions__action',
              iconClass: 'fa-light fa-trash-can'
            },
            click: $ctrl.onDeleteClick,
            title: 'Delete',
            field: 'actions',
            width: '75px'
          });
        }

        $ctrl.options.dataSource = {
          data: $ctrl.records.records,
          pageSize: APP_CONFIG.gridReportPageSize,
          sort: {
            field: 'field_0',
            dir: 'asc'
          },
          schema: {
            model: {
              fields: {
                name: { validation: { required: true } },
                dataType: { defaultValue: { value: 'text', text: 'text' } },
                one: { defaultValue: '' }
              }
            }
          }
        };

        // FLOW-3532: cannot add new record for user extension tables
        if ($ctrl.records.isUserExtension === true) {
          $ctrl.options.toolbar = [];
        }

        let gridDiv = $('#editDatabaseGrid');

        gridDiv.kendoGrid($ctrl.options);
        let grid = gridDiv.data('kendoGrid');
        grid.hideColumn('customdatabaseid');
        grid.hideColumn('_userid_');
        grid.bind('remove', $ctrl.onDeleteClick);

        // Initialize export data button
        // need make sure Angular done its digest cycle thus html tags are exist
        $timeout(() => {
          angular.element('.editDatabaseToolbar .dropdown-button').dropdown({
            belowOrigin: true
          });
        });
      });
    }

    function getImportColumns() {
      let cols = [];

      $ctrl.options.columns.forEach((col) => {
        let isNumberField = col.type === 'number' || col.type === 'currency';

        if (col.field === 'customdatabaseid') {
          cols.push({
            name: 'customdatabaseid',
            isNumberField: isNumberField
          });
        } else if (col.field === 'actions') {
          if (col.title === 'Delete') {
            cols.push({
              name: 'delete_record',
              isNumberField: isNumberField
            });
          }
        } else {
          cols.push({
            name: col.title,
            isNumberField: isNumberField
          });
        }
      });

      return cols;
    }

    function onEditClick(e) {
      $ctrl.gridData = $('#editDatabaseGrid').data('kendoGrid');

      let data, tablecols;

      e.preventDefault();
      data = this.dataItem($(e.currentTarget).closest('tr'));
      tablecols = this.columns.filter((c) => c.field != 'actions');

      let dialogDetails = [];

      lodashService.forEach(tablecols, (tc) => {
        data[tc.field] = data[tc.field] ? data[tc.field] : null;

        if (tc.type != 'user')
          //text/email/number
          dialogDetails.push({
            col: tc.field,
            title: tc.title,
            type: tc.type,
            dataType: $ctrl.columns.find((c) => c.name == tc.title)
              ? $ctrl.columns.find((c) => c.name == tc.title).dataType
              : 'text',
            value: data[tc.field]
          });
        else
          dialogDetails.push({
            col: tc.field,
            title: tc.title,
            type: tc.type,
            dataType: $ctrl.columns.find((c) => c.name == tc.title)
              ? $ctrl.columns.find((c) => c.name == tc.title).dataType
              : 'user',
            valueFullName: data[tc.field],
            valueGuid: data[tc.field]
              ? $ctrl.knownListPair.find((p) => p.name == data[tc.field]).id
              : null,
            selectedUser: data[tc.field]
              ? {
                  id: $ctrl.knownListPair.find((p) => p.name == data[tc.field])
                    .id,
                  fullNameWithEmail: data[tc.field]
                }
              : {}
          });
      });

      data.dialogDetails = dialogDetails;

      dialogService
        .showDialog({
          template:
            'Client/runner.setup/runner.setup.databases/runner.setup.databases.dialog.edit.tmpl.html',
          controller: 'databasesDialogEditController',
          appendClassName: 'ngdialog-normal',
          data: data
        })
        .then(function (result) {
          if (typeof result !== 'object')
            //undefined == cancelled, string == $escape
            return;

          let toBeAddedEditedGridRow;

          if (result.customdatabaseid != null)
            toBeAddedEditedGridRow = lodashService.find(
              $ctrl.gridData._data,
              function (gd) {
                return gd.customdatabaseid == result.customdatabaseid;
              }
            );
          else
            toBeAddedEditedGridRow = lodashService.find(
              $ctrl.gridData._data,
              function (gd) {
                return gd.uid == result.uid;
              }
            );

          if (toBeAddedEditedGridRow) {
            lodashService.forEach(result.dialogDetails, (d) => {
              if (d.col != 'customdatabaseid') {
                toBeAddedEditedGridRow[d.col] = result[d.col];

                if (d.type == 'user') {
                  if ($ctrl.knownListFullName.indexOf(d.valueFullName) == -1) {
                    $ctrl.knownListFullName.push(d.valueFullName);
                    $ctrl.knownListPair.push({
                      id: d.valueGuid,
                      name: d.valueFullName
                    });
                  }
                }
              }
            });
          }

          $ctrl.gridData.refresh();
        });
    }

    $ctrl.onAddClick = () => {
      $ctrl.gridData = $('#editDatabaseGrid').data('kendoGrid');
      if ($ctrl.gridData) {
        $ctrl.gridData.addRow();

        let data, tablecols;

        data = $ctrl.gridData._data[0];
        tablecols = $ctrl.gridData.columns.filter((c) => c.field != 'actions');

        let dialogDetails = [];

        lodashService.forEach(tablecols, (tc) => {
          data[tc.field] = data[tc.field] ? data[tc.field] : null;

          if (tc.type != 'user')
            //text/email/number
            dialogDetails.push({
              col: tc.field,
              title: tc.title,
              type: tc.type,
              dataType: $ctrl.columns.find((c) => c.name == tc.title)
                ? $ctrl.columns.find((c) => c.name == tc.title).dataType
                : 'text',
              value: data[tc.field]
            });
          else
            dialogDetails.push({
              col: tc.field,
              title: tc.title,
              type: tc.type,
              dataType: $ctrl.columns.find((c) => c.name == tc.title)
                ? $ctrl.columns.find((c) => c.name == tc.title).dataType
                : 'user',
              valueFullName: data[tc.field],
              valueGuid: data[tc.field]
                ? $ctrl.knownListPair.find((p) => p.name == data[tc.field]).id
                : null,
              selectedUser: data[tc.field]
                ? {
                    id: $ctrl.knownListPair.find(
                      (p) => p.name == data[tc.field]
                    ).id,
                    fullNameWithEmail: data[tc.field]
                  }
                : {}
            });
        });

        data.dialogDetails = dialogDetails;

        dialogService
          .showDialog({
            template:
              'Client/runner.setup/runner.setup.databases/runner.setup.databases.dialog.add.tmpl.html',
            controller: 'databasesDialogAddController',
            appendClassName: 'ngdialog-normal',
            data: data
          })
          .then(function (result) {
            if (typeof result !== 'object') {
              //undefined == cancelled, string == $escape

              //  Hacky fix to remove the dialog for deleting the supposedly newly added row
              //  https://stackoverflow.com/questions/12825438/how-do-i-prevent-the-kendogrids-removerow-function-from-prompting
              var oldConfirm = window.confirm;
              window.confirm = function () {
                return true;
              };
              $ctrl.gridData.removeRow('tr.k-grid-edit-row');
              window.confirm = oldConfirm;

              return;
            }

            let toBeAddedGridRow = lodashService.find(
              $ctrl.gridData._data,
              function (gd) {
                return gd.uid == result.uid;
              }
            );

            if (toBeAddedGridRow) {
              lodashService.forEach(result.dialogDetails, (d) => {
                if (d.col != 'customdatabaseid') {
                  toBeAddedGridRow[d.col] = result[d.col];

                  if (d.type == 'user') {
                    if (
                      $ctrl.knownListFullName.indexOf(d.valueFullName) == -1
                    ) {
                      $ctrl.knownListFullName.push(d.valueFullName);
                      $ctrl.knownListPair.push({
                        id: d.valueGuid,
                        name: d.valueFullName
                      });
                    }
                  }
                }
              });
            }

            $ctrl.gridData.refresh();
          });
      }
    };
  }
})();
