'use strict';

import angular from 'angular';

export default class RunnerSetupTabsComponentController {
  static $inject = [
    'APP_CONFIG',
    'permissionsService',
    'sessionService',
    'flowinglyConstants'
  ];

  private isCategoryPageAccessable = false;
  private isDatabaseFeatureEnabled = false;
  private isDatabasePageAccessable = false;
  private isRolesPageAccessable = false;
  private isTeamPageAccessable = false;
  private isUserPageAccessable = false;

  constructor(
    APP_CONFIG,
    permissionsService,
    sessionService,
    flowinglyConstants
  ) {
    this.isUserPageAccessable = permissionsService.currentUserHasPermission(
      flowinglyConstants.permissions.SETUP_USER_ACCESS
    );
    this.isTeamPageAccessable = permissionsService.currentUserHasPermission(
      flowinglyConstants.permissions.SETUP_TEAM_ACCESS
    );
    this.isDatabasePageAccessable = permissionsService.currentUserHasPermission(
      flowinglyConstants.permissions.SETUP_DATABASE_ACCESS
    );
    this.isCategoryPageAccessable = permissionsService.currentUserHasPermission(
      flowinglyConstants.permissions.SETUP_CATEGORY_ACCESS
    );

    sessionService.onReady(() => {
      this.isDatabaseFeatureEnabled = APP_CONFIG.enableDatabaseFeature;

      this.isRolesPageAccessable =
        permissionsService.currentUserHasPermission(
          flowinglyConstants.permissions.SETUP_ROLE_ACCESS
        ) && APP_CONFIG.enableSetupRoles;
    });
  }
}

export class RunnerSetupTabsComponent implements angular.IComponentOptions {
  public templateUrl =
    'Client/runner.setup/runner.setup.tabs.component.tmpl.html';

  controller = RunnerSetupTabsComponentController;
}

angular
  .module('flowingly.runner.setup')
  .component('runnerSetupTabs', new RunnerSetupTabsComponent());
