import angular from 'angular';
/**
 * @ngdoc module
 * @name flowingly.runner.maintenance
 * @module flowingly.runner.maintenance
 * @description This module configures the page used to maintenance of all flows.
 */
const m = angular.module('flowingly.runner.maintenance', ['kendo.directives']);
m.run([
  '$templateCache',
  function ($templateCache: angular.ITemplateCacheService) {
    $templateCache.put(
      'Client/runner.maintenance/emails/runner.maintenance.emails.tmpl.html',
      require('./emails/runner.maintenance.emails.tmpl.html').default
    );

    $templateCache.put(
      'Client/runner.maintenance/runner.maintenance.tmpl.html',
      require('./runner.maintenance.tmpl.html').default
    );

    $templateCache.put(
      'Client/runner.maintenance/settings/runner.maintenance.settings.tmpl.html',
      require('./settings/runner.maintenance.settings.tmpl.html').default
    );

    $templateCache.put(
      'Client/runner.maintenance/flows/dialogs/action.tmpl.html',
      require('./flows/dialogs/action.tmpl.html').default
    );

    $templateCache.put(
      'Client/runner.maintenance/flows/runner.maintenance.flows.tmpl.html',
      require('./flows/runner.maintenance.flows.tmpl.html').default
    );

    $templateCache.put(
      'Client/runner.maintenance/validation/runner.maintenance.validation.tmpl.html',
      require('./validation/runner.maintenance.validation.tmpl.html').default
    );

    $templateCache.put(
      'Client/runner.maintenance/users/runner.maintenance.users.tmpl.html',
      require('./users/runner.maintenance.users.tmpl.html').default
    );

    $templateCache.put(
      'Client/runner.maintenance/users/runner.maintenance.user.list.actions.tmpl.html',
      require('./users/runner.maintenance.user.list.actions.tmpl.html').default
    );

    $templateCache.put(
      'Client/runner.maintenance/users/runner.maintenance.user.detail.tmpl.html',
      require('./users/runner.maintenance.user.detail.tmpl.html').default
    );
  }
]);
