import angular, {
  IAuth0LockProvider,
  IAuth0Provider,
  ICompileProvider,
  IHttpProvider,
  IJWTOptionsProvider,
  ILocationProvider,
  cookies,
  idle
} from 'angular';
import { IFlowinglyWindow } from './interfaces/flowingly.window';
import { FormGen } from './@types/formGen';

/*
 * Converted runner.config.js to ts on 17/12/2019
 * To view the original version open this
 * https://bitbucket.org/flowingly-team/flowingly-source-code/src/3a5769f998d23e3923099dcb44996d7cf1dd02b1/src/Flowingly.Angular.Runner/Client/runner.config.js
 */

declare const window: IFlowinglyWindow;

// Create the module and declare its dependencies.
angular.module('flowingly.runner').config([
  '$httpProvider',
  '$locationProvider',
  '$compileProvider',
  'usSpinnerConfigProvider',
  'fgConfigProvider',
  'lockProvider',
  'angularAuth0Provider',
  'APP_CONFIG',
  'jwtOptionsProvider',
  'storeProvider',
  'IdleProvider',
  '$cookiesProvider',
  function (
    $httpProvider: IHttpProvider,
    $locationProvider: ILocationProvider,
    $compileProvider: ICompileProvider,
    usSpinnerConfigProvider: IUsSpinnerConfig,
    fgConfigProvider: FormGen.FgConfigProvider,
    lockProvider: IAuth0LockProvider,
    angularAuth0Provider: IAuth0Provider,
    APP_CONFIG: AppConfig,
    jwtOptionsProvider: IJWTOptionsProvider,
    storeProvider: angular.a0.storage.IStoreProvider,
    IdleProvider: idle.IIdleProvider,
    $cookiesProvider: cookies.ICookiesProvider
  ) {
    // populate settings.json from window to APP_CONFIG
    APP_CONFIG.populateFromSettingsJSON(window.settings);

    // Store defaults to localStorage but we can set sessionStorage or cookieStorage.
    storeProvider.setStore('localStorage');
    if (APP_CONFIG.flowinglyEnvironment === 'production') {
      $httpProvider.useApplyAsync(true);
      $compileProvider.debugInfoEnabled(true);
    }
    fgConfigProvider.setShowDummyDoneButton(false);

    // Set the color used for the in progress spinner
    usSpinnerConfigProvider.setDefaults({ color: '#f57c00' });

    // Implement the method to get the token, which is used by authManager.checkAuthOnRefresh()
    jwtOptionsProvider.config({
      tokenGetter: function () {
        return localStorage.getItem(
          'flowingly.' + APP_CONFIG.flowinglyEnvironment + '.token'
        );
      },
      whiteListedDomains: ['localhost'], // Not sure exactly what this does. Want to see it in Dev so I can work it out.
      unauthenticatedRedirectPath: '/' // Our main route handles it when user is not authenticated
    });

    $httpProvider.interceptors.push('runnerAuthInterceptor');
    $httpProvider.interceptors.push('runnerSpinInterceptor');
    $httpProvider.interceptors.push('flowinglyHttpErrorInterceptor');

    $locationProvider.html5Mode(true).hashPrefix('');

    $httpProvider.defaults.useXDomain = true;
    delete $httpProvider.defaults.headers.common['X-Requested-With'];

    // For Auth0 Lock 10 (eg to logon):
    // See Auth0 Lock and doco here: https://github.com/auth0/lock
    // For customisation options => : https://auth0.com/docs/libraries/lock/v10/customization
    // And see https://auth0.com/docs/libraries/lock/v10/sending-authentication-parameters

    // For access to the full autho API (eg to refresh a token):
    // So basically we follow this doco: https://github.com/auth0/auth0.js
    // but we use this library https://github.com/auth0/angular-auth0
    // and we be careful to NOT use auth0-angular as it is for lock 9.

    // And to work with JWTs (eg to see if they have expired):
    // See https://github.com/auth0/angular-jwt

    //setting redirect url only when user is coming from email or report page
    //Though for such urls redirect happens twice, once after auth0 authorization application is redirected
    //and again as request pipeline, auth0 again checks redirected url and finds valid token and shows the page
    //I was trying to stop second redirect because auth0 has already performed authentication during login but
    //couldn't do so as I can't use authManger service to check if use authenticated and $rootscope etc.
    let redirectUrl = '';
    const domainPort =
      window.location.port !== '' ? `:${window.location.port}` : '';
    const currentDomain = `${window.location.protocol}//${window.location.hostname}${domainPort}`;
    const pushNotifHandle =
      window.isFlowinglyCordova &&
      window.flowinglyCordova.pushNotificationHandle != null &&
      window.flowinglyCordova.pushNotificationHandle
        ? '?pushNotificationHandle=' +
          window.flowinglyCordova.pushNotificationHandle
        : '';

    console.log('pushNotificationHandle: ' + pushNotifHandle);
    if (
      (window.location.href.indexOf('/flows/') > 0 ||
        window.location.href.indexOf('/categoryId?') > 0) &&
      window.location.href.indexOf('access_token=') < 0
    ) {
      redirectUrl = encodeURIComponent(window.location.href);
    }

    const authOptions = {
      allowSignUp: false,
      // TODO: FLOW-1214 Disable thid for now to prevent the incorrect user across multiple environments bug
      rememberLastLogin: false,
      avatar: null,
      theme: {
        // TODO: We are using our small logo as the large logo is rendered badly. We could overide the style lock uses for the logo
        // but the auth0 lock doco says clearly that this is not a good thing to do. So, rather we need to create a logo of the right dimensions
        // I believe. Nick has seen an example where a logo such as ours is used. So if we cant get this working then we can ask the question
        // on the auth0 forms.
        logo: APP_CONFIG.logoUrl,
        primaryColor: '#4A90E2'
      },
      closable: false,
      //container: 'hiw-login-container',
      languageDictionary: {
        title: APP_CONFIG.welcomeText, // TODO: We would rather remove the title field all together. I have not worked out how to do that. Will ask on the Auth0 forums.
        usernameOrEmailInputPlaceholder: 'your email'
      },
      sso: true,
      auth: {
        params: {
          scope:
            'openid offline_access nameidentifier businessidentifier flowinglypermissions', //offline_access so that we get a refresh token
          //redirectUri: `${currentDomain}/flowsactive/${window.isFlowinglyCordova ? "?pushNotificationHandle=" + window.flowinglyCordova.pushNotificationHandle : ""}`,
          redirectUri: `${currentDomain}/flowsactive/${pushNotifHandle}`,
          // state param is returned as is from auth0 service and can be used to send any static data to and fro auth0
          // see more here https://auth0.com/docs/tutorials/redirecting-users
          // though tutorial shows that we can pass object to state but in our case it accepts only string, may be different auth0 APIs
          state: redirectUrl
        }
      },
      _enableIdPInitiatedLogin: true
    };

    lockProvider.init({
      clientID: APP_CONFIG.auth0ClientId,
      domain: APP_CONFIG.auth0Domain,
      options: authOptions
    });

    angularAuth0Provider.init({
      clientID: APP_CONFIG.auth0ClientId,
      domain: APP_CONFIG.auth0Domain,
      responseType: 'id_token token',
      scope:
        'openid offline_access nameidentifier businessidentifier flowinglypermissions', //offline_access so that we get a refresh token
      redirectUri: `${currentDomain}/flowsactive/${pushNotifHandle}`
    });

    IdleProvider.idle(APP_CONFIG.sessionTimeoutInSecond);
    IdleProvider.timeout(0);

    $cookiesProvider.defaults.path = '/';
    $cookiesProvider.defaults.domain = window.location.hostname.substr(
      window.location.hostname.indexOf('.') + 1
    );
  }
]);

angular.module('flowingly.runner').factory('$exceptionHandler', [
  'exceptionService',
  function (exceptionService) {
    return function (exception, cause) {
      exceptionService.error(exception, cause);
    };
  }
]);
