import angular, {
  IController,
  IRootScopeService,
  IScope,
  IWindowService
} from 'angular';
import RunnerFlowService from './runner.flow.service';
import { Flowingly } from '../../@types/flowingly';
import { RunnerFlowsFormatterService } from '../../runner.services/flows.formatter';
import { IFormattedFlow } from '../../interfaces/IFormattedFlow';
import { IStateParamsService, IStateService } from 'angular-ui-router';
import { SharedAngular } from '@Client/@types/sharedAngular';

export default class RunnerFlowController implements IController {
  static $inject = [
    '$state',
    '$scope',
    '$rootScope',
    '$window',
    '$stateParams',
    'lodashService',
    'goService',
    'flowListManager',
    'avatarService',
    'pubsubService',
    'sessionService',
    'flowsUtilityService',
    'flowinglyDiagramService',
    'commentApiService',
    'flow',
    'notificationService',
    'APP_CONFIG',
    'flowinglyConstants',
    'runnerFlowService',
    '$q',
    'flowApiService',
    'guidService',
    'Enums',
    'dialogService',
    'runnerFlowsFormatter',
    'appInsightsService',
    'permissionsService'
  ];

  constructor(
    private $state: IStateService,
    private $scope: IScope,
    private $rootScope: IRootScopeService,
    private $window: IWindowService,
    private $stateParams: IStateParamsService,
    private lodashService: Lodash,
    private goService: GoJS,
    private flowListManager: FlowListManager,
    private avatarService: SharedAngular.AvatarService,
    private pubsubService: SharedAngular.PubSubService,
    private sessionService: SharedAngular.SessionService,
    private flowsUtilityService: SharedAngular.FlowsUtilityService,
    private flowinglyDiagramService: SharedAngular.FlowinglyDiagramService,
    private commentApiService: SharedAngular.CommentApiService,
    private _flow_: IFormattedFlow | string,
    private notificationService: SharedAngular.NotificationService,
    private APP_CONFIG: AppConfig,
    private flowinglyConstants: SharedAngular.FlowinglyConstants,
    private runnerFlowService: RunnerFlowService,
    private $q: angular.IQService,
    private flowApiService: FlowApiService,
    private guidService: SharedAngular.GuidService,
    private enums: Enums,
    private dialogService: SharedAngular.DialogService,
    private flowsFormatter: RunnerFlowsFormatterService,
    private appInsightsService: SharedAngular.AppInsightsService,
    private permissionsService: SharedAngular.PermissionsService
  ) {
    this.commentTargetTypeFlow = this.flowinglyConstants.commentTargetType.FLOW;
  }
  public flow: IFormattedFlow;
  public noPermisson = false;
  public showFlow = true;
  public unhandledException = false;
  public tabStrip: any;
  public flowComments: any;
  public commentTargetTypeFlow: number;
  public showSupportButton: boolean;
  public progress = 0;
  public flowProcessWidth = 100;
  public searchable = false;

  public tabOptions = {
    activate: this.onTabActive
  };
  public tabOptionsMobile = {
    scrollable: false,
    activate: this.onTabActive
  };

  public canEdit: boolean;
  public showComments: boolean;

  public newFlowIsDynamicType: boolean;
  public newFlowParameters: any;
  public newFlowForm: angular.IFormController;
  public newFlowDynamicActorList: Flowingly.IDynamicActor[] = [];
  public isCurrentlyStartingFlow = false;
  public shouldForceShowErrors = false; // initially hidden until a submit attempt is done

  public description: string;
  public isSameUser = false;

  public $onInit() {
    const {
      pubsubService,
      guidService,
      sessionService,
      $stateParams,
      enums,
      avatarService,
      flowApiService,
      APP_CONFIG,
      runnerFlowService,
      flowinglyConstants,
      flowsUtilityService,
      lodashService,
      notificationService,
      _flow_
    } = this;

    pubsubService.subscribe(
      'SIGNALR_RUNNER_COMPLETE_STEP',
      this.onRunnerFlowStatusChange.bind(this),
      'runnerFlowController'
    );
    pubsubService.subscribe(
      'SIGNALR_RUNNER_REASSIGN_STEP',
      this.onRunnerFlowStatusChange.bind(this),
      'runnerFlowController'
    );
    pubsubService.subscribe(
      'SIGNALR_RUNNER_WITHDRAW_FLOW',
      this.onRunnerFlowWithdrawn.bind(this),
      'runnerFlowController'
    );
    pubsubService.subscribe(
      'SIGNALR_RUNNER_STEP_INTEGRATION_PROCESSING_UPDATED',
      this.onRunnerFlowStatusChange.bind(this),
      'runnerFlowController'
    );

    this.registerSupportButton();
    this.registerKendoWidget();

    if (angular.isString(_flow_)) {
      this.showFlow = false;

      if (_flow_.toLowerCase() === 'permission denied') {
        this.noPermisson = true;
      } else if (_flow_.toLowerCase() === 'unhandled exception') {
        this.unhandledException = true;
      }
    } else {
      this.flow = _flow_ as IFormattedFlow;
      this.flow.FilesCount = this.getFileCount(this.flow);
      runnerFlowService.hasStarted = this.flow.FlowId != guidService.empty();

      if (runnerFlowService.hasStarted == false) {
        const { flowId, flowModelId } = $stateParams;
        const loggedInUser = sessionService.getUser();

        flowApiService.getPublishedFlowModel(flowModelId).then((flowModel) => {
          // these are needed for us to generate the flow model
          // on the flow model tab
          this.flow.FlowModelId = flowModelId;
          this.flow.Id = guidService.new();
          this.flow.FlowSchema = flowModel.flowSchema;
          this.flow.Name = flowModel.name;
          this.description = flowModel.description;
          this.searchable = flowModel.searchable;

          // logic copied from runner.flow.start.component from commit
          // that exists in commit d7f7cc0999d91b989ab62ba738225f17baac0622
          this.newFlowIsDynamicType = !!(
            flowModel.dynamicActorType &&
            flowModel.dynamicActorType ===
              enums.dynamicActorTypeIds.SELECT_DYNAMIC_ACTORS &&
            flowModel.selectedDynamicActors.length > 0
          );

          if (this.newFlowIsDynamicType) {
            this.newFlowDynamicActorList = flowModel.selectedDynamicActors.map(
              (actor) => {
                return {
                  displayName: actor.fullName,
                  actorId: actor.id
                } as Flowingly.IDynamicActor;
              }
            );
          }
        });

        this.newFlowParameters = {
          flowModelId: flowModelId,
          requesterId: loggedInUser.id,
          subject: '',
          ccActors: [],
          bulkActors: [
            {
              id: loggedInUser.id,
              displayName: loggedInUser.fullName,
              avatarUrl: avatarService.getAvatarUrl(loggedInUser.id),
              searchEntityType: flowinglyConstants.searchEntityType.USER
            }
          ]
        };
      } else {
        try {
          const eventSecondsElapsed =
            this.appInsightsService.getAndClearElapsedEventSeconds(
              this.appInsightsService.Events.FLOW_STARTED
            );
          if (eventSecondsElapsed) {
            this.appInsightsService.trackMetric(
              'flow-started',
              eventSecondsElapsed,
              { time: eventSecondsElapsed }
            );
          }
        } catch (e) {
          // Swallow the exception to not block code execution.
        }
      }

      //if we are viewing this flow from a report, there will be a flowId in the query string
      const stepParam = flowsUtilityService.getStateParamValue('step');
      if (stepParam) {
        const stepDetails = lodashService.find(this.flow.Steps, (step) => {
          return step.Id.toUpperCase() === stepParam.toUpperCase();
        });

        if (
          stepDetails.IsCompleted &&
          stepDetails.ActorAssignedId !== stepDetails.CompletedById
        ) {
          notificationService.showSuccessToast(
            `${stepDetails.Name} was completed by ${stepDetails.CompletedByName} on behalf of ${stepDetails.ActorAssignedName}`,
            10000
          );
        }
      }

      this.showComments =
        flowsUtilityService.getStateParamValue('showComments');
      this.showFlow = !(this.flow.FinalisedReason === 'Withdrawn');
      this.canEdit =
        this.permissionsService.currentUserHasPermission(
          flowinglyConstants.permissions.FLOWMODEL_UPDATE
        ) ||
        (this.flow.IsFlowModelOwner && APP_CONFIG.fmoCanPublish);
    }
  }

  public onSupportButtonClick() {
    const { sessionService, $stateParams, $window } = this;
    const loggedInUser = sessionService.getUser();
    const flowId = $stateParams.flowId;

    this.flowApiService.getFlowSupportDetails(flowId).then((response) => {
      const supportDetails = JSON.parse(response);
      $window.location.href =
        'mailto:support@flowingly.net?subject=There was an error opening flow ' +
        supportDetails.flowIdentifier +
        '&body=Logged in User: ' +
        loggedInUser.email +
        '%0D' +
        'Logged in Users Manager: ' +
        supportDetails.userManager +
        '%0D' +
        'FlowId: ' +
        flowId +
        '%0D' +
        'Flow-ID: ' +
        supportDetails.flowIdentifier +
        '%0D' +
        'Current Step Name: ' +
        supportDetails.currentStepName +
        '%0D' +
        'Date/Time: ' +
        new Date();
    });
  }

  public onTabActive(e) {
    angular
      .element(e.item)
      .text()
      .replace(/(\(\d*\))/gm, '')
      .trim();
  }

  public onCommentsClick() {
    const { lodashService, commentApiService, avatarService } = this;

    if (!this.hasStarted()) {
      return;
    }

    commentApiService
      .getFlowComments(this.commentTargetTypeFlow, this.flow.FlowId)
      .then((data) => {
        this.flowComments = data;
        this.flow.CommentCount = this.flowComments.length;
        lodashService.forEach(this.flowComments, (comment) => {
          comment.avatarUrl = avatarService.getAvatarUrl(comment.userId);
        });
        this.tabStrip.select(1);
      });
  }

  public shouldShowSupportButton(configHide) {
    const { APP_CONFIG } = this;
    return (
      (APP_CONFIG.hasLoaded && !configHide) ||
      this.permissionsService.currentUserHasPermission(
        this.flowinglyConstants.permissions.SUPPORT_ACCESS
      )
    );
  }

  public canStartFlowOnBehalf() {
    return this.permissionsService.currentUserHasPermission(
      this.flowinglyConstants.permissions.FLOW_STARTONBEHALF
    );
  }

  public showFlowModel(flow) {
    this.flowinglyDiagramService.generateProcessModel({
      flow: flow,
      applyAvatar: false,
      modelCustomArgs: {
        scrollMode: this.goService.Diagram.DocumentScroll
      }
    });
  }

  public onCommentAdded(flowId, count) {
    this.flowListManager.updateFlowCommentCountInLists(flowId, count);
    this.flow.CommentCount = count;
  }

  public onRunnerFlowStatusChange(event, message) {
    const data = JSON.parse(message);
    const flowId = data.flowId || data.flow.flowId;
    if (flowId !== this.flow.FlowId) {
      return;
    }
    this.runnerFlowService.getFlowById(flowId).then((response) => {
      if (!response) {
        return;
      }
      this.flow = response;
      this.progress = this.flow.PercentageComplete;
      this.flow.FilesCount = this.getFileCount(this.flow);

      //FLOW-4947 - re-render the flow model if the current tab is the Flow Model tab
      if ($('li[aria-controls=tabstrip-3]').hasClass('k-state-active')) {
        this.showFlowModel(this.flow);
      }
    });
  }

  public onRunnerFlowWithdrawn() {
    this.onBackButtonClick();
  }

  public onBackButtonClick() {
    const { $rootScope, $state, $window, $stateParams } = this;

    const route = $rootScope.previousRoute || '';
    switch (route.split('?')[0]) {
      case '/flows/{flowId}':
      case '/flowsactive/{categoryId}':
        $state.go('app.runner.flowsactive');
        break;
      case '/flowstodo':
        $state.go('app.runner.flowstodo');
        break;
      case '/flowsin':
        $state.go('app.runner.flowsin');
        break;
      case '/library':
        $state.go('app.runner.library');
        break;
      case '/report/{flowModelId}':
        $window.history.go(-1);
        break;
      case '/search?:term':
        if ($stateParams.searchJump)
          $window.history.go(-2); // go back to the page prior to the search
        else
          $state.go('app.runner.search', {
            term: $rootScope.previousSearchTerm
          });
        break;
      default:
        $state.go('app.runner.flowsin');
    }
  }

  public shouldShowErrors() {
    return this.shouldForceShowErrors || this.newFlowForm.$dirty;
  }

  public tryAndStartFlow(): angular.IPromise<void> {
    this.shouldForceShowErrors = true; // initially hidden until a submit attempt is done

    if (this.hasSubjectError()) {
      return this.$q.reject();
    } else if (this.newFlowIsDynamicType === true) {
      /*
       * Yes this is a very convulated way to get the name of the step but so far this is
       * the only way we can get the step name with the variables we have in this controller
       */
      let firstStep = null;
      try {
        const schema = JSON.parse(this.flow.FlowSchema as string);
        const startNode = schema.nodeDataArray.find(
          ({ item }) => item == 'start'
        );
        const startNodeLink = schema.linkDataArray.find(
          ({ from }) => from == startNode.key
        );
        firstStep = schema.nodeDataArray.find(
          ({ key }) => startNodeLink.to == key
        );
      } catch (err) {
        console.warn(
          'There was a problem trying to determine the name of the first step. Falling back to generic message...'
        );
        console.warn(err.stack);
      }

      if (this.newFlowDynamicActorList.length == 1) {
        const loggedInUser = this.sessionService.getUser();
        for (let i = 0; i < this.newFlowDynamicActorList.length; i++) {
          if (loggedInUser.id == this.newFlowDynamicActorList[i].actorId) {
            this.isSameUser = true;
          }
        }
        if (this.isSameUser == true) {
          return this.startFlow(loggedInUser.id);
        } else {
          return this.dynamicActorsDialog(firstStep).then(
            (assignedDynamicActors) => {
              if (
                !assignedDynamicActors ||
                assignedDynamicActors.length === 0
              ) {
                return null;
              } else {
                return this.startFlow(
                  assignedDynamicActors.pop().assignedDynamicActorId
                );
              }
            }
          );
        }
      } else {
        return this.dynamicActorsDialog(firstStep).then(
          (assignedDynamicActors) => {
            if (!assignedDynamicActors || assignedDynamicActors.length === 0) {
              return null;
            } else {
              return this.startFlow(
                assignedDynamicActors.pop().assignedDynamicActorId
              );
            }
          }
        );
      }
    } else {
      return this.startFlow();
    }
  }

  private dynamicActorsDialog(firstStep) {
    return this.dialogService
      .showDialog({
        template:
          'Client/runner.flow/runner.flow.form/runner.flow.dynamic-actor.dialog.tmpl.html',
        controller: 'dynamicActorDialogController',
        appendClassName: 'ngdialog-theme-plain w-500',
        data: {
          /* this is just a stub to make this controller
           * work with data it is not used for
           *
           * @TODO genericify this display dialogue
           */
          dynamicActorsForSteps: [
            {
              dynamicActors: this.newFlowDynamicActorList,
              nodeId: null,
              searchable: this.searchable,
              stepName: firstStep.text,
              uniqueId: this.guidService.new() // dummy id
            }
          ],
          modelerNodeId: firstStep.id
        }
      })
      .then((assignedDynamicActors) => {
        if (
          this.dialogService.isCloseModalWithCancelAction(assignedDynamicActors)
        ) {
          //user closed modal by clicking on overlay (or cancel or press Esc key)
          return null;
        }
        return assignedDynamicActors;
      });
  }

  private registerSupportButton() {
    const { APP_CONFIG } = this;
    const removeWatch = this.$scope.$watch(
      () => {
        return APP_CONFIG.hasLoaded;
      },
      (value) => {
        if (value) {
          this.showSupportButton = this.shouldShowSupportButton(
            APP_CONFIG.hideSupportButton
          );
          removeWatch();
        }
      }
    );
  }

  private registerKendoWidget() {
    this.$scope.$on('kendoWidgetCreated', (ev, widget) => {
      if (widget === this.tabStrip) {
        if (!this.showComments) {
          this.tabStrip.select(0);
        } else {
          this.onCommentsClick();
        }
      }
    });
  }

  private startFlow(assignedActorId?: any): angular.IPromise<void> {
    // Set the datetime the flow start was triggered by the user. This is to get the time taken to start a flow
    // after user selected start.
    this.appInsightsService.startEventTimer(
      this.appInsightsService.Events.FLOW_STARTED
    );
    const {
      lodashService,
      runnerFlowService,
      $state,
      notificationService,
      $q
    } = this;
    const transformFn = this._transformForStartFlow.bind(this);
    const { newFlowParameters } = this;

    this.isCurrentlyStartingFlow = true;

    const loggedInUser = this.sessionService.getUser();

    const bulkActors = newFlowParameters.bulkActors.map(transformFn);
    const ccActors = newFlowParameters.ccActors.map(transformFn);
    let promise;

    const shouldAppendNameToSubject = bulkActors.length > 1;
    /*
     * We process the user's request first so and update the rest of the bulk requests
     * after. This is so that the user won't have to wait for a super long time to start the flow.
     */
    const currUsersRequest = lodashService.remove(
      bulkActors,
      (u) => u.userId == loggedInUser.id
    );
    if (currUsersRequest.length) {
      promise = runnerFlowService
        .startFlow(
          newFlowParameters.flowModelId,
          newFlowParameters.subject,
          currUsersRequest,
          ccActors,
          assignedActorId,
          shouldAppendNameToSubject
        )
        .then(function (response) {
          if (response.dataModel) {
            const [flowId] = response.dataModel;
            $state.go(
              'app.runner.flow',
              { flowId, flowModelId: undefined },
              { notify: true }
            );

            notificationService.showSuccessToast(`Flow started`);
          } else if (
            response.errorCode &&
            !response.errorCode.toLowerCase().match(`exception`) &&
            response.errorMessage
          ) {
            //exclude generic exception messages returned from API
            notificationService.showErrorToast(response.errorMessage);
          } else {
            notificationService.showErrorToast(
              `There was an error trying to start the flow.`
            );
          }
        });
    }

    if (bulkActors.length > 0) {
      promise = $q.resolve(promise).then(() => {
        return runnerFlowService
          .startFlow(
            newFlowParameters.flowModelId,
            newFlowParameters.subject,
            bulkActors,
            ccActors,
            null,
            shouldAppendNameToSubject,
            currUsersRequest.length ? [loggedInUser.id] : null
          )
          .then(function (response) {
            if (response.dataModel) {
              const otherUserCount = response.dataModel.length;
              notificationService.showSuccessToast(
                `Successfully started for ${otherUserCount} other user${
                  otherUserCount > 1 ? 's' : ''
                }.`
              );

              if (currUsersRequest.length == 0) {
                // can't use transition because "/" is an abstract state
                $state.go('app.runner.flowsactive');
              }
            } else if (
              response.errorCode &&
              !response.errorCode.toLowerCase().match(`exception`) &&
              response.errorMessage
            ) {
              //exclude generic exception messages returned from API
              notificationService.showErrorToast(response.errorMessage);
            } else {
              notificationService.showErrorToast(
                `There was an error trying to start the flow.`
              );
            }
          });
      });
    }

    promise = promise
      .catch((error) => {
        this.isCurrentlyStartingFlow = false;
        console.error(error);
      })
      .then(() => {
        this.isCurrentlyStartingFlow = false;
      });

    return promise;
  }

  hasSubjectError(): boolean {
    const subject = this.newFlowForm.subject;
    return subject.$pristine || subject.$invalid;
  }

  private _transformForStartFlow({ searchEntityType, id }) {
    const { flowinglyConstants } = this;
    const param = { userId: '', groupId: '' };
    switch (searchEntityType) {
      case flowinglyConstants.searchEntityType.USER:
        param.userId = id;
        break;
      case flowinglyConstants.searchEntityType.GROUP:
        param.groupId = id;
        break;
    }
    return param;
  }

  public hasStarted(): boolean {
    // introduced by at FLOW-4651, you can now open this page in 2 states.
    // It can be started (old flow of events) or not-started (things)
    // are configured on this page.
    return this.runnerFlowService.hasStarted;
  }

  /**
   * This code is copied from runner.flow.header\runner.flow.header.component
   */
  private getFileCount(flow: IFormattedFlow) {
    let count = 0;
    if (flow.Steps) {
      for (let i = 0; i < flow.Steps.length; i++) {
        const step = flow.Steps[i];
        for (let j = 0; j < step.Fields.length; j++) {
          const field = step.Fields[j];
          if (field.HasFiles) {
            count++;
          } else if (field.Type === 'table') {
            if (field.TableHasRows) {
              const table = JSON.parse(field.Text);
              angular.forEach(table.rows, function (row) {
                angular.forEach(row.cells, function (cell) {
                  if (cell.type === 4) {
                    if (cell.value) {
                      count++;
                    }
                  }
                });
              });
            }
          }
        }
      }
      return count;
    }
  }
}

angular
  .module('flowingly.runner.flow')
  .controller('runnerFlowController', RunnerFlowController);
