/**
 * @ngdoc service
 * @name teamApiService
 * @module flowingly.runner.services
 *
 * @description A service responsible solely for fetching teams data
 *
 * ## Notes
 * No Caching or formatting to be performed here.
 *
 */

'use strict';
import angular from 'angular';

/// i.e. we return response.data.DataModel so the consumer can work with the data directly
angular
  .module('flowingly.runner.services')
  .factory('teamApiService', teamApiService);

teamApiService.$inject = ['$http', 'APP_CONFIG'];

function teamApiService($http: angular.IHttpService, APP_CONFIG: AppConfig) {
  const service = {
    teams: [],
    getTeamsWithOptions: getTeamsWithOptions,
    getUsersInTeam: getUsersInTeam,
    saveTeam: saveTeam,
    deleteTeam: deleteTeam
  };

  const teamsApiBaseUrl = APP_CONFIG.apiBaseUrl + 'teams';

  return service;

  //////////// Public API Methods

  function deleteTeam(item, actor) {
    return $http({
      url: APP_CONFIG.apiBaseUrl + 'teams/',
      method: 'DELETE',
      data: {
        deletedTeamId: item.id,
        replacedActorId: actor
      },
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      }
    });
  }

  function saveTeam(team) {
    const isNewTeam = team.id === undefined || team.id === '';

    return $http.post(teamsApiBaseUrl, team).then(function (response) {
      const savedTeam = response.data;

      if (isNewTeam) {
        service.teams.push(response.data);
      } else {
        for (const [index, t] of service.teams.entries()) {
          if (t.id === savedTeam.id) {
            service.teams[index] = savedTeam;
            break;
          }
        }
      }

      sortTeamsByName();
      return savedTeam;
    });
  }

  function getTeamsWithOptions(options) {
    return $http
      .post(teamsApiBaseUrl + '/getTeamsWithOptions', options, {
        noSpinner: true
      })
      .then(function (response) {
        angular.copy(response.data, service.teams);
        //sortTeamsByName();
        return response.data;
      });
  }

  function getUsersInTeam(teamId) {
    return $http
      .get(teamsApiBaseUrl + '/getUsersInTeam?teamId=' + teamId, {
        noSpinner: true
      })
      .then(function (response) {
        return response.data;
      });
  }

  // PRIVATE METHODS /////////////////////////////////////////////////////////////////
  function sortTeamsByName() {
    service.teams.sort((t1, t2) => {
      const t1NameToCompare = t1.name.trim().toLowerCase();
      const t2NameToCompare = t2.name.trim().toLowerCase();

      if (t1NameToCompare < t2NameToCompare) {
        return -1;
      }

      if (t1NameToCompare > t2NameToCompare) {
        return 1;
      }

      return t1NameToCompare > t2NameToCompare;
    });
  }
}

export type TeamApiServiceType = ReturnType<typeof teamApiService>;
