/**
 * @ngdoc service
 * @name runnerPublicFormService
 * @module flowingly.runner.services
 *
 * @description A helper service for public form related functionality
 *
 * ## Notes
 *
 * ###API
 * * getFormById - get the form data by form guid
 * * submitForm - submit the form and then will complete the first step under the hood
 */

import { SharedAngular } from '@Client/@types/sharedAngular';
import angular from 'angular';

export interface ICardTask {
  deadLineNumber: any;
  deadLineType: string;
  description: string;
  formElements: IFormElement[];
  guid: string;
  id: string;
  name: string;
}

export interface IFormElement {
  actionButtons: IFormElementActionButton;
  alignment: string;
  attachDocumentFileIds: string[];
  conditions: any;
  customUnits: any;
  customValidation: any;
  dataSource: any;
  dbDataSource: any;
  defaultFormFieldId: any;
  defaultPreviousStepId: any;
  defaultValueOption: string;
  disabled: boolean;
  displayInCompletedSteps: any;
  displayName: string;
  formulaConfig: any;
  hideByDefault: any;
  id: string;
  instruction: any;
  label: any;
  lookupConfig: any;
  name: string;
  noLabel: boolean;
  options: any;
  placeholder: any;
  searchable: boolean;
  tableSchema: any;
  tooltip: any;
  type: string;
  typeName: string;
  validation: IFormElementValidation;
  value: string;
}

export interface IFormElementActionButton {
  hideSettings: boolean;
  hideDelete: boolean;
}

export interface IFormElementValidation {
  required: boolean;
  minlength: number;
  maxlength: number;
  pattern: any;
  messages: any;
}

export class RunnerPublicFormService {
  constructor(
    private $window: angular.IWindowService,
    private runnerPublicFormApiService: RunnerPublicFormApiService,
    private notificationService: SharedAngular.NotificationService,
    private fileService: SharedAngular.FileService
  ) {}

  getFormById(flowModelId: string) {
    return this.runnerPublicFormApiService
      .getFormById(flowModelId, this.inIframe(), false)
      .then((data) => {
        // validating the response result
        if (
          data === undefined ||
          data === null ||
          data.success === false ||
          data.errorMessage !== null ||
          data.errorCode !== null ||
          data.dataModel === null ||
          data.dataModel.cardTasks === null ||
          data.dataModel.fields === null ||
          data.dataModel.stepName === null ||
          data.dataModel.stepName === 'Invalid Form'
        ) {
          return undefined;
        }
        this.fileService.setUser(
          data.dataModel.userId,
          data.dataModel.businessId
        ); // Need to setup the user and business in the File Service
        const cardTasks = JSON.parse(data.dataModel.cardTasks) as ICardTask;
        const schema = { fields: [] };

        if (cardTasks !== null && cardTasks !== undefined) {
          schema.fields = cardTasks.formElements;
        }

        data.dataModel.schema = schema;

        return data.dataModel;
      });
  }

  submitForm(
    flowModelId: string,
    publicFormId: string,
    formData,
    stepId: string
  ) {
    const postData = {
      publicFormId: publicFormId,
      cardData: JSON.stringify(formData),
      stepId: stepId,
      subject: 'Public Form Submission'
    };

    return this.runnerPublicFormApiService.submitForm(
      flowModelId,
      postData,
      false
    );
  }

  uploadAnonymousFile(flowModelId, stepId, file, fieldKey) {
    if (file.size >= 50 * 1024 * 1024) {
      this.notificationService.showErrorToast(
        'You cannot upload files greater than 50MB'
      );
      return Promise.reject('');
    }

    const formData = new FormData(document.createElement('form'));
    const fileName = file.name.substr(file.name.lastIndexOf('\\') + 1);
    formData.append(fileName, file);

    return this.runnerPublicFormApiService.uploadAnonymousFile(
      formData,
      flowModelId,
      stepId,
      fieldKey
    );
  }

  inIframe() {
    try {
      return this.$window.self !== this.$window.top;
    } catch (e) {
      return true;
    }
  }
}

angular
  .module('flowingly.runner.services')
  .factory('runnerPublicFormService', [
    '$window',
    'runnerPublicFormApiService',
    'notificationService',
    'fileService',
    ($window, runnerPublicFormApiService, notificationService, fileService) =>
      new RunnerPublicFormService(
        $window,
        runnerPublicFormApiService,
        notificationService,
        fileService
      )
  ]);

export type RunnerPublicFormServiceType = InstanceType<
  typeof RunnerPublicFormService
>;
