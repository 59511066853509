'use strict';
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular from 'angular';
import { IStateProvider, IStateService } from 'angular-ui-router';

// Register the application's routes configuration.
angular.module('flowingly.runner.maintenance').config(config);

// Inject the dependencies.
config.$inject = ['$stateProvider'];

function config($stateProvider: IStateProvider) {
  $stateProvider.state('app.runner.maintenance.settings', {
    url: '/settings',
    params: { title: 'Maintenance - Settings' },
    views: {
      childcontent: {
        templateUrl:
          'Client/runner.maintenance/settings/runner.maintenance.settings.tmpl.html',
        controllerAs: '$ctrl',
        controller: [
          '$state',
          'maintenanceService',
          'authService',
          'dialogService',
          function (
            $state: IStateService,
            maintenanceService: MaintenanceService,
            authService: AuthService,
            dialogService: SharedAngular.DialogService
          ) {
            const $ctrl = this;

            $ctrl.businesses = [];
            $ctrl.updateSetting = updateSetting;
            $ctrl.loadBusinessSettings = loadBusinessSettings;
            $ctrl.populateSettingInputs = populateSettingInputs;
            $ctrl.clearCachedSettings = clearCachedSettings;

            $ctrl.presetBusinessSettings = [
              'EnableWebhooks',
              'EnableStepTasks',
              'EnableExago',
              'EnableCustomPublicFormSubjects',
              'AllowProcessMap',
              'AllowStepRules'
            ];

            const scrollOptions: ScrollIntoViewOptions = {
              behavior: 'smooth',
              block: 'center'
            };

            authService.getUserDeferred().then((user) => {
              maintenanceService.getBusinesses().then((businesses) => {
                $ctrl.businesses = businesses.sort((a, b) =>
                  a.name.localeCompare(b.name)
                );
                $ctrl.selectedBusinessId = user.businessId;

                $ctrl.loadBusinessSettings($ctrl.selectedBusinessId);
              });
            });

            // Private Methods
            function loadBusinessSettings(businessId) {
              return maintenanceService
                .getBusinessSettings(businessId)
                .then((settings) => {
                  $ctrl.businessSettings = Object.keys(settings)
                    .map((key) => {
                      return { name: key, value: settings[key] };
                    })
                    .sort((a, b) => {
                      return a.name.localeCompare(b.name);
                    });
                });
            }

            function updateSetting(businessId, settingName, settingValue) {
              maintenanceService
                .updateBusinessSetting(businessId, settingName, settingValue)
                .then((success) => {
                  if (success) {
                    $ctrl
                      .loadBusinessSettings($ctrl.selectedBusinessId)
                      .then(() => {
                        $ctrl.businessSettings.find(
                          (setting) => setting.name === settingName
                        ).updated = true;

                        const settings =
                          document.getElementsByClassName('setting-name');
                        Array.from(settings)
                          .find((x) => x.textContent === settingName)
                          .scrollIntoView(scrollOptions);
                      });
                  } else {
                    const options = {
                      headerText: 'Failed to update setting',
                      message: `Error while trying to set "${settingName}" to "${settingValue}"`
                    };
                    dialogService.showMessageDialog(options);
                  }
                });
            }

            function clearCachedSettings() {
              maintenanceService.clearCachedSettings().then((success) => {
                if (success) {
                  $ctrl.loadBusinessSettings($ctrl.selectedBusinessId);
                } else {
                  const options = {
                    headerText: 'Failed to clear caches',
                    message: 'Error while clearing setting caches'
                  };
                  dialogService.showMessageDialog(options);
                }
              });
            }

            function populateSettingInputs(name, value) {
              $ctrl.addSettingName = name;
              $ctrl.addSettingValue = value;
              document
                .getElementById('addSettingInput')
                .scrollIntoView(scrollOptions);
            }
          }
        ]
      }
    }
  });
}
