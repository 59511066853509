/**
 * @ngdoc directive
 * @name flowHistoryStep
 * @module flowingly.runner.flow
 * @description This component is used to display information about a completed step.
 * @usage
 * ```
     <flow-history-step ng-if="step.completed" step="step" is-mobile="$ctrl.isMobile"></flow-history-step>
 * ``` 
 * ### Notes
 * See Also: https://bizflo.atlassian.net/wiki/display/TECH/Angular+Flow+Components
 * ### Properties
 * #### Inputs
 * * step: the step data to display (JSON)
 * * isMobile: show mobile view if set
 */

'use strict';
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular, {
  IHttpService,
  ISCEService,
  ITimeoutService,
  IWindowService
} from 'angular';

angular.module('flowingly.runner.flow').component('flowHistoryStep', {
  bindings: {
    step: '<',
    isMobile: '<', //show mobile view if set
    flow: '<'
  },
  controller: [
    '$sce',
    'flowinglyConstants',
    '$http',
    'sessionService',
    '$timeout',
    '$window',
    'pubsubService',
    'fileService',
    function (
      $sce: ISCEService,
      flowinglyConstants: SharedAngular.FlowinglyConstants,
      $http: IHttpService,
      sessionService: SharedAngular.SessionService,
      $timeout: ITimeoutService,
      $window: IWindowService,
      pubsubService: SharedAngular.PubSubService,
      fileService: SharedAngular.FileService
    ) {
      const $ctrl = this;
      $ctrl.$sce = $sce;
      $ctrl.isMobileApp = false;
      $ctrl.completedApprovals = $ctrl.step.CompletedApprovals;
      $ctrl.StepStatus = getStepStatus();
      $ctrl.numberOfCompletedApprovals = $ctrl.completedApprovals.length;
      $ctrl.flowinglyConstants = flowinglyConstants;
      $ctrl.StepWebhooks = undefined;
      $ctrl.StepTasks = undefined;

      $ctrl.displayStepWebhooks = () => {
        return $ctrl.StepWebhooks != null && $ctrl.StepWebhooks.length > 0;
      };

      $ctrl.displayStepTasks = () => {
        return $ctrl.StepTasks != null && $ctrl.StepTasks.length > 0;
      };

      $ctrl.$onInit = function () {
        $timeout(() => {
          fileService.addFileDownloadClickEventListener();
        }, 500); // Adding execution delay avoids clashing with the instruction field calling the same
        // function and in adding duplicate event listeners.

        pubsubService.subscribe(
          'SIGNALR_RUNNER_STEP_WEBHOOK_UPDATED',
          (event, data) => {
            // Update the status of the webhook. E.g Pending to Success.
            if (data != null && $ctrl.StepWebhooks != null) {
              const json = JSON.parse(data);
              if (
                json != null &&
                json.stepId != null &&
                json.webhookId != null
              ) {
                for (let i = 0; i < $ctrl.StepWebhooks.length; i++) {
                  const hook = $ctrl.StepWebhooks[i];
                  if (hook && hook.Id === json.webhookId) {
                    $ctrl.StepWebhooks[i] = {
                      Status: json.status,
                      Id: json.webhookId,
                      Name: hook.Name
                    };
                    break;
                  }
                }
              }
            }
          },
          'flowHistoryStep'
        );

        $ctrl.StepWebhooks = $ctrl.step.Webhooks;

        // Sort the step tasks by their completed date.
        const tasks = $ctrl.step.StepTasks;
        if (tasks != null) {
          tasks.sort(function (a, b) {
            return new Date(b.CompletedDateUtc) - new Date(a.CompletedDateUtc);
          });
          $ctrl.StepTasks = tasks;
        }
      };

      $ctrl.showOptional = showOptional;
      $ctrl.useFlowHistoryFieldValueDirective = (type) => {
        if (
          type !== 'fileupload' &&
          type !== 'textArea' &&
          type !== 'table' &&
          type !== 'instruction' &&
          type !== 'multiselectlist' &&
          type !== 'dropdown' &&
          type !== 'radiobuttonlist' &&
          type !== 'tasklist' &&
          type !== 'checkbox' &&
          type !== 'signature' &&
          type !== 'approvecomment' &&
          type !== 'attachdocument' &&
          type !== 'image'
        ) {
          return true;
        } else {
          return false;
        }
      };

      $ctrl.isMobileApp = isMobileApp();

      //public methods
      function showOptional(type, strValidation) {
        if (type === 'lookup' || type === 'attachdocument') {
          return false;
        }
        const validation = JSON.parse(strValidation);

        if (!validation || !validation.required) {
          return true;
        }

        return false;
      }

      function isMobileApp() {
        //var isiOSMobileApp = /FlowinglyMobile/.test(navigator.userAgent) && /Apple Computer, Inc./.test(navigator.vendor);
        const isMobileApp = /FlowinglyMobile/.test(navigator.userAgent);

        if (isMobileApp) return true;
      }

      function getStepStatus() {
        switch ($ctrl.step.Status) {
          case 0:
            return 'ToDo';
          case 1:
            return 'Hold';
          case 2:
            return 'Completed';
          case 3:
            return 'Abandoned';
          default:
        }
      }

      $ctrl.isTableHasFileUpload = function (x) {
        if (x.indexOf('"type":4') > -1) return true;
      };
    }
  ],
  templateUrl:
    'Client/runner.flow/runner.flow.history/runner.flow.history.step.component.tmpl.html'
});
