/**
 * @ngdoc service
 * @name runnerImportService
 * @module flowingly.runner.services
 *
 * @description A helper service for csv import users related functionality
 *
 * ## Notes
 *
 * ###API
 * * isCsvFile - check if the file type is csv format
 * * parseCsvFile - parse given csv file (file is a File object obtained from the DOM)
 * * stringIsNotEmpty - check if string is not undefined or only contains space
 * * isCorrectEmailFormat - check if valid email format
 *
 */

import angular from 'angular';

export class RunnerImportService {
  constructor(private papaParseService) {}

  isCsvFile(name) {
    return name.toLowerCase().endsWith('.csv');
  }

  parseCsvFile(file, completeCallBack, errorCallBack) {
    this.papaParseService.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: completeCallBack,
      error: errorCallBack
    });
  }

  stringIsNotEmpty(str) {
    return str && str.trim() !== '';
  }

  isCorrectEmailFormat(email) {
    const format =
      /^[a-zA-Z0-9\-_]+([.'+][a-zA-Z0-9\-_]+)*@[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*(\.[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*)*\.[a-zA-Z]{2,4}$/;
    return format.test(email);
  }
}

angular
  .module('flowingly.runner.services')
  .factory('runnerImportService', [
    'papaParseService',
    (papaParseService) => new RunnerImportService(papaParseService)
  ]);

export type RunnerImportServiceType = InstanceType<typeof RunnerImportService>;
