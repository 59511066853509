/**
 * @ngdoc type
 * @module flowingly.runner.templates
 * @name runnerTemplatesLibraryController
 *
 * @description Controller for Template Library.
 */
'use strict';

import { SharedAngular } from '@Client/@types/sharedAngular';
import angular from 'angular';

export default class RunnerTemplatesLibraryController {
  static $inject = [
    'sessionService',
    'permissionsService',
    'flowinglyConstants',
    '$state',
    'APP_CONFIG'
  ];

  private isTemplateAdmin: boolean;
  private templateManagementEnabled: boolean;

  constructor(
    private sessionService: SharedAngular.SessionService,
    private permissionsService: SharedAngular.PermissionsService,
    private flowinglyConstants: SharedAngular.FlowinglyConstants,
    private $state: angular.ui.IStateService,
    private APP_CONFIG: AppConfig
  ) {
    this.sessionService.onReady(() => {
      if (!this.APP_CONFIG.enableTemplateLibrary) {
        this.$state.go('app.runner.flowsactive');
      }
      this.isTemplateAdmin = this.permissionsService.currentUserHasPermission(
        this.flowinglyConstants.permissions.TEMPLATE_ADMIN
      );

      this.templateManagementEnabled = this.APP_CONFIG.enableTemplateManagement;
    });
  }
}

angular
  .module('flowingly.runner.templates')
  .controller(
    'runnerTemplatesLibraryController',
    RunnerTemplatesLibraryController
  );
