/**
 * @ngdoc service
 * @name roleApiService
 * @module flowingly.runner.services
 *
 * @description A service responsible solely for fetching roles data
 *
 * ## Notes
 * No Caching or formatting to be performed here.
 *
 */
'use strict';
import angular from 'angular';

angular
  .module('flowingly.runner.services')
  .factory('roleApiService', roleApiService);

roleApiService.$inject = ['$http', 'APP_CONFIG'];

function roleApiService($http: angular.IHttpService, APP_CONFIG: AppConfig) {
  const service = {
    getRoles: getRoles,
    searchRoles: searchRoles,
    saveRole: saveRole,
    deleteRole: deleteRole,
    getPermissions: getPermissions
  };
  const rolesApiBaseUri = APP_CONFIG.apiBaseUrl + 'roles';

  return service;

  function getRoles(noSpinner) {
    return $http
      .get<IResponseData>(rolesApiBaseUri, {
        noSpinner: noSpinner || false
      })
      .then(function (response) {
        return response.data.dataModel;
      });
  }

  function searchRoles(options, noSpinner) {
    return $http
      .post(rolesApiBaseUri, options, { noSpinner: noSpinner || false })
      .then(function (response) {
        return response.data;
      });
  }

  function saveRole(role) {
    return $http.put(rolesApiBaseUri, role);
  }

  function deleteRole(roleId) {
    return $http.delete(rolesApiBaseUri + `/${roleId}`);
  }

  function getPermissions() {
    return $http
      .get(rolesApiBaseUri + '/permissions')
      .then((response) => response.data);
  }
}

export type RoleApiServiceType = ReturnType<typeof roleApiService>;
