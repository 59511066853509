import { angular2react } from '@Shared.Angular/flowingly.helper/angular2react';
import { CommentsComponent } from '@Shared.Angular/flowingly.components/comments/comments.component';

type Props = {
  commentTargetId: string;
  commentTargetTypeFromReact: string;
  commentList: string[];
  isMobile: boolean;
  flowOwnerOnly: boolean;
};

export const FlowinglyComments = angular2react<Props>(
  'flowinglyComments',
  new CommentsComponent()
);
