/**
 * @ngdoc directive
 * @name flowLibraryHeader
 * @module flowingly.runner.library
 * @description  This comppnent is used to display the header for the flow library and will contain controls
 * @usage
 * ```
      <flow-library-header></flow-library-header>
 * ``` 
 */
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular from 'angular';
import { IStateService } from 'angular-ui-router';

angular.module('flowingly.runner.library').component('flowLibraryHeader', {
  bindings: {
    canCreateFlows: '<'
  },
  templateUrl: 'Client/runner.library/runner.library.header.tmpl.html',
  controller: [
    'tempModelerUrlService',
    '$state',
    'APP_CONFIG',
    'sessionService',
    'appInsightsService',
    function (
      tempModelerUrlService: SharedAngular.TempModelerUrlService,
      $state: IStateService,
      APP_CONFIG: AppConfig,
      sessionService: SharedAngular.SessionService,
      appInsightsService: SharedAngular.AppInsightsService
    ) {
      sessionService.onReady(() => {
        this.templateLibraryEnabled = APP_CONFIG.enableTemplateLibrary;
      });

      this.onAddFlowModelClick = () => {
        tempModelerUrlService.openModeler();
      };

      this.viewTemplatesClicked = () => {
        appInsightsService.trackPageView('template-library', {
          from: 'library'
        });

        $state.go('app.runner.templates.library');
      };
    }
  ]
});
