/**
 * @ngdoc directive
 * @name runnerSetupDeleteWarnings
 * @module flowingly.runner.setup
 * @description  This component is used to dipslay warnings related to deleteing an entity
 * @usage
 * ```
     
 * ``` 
 * ### Notes
 *
 * 
 * ### Properties
 * #### Inputs
 * * warnings: the lsit of warnings (JSON[])
 */
(function () {
  'use strict';

  angular
    .module('flowingly.runner.setup')
    .component('runnerSetupDeleteWarnings', {
      bindings: {
        entityName: '<',
        warnings: '<',
        deleteConfirmed: '&',
        entityType: '<'
      },
      templateUrl:
        'Client/runner.setup/runner.setup.delete/runner.setup.delete.warnings.tmpl.html',
      controller: [
        'Enums',
        function (enums) {
          var $ctrl = this;

          $ctrl.confirmDelete = undefined;
          $ctrl.deletePhrase = 'delete'; //this could be configurable
          $ctrl.showCannotUndone =
            $ctrl.entityType !== enums.deleteEntityTypes.flowModelEntityType;

          $ctrl.onInputChange = onInputChange;

          function onInputChange() {
            var confirmed = false;
            if ($ctrl.confirmDelete) {
              confirmed =
                $ctrl.confirmDelete.toLowerCase() === $ctrl.deletePhrase;
            }
            $ctrl.deleteConfirmed({ confirmed: confirmed });
          }
        }
      ]
    });
})();
