/**
 * @ngdoc directive
 * @name runnerPageDescription
 * @module flowingly.runner.directives
 * @description This is a component for displaying the page descriptions such as what this page about, help link and action primary button(s)
 *
 * Input attributes
 * isShow - determine should show the page description bar or not eg. we don't intend to show it in mobile view
 * pageDescription - the page description text
 * helpLink - the help link go to the relative KB page
 * stickyTopSpacing - sticky 3rd party library option "topSpacing", refer https://github.com/garand/sticky for more information
 *
 * @usage
 * ```
 * <runner-page-description page-header-text="Start Flows"></runner-page-description>
 * ```
 */
import angular from 'angular';

class PageDescriptionComponentController {
  static $inject = ['$state', '$timeout', '$window'];
  public stickyTopSpacing: number;
  public backLinkState: string;

  constructor(
    private $state,
    private $timeout: ng.ITimeoutService,
    private $window
  ) {}

  $onInit() {
    this.$timeout(() => {
      setPageHeaderDescriptionWidth();
    });
    this.$window.onresize = function () {
      setPageHeaderDescriptionWidth();
    };
    //set top banner (page header description) width during page load and resize
    function setPageHeaderDescriptionWidth() {
      const descriptionHorizontalPadding = 25;
      (angular.element('.page-header-description') as any).width(
        (angular.element('.page-header-description_wrapper') as any).width() -
          descriptionHorizontalPadding
      );
    }
  }

  onBackButtonClick(): void {
    if (this.backLinkState && this.backLinkState !== '') {
      this.$state.go(this.backLinkState);
    }
  }
}

class PageDescriptionComponent implements angular.IComponentOptions {
  public bindings: Bindings;

  constructor() {
    this.bindings = {
      isShow: '<',
      pageDescription: '@',
      helpLink: '@',
      pageHeaderText: '@',
      backLinkText: '@',
      backLinkState: '@',
      stickyTopSpacing: '<'
    };
  }

  transclude = true;

  controller = PageDescriptionComponentController;

  template = `
            <div class="user-menu__back-button" ng-show="$ctrl.backLinkText">
                <div ng-click="$ctrl.onBackButtonClick()"><i id="back-button" class="fa-light fa-arrow-left" aria-hidden="true"></i><span class="user-menu__back-text">{{$ctrl.backLinkText}}</span></div>
            </div>
            <div class="page-header-description__top-space"></div>
            <div ng-if="$ctrl.isShow" class="page-header-description_wrapper">
                <div class="page-header-description">
                    <div class="page-header-component">
                        <h4 class="runner-page-header">
                            {{ $ctrl.pageHeaderText }}
                        </h4>
                        <div class="page-header-description__text">{{ $ctrl.pageDescription }}</div>
                        <div ng-show="!!$ctrl.helpLink" class="page-header-description__help-link"><a ng-href="{{ $ctrl.helpLink }}" target="_blank" ng-click="$ctrl.onHelpLinkClick()">Learn more.</a></div>
                    </div>

                    <div class="page-header-description__contents">
                        <div class="page-header-description__actions"><ng-transclude></ng-transclude></div>
                    </div>
                </div>
            </div>
        `;
}

angular
  .module('flowingly.runner.directives')
  .component('runnerPageDescription', new PageDescriptionComponent());
