import angular from 'angular';
/**
 * @ngdoc module
 * @name flowingly.runner.library
 * @module flowingly.runner.library
 * @description This module is used to display the flow model library
 */
const m = angular.module('flowingly.runner.library', [
  'kendo.directives',
  'flowingly.directives',
  'flowingly.components'
]);

m.run([
  '$templateCache',
  function ($templateCache: angular.ITemplateCacheService) {
    $templateCache.put(
      'Client/runner.library/runner.library.flow.model.owner/runner.library.flow.model.owner.dialog.flow-model-tab.tmpl.html',
      require('./runner.library.flow.model.owner/runner.library.flow.model.owner.dialog.flow-model-tab.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.library/runner.library.flow.model.owner/runner.library.flow.model.owner.dialog.report-tab.tmpl.html',
      require('./runner.library.flow.model.owner/runner.library.flow.model.owner.dialog.report-tab.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.library/runner.library.flow.model.owner/runner.library.flow.model.owner.dialog.tmpl.html',
      require('./runner.library.flow.model.owner/runner.library.flow.model.owner.dialog.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.library/runner.library.header.tmpl.html',
      require('./runner.library.header.tmpl.html').default
    );

    $templateCache.put(
      'Client/runner.library/runner.library.list.actions.tmpl.html',
      require('./runner.library.list.actions.tmpl.html').default
    );

    $templateCache.put(
      'Client/runner.library/runner.library.list.tmpl.html',
      require('./runner.library.list.tmpl.html').default
    );

    $templateCache.put(
      'Client/runner.library/runner.library.tmpl.html',
      require('./runner.library.tmpl.html').default
    );
  }
]);
