'use strict';
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular, { ILocationService, a0 } from 'angular';

type Svcs = {
  lodashService: Lodash;
  $location: ILocationService;
  APP_CONFIG: AppConfig;
  fileService: SharedAngular.FileService;
  sessionService: SharedAngular.SessionService;
  store: a0.storage.IStoreService;
  tokenService: SharedAngular.TokenService;
};

class RedirectFileService {
  private svcs: Svcs;

  constructor(...services) {
    this.svcs = services[0].zipObject($inject, services);

    this.beginFileRequest = this.beginFileRequest.bind(this);
    this.hasPendingRequest = this.hasPendingRequest.bind(this);
    this.executeFileRequest = this.executeFileRequest.bind(this);
    this.destroyPendingRequest = this.destroyPendingRequest.bind(this);
  }

  /**
   *  the runner app has code that can eat this token
   *  and redirect based on this token.
   */
  setPendingRequestAsJwt(fileId) {
    this.svcs.store.set(this.svcs.APP_CONFIG.redirect.FILE_STORAGE_KEY, fileId);
  }

  getPendingRequest() {
    return this.svcs.store.get(this.svcs.APP_CONFIG.redirect.FILE_STORAGE_KEY);
  }

  hasPendingRequest() {
    const fileId = this.getPendingRequest();
    return !!fileId;
  }

  beginFileRequest() {
    return this.svcs.sessionService
      .onAuthenticated()
      .then(this.executeFileRequest);
  }

  destroyPendingRequest() {
    return this.svcs.store.remove(
      this.svcs.APP_CONFIG.redirect.FILE_STORAGE_KEY
    );
  }

  executeFileRequest() {
    const { fileService, sessionService, tokenService } = this.svcs;
    const fileId = this.getPendingRequest();

    if (fileId && fileId.length) {
      const user = sessionService.getUser();
      fileService.setUser(user.id, tokenService.getTenant().id);
      return fileService
        .downloadById(fileId)
        .finally(this.destroyPendingRequest)
        .catch(console.error);
    }
  }
}
const $inject = [
  'lodashService',
  '$location',
  'APP_CONFIG',
  'fileService',
  'sessionService',
  'store',
  'tokenService'
];
angular
  .module('flowingly.runner.redirect')
  .service('redirectFileService', [...$inject, RedirectFileService]);

export type RedirectFileServiceType = InstanceType<typeof RedirectFileService>;
