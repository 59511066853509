import { AppInsightsConfig } from '@Shared.Angular/flowingly.services/appInsights.service';
import { EnvSettings, LoginSettings } from './interfaces/flowingly.window';

function valueIsTrue(value) {
  return typeof value === 'string' && value.toLowerCase().trim() === 'true';
}
export default class RUNNER_APP_CONFIG {
  // these variables will be initialized via window from settings.json
  flowinglyEnvironment: string;
  logoUrl: string;
  sessionTimeoutInSecond = 1800;
  auth0ClientId: string;
  auth0Domain: string;
  apiBaseUrl: string;
  exagoBaseUrl: string;

  appInsightsRunnerConfig: AppInsightsConfig | undefined;
  slackEndPoint: string | undefined;

  cfEnableConditionalRules: boolean;
  showConfidentialFlowOption = false;
  enableTenantSwitching: boolean;
  enableTemplateLibrary: boolean;
  enableTemplateManagement: boolean;

  smartlookEnableIdentify = false;
  smartlookEnableInputRecording = false;

  allowStepRules = false;

  brandingHelpTitle = '';
  brandingHelpUri = '';
  brandLogoClass = 'brand-logo-image';

  defaultDays = 5;

  disableAdminCategoryEdit = false;
  disableAdminDatabaseDelete = false;
  disableAdminTeamEdit = false;
  disableAdminUserEdit = false;
  disableViewFlowModel = false;
  disableXssValidation = true;
  enableConsoleLogging = true;
  disableFlowModelCreation: boolean;

  enableExago = false;
  enableReportOwner = false;
  enableStepTasks = false;
  enableWebhooks = false;

  exagoConfig = {
    defaultDashboard: {
      folder: 'Dashboards',
      name: 'My Consumption Dashboard'
    }
  };
  favIcon: string;
  flowModelFeedbackVisibility = 'All';
  flowFilterMaxCharacters;
  flowModelSizeLimit = 150000;

  fmoCanPublish = true;
  gridReportPageSize = 25;
  hideInviteUsers = false;
  hideSupportButton = false;

  intercomConfig = {
    id: '',
    pollDelay: 5000
  };

  intercomId = '';
  intercomUrl = '';
  isDatabaseFeatureEnabled = false;
  keyForAuthLoggingId = 'AUTH_LOG_ID';
  localDateTimeFormat = 'DD/MM/YYYY h:mm:ss a';

  maximumStepTasksOnStep = 5;
  maximumWebhooksOnStep = 5;
  maxReportReturnRows = 1000;
  modeler_ActorType = {
    USER: 'user',
    GROUP: 'group',
    DYNAMIC: 'dynamic'
  };
  modeler_DynamicActor = {
    INITIATOR: 'Initiator',
    INITIATOR_MANAGER: 'InitiatorManager',
    INITIATOR_MANAGER_DISPLAY: 'Initiator Manager',
    PREVIOUS_ACTOR: 'PreviousActor',
    SELECT_DYNAMIC_ACTORS: 'SelectDynamicActors'
  };
  modelerUrl = '';
  outOfHoursSupportMessage = '';

  redirect = {
    LOCAL_STORAGE_KEY: 'flowingly.local.redirectRequest',
    QUERY_PARAM_KEY: 'redirectUri',
    FILE_PARAM_KEY: 'redirectFileId',
    FILE_STORAGE_KEY: 'flowingly.local.redirectFileRequest',
    OPEN_MODELER: 'openModeler',
    OPEN_FEEDBACK: 'openFeedback',
    OPEN_FILE: 'redirectFileId='
  };
  reports = {
    dateRange: 3, // in days
    pageSize: 100
  };
  runnerClosedLogoUrl = '';
  runnerOpenLogoUrl = '';
  runnerTitle = '';
  runnerUrl = window.origin;

  showReportDatesInUtc = false;
  auth0UseUniversalLogin = false;

  signalrServerUrl = '';
  stepIntegrationState = {
    // Matches C# enum IntegrationState.
    Processing: 0,
    Completed: 1,
    Override: 2,
    Canceled: 3
  };
  stepTaskPriority = [
    {
      id: '0',
      name: 'Low'
    },
    {
      id: '1',
      name: 'Medium'
    },
    {
      id: '2',
      name: 'High'
    }
  ];
  stepTaskStatus = {
    // Matches C# enum StepTaskStatus.
    InProgress: 0,
    WaitingForApproval: 1,
    Completed: 2,
    Cancel: 3
  };
  stepTimeCostVisibility = 'Both';
  supportHours = '';
  welcomeText = 'Welcome to Flowingly';
  enableDatabaseFeature = false;

  allowProcessMap = false;
  enableProcessMapsV2 = false;

  enableAuthenticationLogging = false;
  enableAppInsightsRunner = false;

  googleTagManagerId = '';

  loginAlertMessage = '';

  // settings in settings.json that are not overridden from database or business settings
  noOverwriteSettings = [];

  /**
   * Check if ok to overwrite the setting
   */
  isSettingOverwritable(settingName: string): boolean {
    const exist =
      Array.isArray(this.noOverwriteSettings) &&
      this.noOverwriteSettings.includes(settingName);
    return !exist;
  }

  private tryApplySetting(
    settings: Array<string>,
    settingName: string,
    isBoolean = false
  ) {
    const value = settings[settingName];
    let propertyName = settingName.replace(/[^A-Za-z]*/g, '');
    propertyName = propertyName.charAt(0).toLowerCase() + propertyName.slice(1);
    if (
      typeof value !== 'string' ||
      !this.isSettingOverwritable(propertyName)
    ) {
      return;
    }

    this[propertyName] = isBoolean ? valueIsTrue(value) : value;
  }

  /**
   * Pass data from window.settings to local fields
   * @param settings
   */
  public populateFromSettingsJSON(settings: EnvSettings) {
    // clear noOverwriteSettings
    this.noOverwriteSettings = [];

    for (const [key, value] of Object.entries(settings)) {
      this[key] = value;
      this.noOverwriteSettings.push(key);
    }
  }

  /**
   * Populate data from /settings/login?domainname api
   * @param loginSettings
   */
  public populateLoginSettings(loginSettings: LoginSettings) {
    const {
      welcomeText,
      loginAlertMessage,
      auth0UseUniversalLogin,
      logoUrl,
      title,
      favIcon,
      logging: {
        enableAuthenticationLogging,
        enableAppInsightsRunner,
        appInsightsRunnerConfig
      }
    } = loginSettings;
    if (this.isSettingOverwritable('welcomeText')) {
      this.welcomeText = welcomeText;
    }

    if (this.isSettingOverwritable('loginAlertMessage')) {
      this.loginAlertMessage = loginAlertMessage;
    }

    if (this.isSettingOverwritable('auth0UseUniversalLogin')) {
      this.auth0UseUniversalLogin = auth0UseUniversalLogin;
    }

    if (this.isSettingOverwritable('logoUrl')) {
      this.logoUrl = logoUrl;
    }

    if (this.isSettingOverwritable('enableAppInsightsRunner')) {
      this.enableAppInsightsRunner = enableAppInsightsRunner;
    }

    if (this.isSettingOverwritable('enableAuthenticationLogging')) {
      this.enableAuthenticationLogging = enableAuthenticationLogging;
    }

    if (this.isSettingOverwritable('appInsightsRunnerConfig')) {
      this.appInsightsRunnerConfig = appInsightsRunnerConfig;
    }

    if (this.isSettingOverwritable('runnerTitle')) {
      this.runnerTitle = title;
    }

    if (this.isSettingOverwritable('favIcon')) {
      this.favIcon = favIcon;
    }
  }

  /**
   * Populate data from account/settings api
   * @param settings
   */
  public populateBusinessSettings(settings) {
    // Ideally we would provide the setting type so we could iterate over settings
    // instead of having to define each setting like this.

    // In the meantime we should use tryApplySetting to keep things simple
    this.tryApplySetting(settings, 'EnableSetupRoles', true);
    this.tryApplySetting(settings, 'EnableTemplateLibrary', true);
    this.tryApplySetting(settings, 'EnableTemplateManagement', true);
    this.tryApplySetting(settings, 'DisableFlowModelCreation', true);

    if (this.isSettingOverwritable('enableDatabaseFeature')) {
      this.enableDatabaseFeature = valueIsTrue(
        settings['EnableDatabaseFeature']
      );
    }

    if (this.isSettingOverwritable('enableTenantSwitching')) {
      this.enableTenantSwitching = valueIsTrue(
        settings['EnableTenantSwitching']
      );
    }

    if (this.isSettingOverwritable('brandingHelpTitle')) {
      this.brandingHelpTitle = settings['Branding:HelpTitle'];
    }

    if (this.isSettingOverwritable('brandingHelpUri')) {
      this.brandingHelpUri = settings['Branding:HelpUri'];
    }

    if (this.isSettingOverwritable('showReportDatesInUtc')) {
      this.showReportDatesInUtc = valueIsTrue(settings['ShowReportDatesInUtc']);
    }

    if (
      settings['HideInviteUsers'] &&
      this.isSettingOverwritable('hideInviteUsers')
    ) {
      this.hideInviteUsers = valueIsTrue(settings['HideInviteUsers']);
    }

    if (settings['ModelerUrl'] && this.isSettingOverwritable('modelerUrl')) {
      this.modelerUrl = settings['ModelerUrl'];
    }

    if (
      settings['Flowingly.Core.ServiceCatalog.Api'] &&
      this.isSettingOverwritable('apiBaseUrl')
    ) {
      this.apiBaseUrl = settings['Flowingly.Core.ServiceCatalog.Api'] + '/api/';
    }

    if (
      settings['EnableConsoleLogging'] &&
      this.isSettingOverwritable('enableConsoleLogging')
    ) {
      this.enableConsoleLogging = valueIsTrue(settings['EnableConsoleLogging']);
    }

    if (
      settings['ShowConfidentialFlowOption'] &&
      this.isSettingOverwritable('showConfidentialFlowOption')
    ) {
      this.showConfidentialFlowOption = valueIsTrue(
        settings['ShowConfidentialFlowOption']
      );
    }

    if (
      settings['SlackEndPoint'] &&
      this.isSettingOverwritable('slackEndPoint')
    ) {
      this.slackEndPoint = settings['SlackEndPoint'];
    }

    if (
      settings['Flowingly.Core.ServiceCatalog.Communications'] &&
      this.isSettingOverwritable('signalrServerUrl')
    ) {
      this.signalrServerUrl =
        settings['Flowingly.Core.ServiceCatalog.Communications'] + '/signalr';
    }

    if (settings['IntercomAppId'] && this.isSettingOverwritable('intercomId')) {
      this.intercomId = settings['IntercomAppId'];
    }

    if (
      settings['auth0:UseUniversalLogin'] &&
      this.isSettingOverwritable('auth0UseUniversalLogin')
    ) {
      this.auth0UseUniversalLogin = valueIsTrue(
        settings['auth0:UseUniversalLogin']
      );
    }

    if (
      settings['auth0:ClientId'] &&
      this.isSettingOverwritable('auth0ClientId')
    ) {
      this.auth0ClientId = settings['auth0:ClientId'];
    }

    if (settings['auth0:Domain'] && this.isSettingOverwritable('auth0Domain')) {
      this.auth0Domain = settings['auth0:Domain'];
    }

    if (
      settings['FlowinglyEnvironment'] &&
      this.isSettingOverwritable('flowinglyEnvironment')
    ) {
      this.flowinglyEnvironment = settings['FlowinglyEnvironment'];
    }

    if (
      settings['SessionTimeoutInSecond'] &&
      this.isSettingOverwritable('sessionTimeoutInSecond')
    ) {
      this.sessionTimeoutInSecond = +settings['SessionTimeoutInSecond'];
    }

    if (this.isSettingOverwritable('allowProcessMap')) {
      if (
        settings['AllowProcessMap'] &&
        settings['AllowProcessMap'].toString().toLowerCase() === 'false'
      ) {
        this.allowProcessMap = false;
      } else {
        this.allowProcessMap = true;
      }
    }

    if (
      settings['EnableProcessMapsV2'] &&
      this.isSettingOverwritable('enableProcessMapsV2')
    ) {
      this.enableProcessMapsV2 = valueIsTrue(settings['EnableProcessMapsV2']);
    }

    const value = +settings['IntercomPollDelay'];
    if (Number.isFinite(value) && value > 0) {
      this.intercomConfig.pollDelay = value;
    } else {
      console.error(
        `Invalid IntercomPollDelay of ${value}. Using ${this.intercomConfig.pollDelay}`
      );
    }

    if (settings['EnableExago'] && this.isSettingOverwritable('enableExago')) {
      this.enableExago = valueIsTrue(settings['EnableExago']);

      if (settings['ExagoDefaultDashboardConfig']) {
        try {
          const temp = JSON.parse(settings['ExagoDefaultDashboardConfig']);

          if (temp['folder']) {
            this.exagoConfig.defaultDashboard.folder = temp['folder'];
          }

          if (temp['name']) {
            this.exagoConfig.defaultDashboard.name = temp['name'];
          }
        } catch (e) {
          console.error(
            'Problem parsing business setting ExagoDefaultDashboardConfig'
          );
        }
      }
    }

    if (
      settings['DisableXssValidation'] &&
      this.isSettingOverwritable('disableXssValidation')
    ) {
      this.disableXssValidation = valueIsTrue(settings['DisableXssValidation']);
    }

    if (
      settings['DisableViewFlowModel'] &&
      this.isSettingOverwritable('disableViewFlowModel')
    ) {
      this.disableViewFlowModel = valueIsTrue(settings['DisableViewFlowModel']);
    }

    if (
      settings['DisableAdminTeamEdit'] &&
      this.isSettingOverwritable('disableAdminTeamEdit')
    ) {
      this.disableAdminTeamEdit = valueIsTrue(settings['DisableAdminTeamEdit']);
    }

    if (
      settings['DisableAdminUserEdit'] &&
      this.isSettingOverwritable('disableAdminUserEdit')
    ) {
      this.disableAdminUserEdit = valueIsTrue(settings['DisableAdminUserEdit']);
    }

    if (
      settings['DisableAdminCategoryEdit'] &&
      this.isSettingOverwritable('disableAdminCategoryEdit')
    ) {
      this.disableAdminCategoryEdit = valueIsTrue(
        settings['DisableAdminCategoryEdit']
      );
    }

    if (
      settings['DisableAdminDatabaseDelete'] &&
      this.isSettingOverwritable('disableAdminDatabaseDelete')
    ) {
      this.disableAdminDatabaseDelete = valueIsTrue(
        settings['DisableAdminDatabaseDelete']
      );
    }

    if (
      settings['EnableReportOwner'] &&
      this.isSettingOverwritable('enableReportOwner')
    ) {
      this.enableReportOwner = valueIsTrue(settings['EnableReportOwner']);
    }

    if (
      settings['FMOCanPublish'] &&
      this.isSettingOverwritable('fmoCanPublish')
    ) {
      this.fmoCanPublish = valueIsTrue(settings['FMOCanPublish']);
    }

    if (settings['DefaultDays'] && this.isSettingOverwritable('defaultDays')) {
      this.defaultDays = +settings['DefaultDays'];
    }

    if (
      settings['SupportHours'] &&
      this.isSettingOverwritable('supportHours')
    ) {
      this.supportHours = settings['SupportHours'];
    }

    if (
      settings['HideSupportButton'] &&
      this.isSettingOverwritable('hideSupportButton')
    ) {
      this.hideSupportButton = valueIsTrue(settings['HideSupportButton']);
    }

    if (
      settings['OutOfHoursSupportMessage'] &&
      this.isSettingOverwritable('outOfHoursSupportMessage')
    ) {
      this.outOfHoursSupportMessage = settings['OutOfHoursSupportMessage'];
    }

    if (
      settings['FlowModelFeedbackVisibility'] &&
      this.isSettingOverwritable('flowModelFeedbackVisibility')
    ) {
      this.flowModelFeedbackVisibility =
        settings['FlowModelFeedbackVisibility'];
    }

    if (
      settings['StepTimeCostVisibility'] &&
      this.isSettingOverwritable('stepTimeCostVisibility')
    ) {
      this.stepTimeCostVisibility = settings['StepTimeCostVisibility'];
    }

    if (
      settings['MaxReportReturnRows'] &&
      this.isSettingOverwritable('maxReportReturnRows')
    ) {
      this.maxReportReturnRows = +settings['MaxReportReturnRows'];
    }

    if (
      settings['AllowStepRules'] &&
      this.isSettingOverwritable('allowStepRules')
    ) {
      this.allowStepRules = valueIsTrue(settings['AllowStepRules']);
    }

    if (
      settings['EnableWebhooks'] &&
      this.isSettingOverwritable('enableWebhooks')
    ) {
      this.enableWebhooks = valueIsTrue(settings['EnableWebhooks']);
    }

    if (
      settings['MaximumWebhooksOnStep'] &&
      this.isSettingOverwritable('maximumWebhooksOnStep')
    ) {
      this.maximumWebhooksOnStep =
        parseInt(settings['MaximumWebhooksOnStep']) || 5;
    }

    if (
      settings['EnableStepTasks'] &&
      this.isSettingOverwritable('enableStepTasks')
    ) {
      this.enableStepTasks = valueIsTrue(settings['EnableStepTasks']);
    }

    if (
      settings['MaximumStepTasksOnStep'] &&
      this.isSettingOverwritable('maximumStepTasksOnStep')
    ) {
      this.maximumStepTasksOnStep =
        parseInt(settings['MaximumStepTasksOnStep']) || 5;
    }

    if (
      settings['CF:EnableConditionalRules'] &&
      this.isSettingOverwritable('cfEnableConditionalRules')
    ) {
      this.cfEnableConditionalRules = valueIsTrue(
        settings['CF:EnableConditionalRules']
      );
    }

    if (
      settings['Smartlook:EnableIdentify'] &&
      this.isSettingOverwritable('smartlookEnableIdentify')
    ) {
      this.smartlookEnableIdentify = valueIsTrue(
        settings['Smartlook:EnableIdentify']
      );
    }

    if (
      settings['Smartlook:EnableInputRecording'] &&
      this.isSettingOverwritable('smartlookEnableInputRecording')
    ) {
      this.smartlookEnableInputRecording = valueIsTrue(
        settings['Smartlook:EnableInputRecording']
      );
    }

    if (
      settings['RunnerClosedLogoUrl'] &&
      this.isSettingOverwritable('runnerClosedLogoUrl')
    ) {
      this.runnerClosedLogoUrl = settings['RunnerClosedLogoUrl'];
    }

    if (
      settings['RunnerOpenLogoUrl'] &&
      this.isSettingOverwritable('runnerOpenLogoUrl')
    ) {
      this.runnerOpenLogoUrl = settings['RunnerOpenLogoUrl'];
    }

    if (
      settings['GoogleTagManagerId'] &&
      this.isSettingOverwritable('googleTagManagerId')
    ) {
      this.googleTagManagerId = settings['GoogleTagManagerId'];
    }
  }
}

// type of RUNNER_APP_CONFIG
export type AppConfigType = InstanceType<typeof RUNNER_APP_CONFIG>;
