import React, { useCallback, useState } from 'react';
import LeftPanel from './LeftPanel/LeftPanel';
import RightPanel from './RightPanel/RightPanel';
import { Box, Drawer } from '@mui/material';

type Props = {
  processMapId: string;
};

const ProcessMapView = (props: Props) => {
  const { processMapId } = props;
  const [selectedNodeData, setSelectedNodeData] = useState(null);
  const [open, setOpen] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const handleSelectedNodeDataChange = (node: unknown) => {
    setSelectedNodeData(node);
    if (node == null) {
      toggleDrawer(false);
    } else {
      toggleDrawer(true);
    }
  };

  const [drawerWidth, setDrawerWidth] = useState(500);

  const handleMouseDown = () => {
    document.addEventListener('mouseup', handleMouseUp, true);
    document.addEventListener('mousemove', handleMouseMove, true);
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    document.removeEventListener('mouseup', handleMouseUp, true);
    document.removeEventListener('mousemove', handleMouseMove, true);
    setIsDragging(false);
  };

  const handleMouseMove = useCallback((e: MouseEvent) => {
    const maxDrawerWidth = window.innerWidth / 1.5;
    const minDrawerWidth = 0;
    const newWidth = Math.min(
      maxDrawerWidth,
      Math.max(minDrawerWidth, window.innerWidth - e.clientX)
    );
    setDrawerWidth(newWidth);
  }, []);

  function toggleDrawer(open: boolean): void {
    setOpen(open);
  }

  return (
    <Box className={'process-map-v2-view-container'}>
      <LeftPanel
        processMapId={processMapId}
        onSelectedNodeDataChange={handleSelectedNodeDataChange}
      />
      <Drawer
        variant="persistent"
        anchor="right"
        hideBackdrop={true}
        open={open}
        PaperProps={{
          className: 'process-map-v2-view-drawer-paper',
          sx: {
            width: drawerWidth
          }
        }}
      >
        <Box
          onMouseDown={() => handleMouseDown()}
          className={`${'process-map-v2-view-resize-handle'} ${
            isDragging ? 'process-map-v2-view-resize-handle-is-dragging' : ''
          }`}
        >
          <div className={'process-map-v2-view-resize-handle-icon'}>
            <i className="dragger-icon-color fa-light fa-arrows-left-right fa-3x"></i>
          </div>
        </Box>
        <Box flexGrow={1} overflow={'auto'}>
          <RightPanel selectedNode={selectedNodeData} />
        </Box>
      </Drawer>
    </Box>
  );
};

export default ProcessMapView;
