/**
 * @ngdoc module
 * @name flowingly.runner
 * @module flowingly.runner
 * @description Root module for the application
 */
import angular from 'angular';
import './runner.import/runner.import.module';
import './runner.public-form/runner.public-form.module';
import './runner.directives/runner.directives.module';
import './runner.exago/runner.exago.module';
import './runner.exago.single/runner.exago.single.module';
import './runner.flow/runner.flow/runner.flow.module';
import './runner.flow.start/runner.flow.start.module';
import './runner.flows.active/runner.flows.active.module';
import './runner.flows.imin/runner.flows.imin.module';
import './runner.flows.search/runner.flows.search.module';
import './runner.flows.todo/runner.flows.todo.module';

import './runner.library/runner.library.module';
import './runner.login/runner.login.module';
import './runner.maintenance/runner.maintenance.module';
import './runner.processmaps/runner.processmaps.module';
import './runner.processmapsv2/runner.processmapsv2.module';
import './runner.public-form/runner.public-form.module';
import './runner.redirect/runner.redirect.module';
import './runner.report/runner.report.module';
import './runner.reports/runner.reports.module';
import './runner.services/runner.services.module';
import './runner.setup/runner.setup.module';
import './runner.sso/runner.sso.module';
import './runner.user-condition/runner.user-condition.module';
import './runner.user.accept.invite/runner.user.accept.invite.module';
import './runner.user.notifications/runner.user.notifications.module';
import './runner.user.profile/runner.user.profile.module';
import './signup/signup.module';
import './runner.templates/runner.templates.module';
const runnerModule = angular.module('flowingly.runner', [
  // Angular
  'ngSanitize',
  'ngMessages',
  'ngCookies',

  // Vendor
  'ui.router',
  'angularSpinner',
  'ui.materialize',
  'fg',
  'fgFiles',
  'ngDialog',
  'angular-storage',
  'ng-currency', // https://github.com/aguirrel/ng-currency (actively worked on in GitHub over the last few years)
  'auth0.lock',
  'angular-jwt',
  'auth0.auth0',
  'ngIdle',
  'mentio',

  // Flowingly Core
  'flowingly.services',
  'flowingly.directives',
  'flowingly.filters',
  'flowingly.adapters',
  'flowingly.directives.sidemenu',
  'flowingly.directives.files',
  'flowingly.components',
  'flowingly.bpmn.modeler',
  'flowingly.modeler.validation',

  // Signup

  'flowingly.signup',
  'flowingly.runner.user.accept.invite',

  // Flowingly Runner
  'flowingly.runner.directives',
  'flowingly.runner.services',
  'flowingly.runner.login',
  'flowingly.runner.flows.active',
  'flowingly.runner.flows.imin',
  'flowingly.runner.flows.search',
  'flowingly.runner.user.notifications',
  'flowingly.runner.flows.todo',
  'flowingly.runner.flow.start',
  'flowingly.runner.flow',
  'flowingly.runner.user.profile',
  'flowingly.runner.reports',
  'flowingly.runner.library',
  'flowingly.runner.setup',
  'flowingly.runner.maintenance',
  'flowingly.runner.exago',
  'flowingly.runner.exago.single',
  'flowingly.runner.processmap',
  'flowingly.runner.processmapsv2',
  'flowingly.runner.user.condition',
  'flowingly.runner.sso',
  'flowingly.runner.redirect',
  'flowingly.runner.report',
  'flowingly.runner.import',
  'flowingly.runner.templates',
  'flowingly.runner.public-form'
]);

runnerModule.run([
  '$templateCache',
  function ($templateCache: angular.ITemplateCacheService) {
    $templateCache.put('Client/runner.html', require('./runner.html').default);

    $templateCache.put(
      'Client/runner.tenant/runner.tenant.dialog.tmpl.html',
      require('./runner.tenant/runner.tenant.dialog.tmpl.html').default
    );
  }
]);
export default runnerModule;
