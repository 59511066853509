'use strict';

angular
  .module('flowingly.runner.setup')
  .controller('setupDeleteDialogController', setupDeleteDialogController);

setupDeleteDialogController.$inject = [
  '$scope',
  'APP_CONFIG',
  '$q',
  'ngDialog',
  'setupDeleteApiService',
  'lodashService',
  'Enums'
];

// This controller is for the reusable delete dialog.
// The purpose of this dialog is
// * to warn users about the implications of their delete
// * make them choose replacements for the actors being deleted
// * confirm the delete by typing the word delete
//
// Once completed, control is returned to the caller to perform the delete.

function setupDeleteDialogController(
  $scope,
  APP_CONFIG,
  $q,
  ngDialog,
  setupDeleteApiService,
  lodashService,
  enums
) {
  var ctrl = this;

  ctrl.reassignActorRequired = false;
  ctrl.reassignManagerRequired = false;
  ctrl.reassignCustomDatabaseRequired = false;
  ctrl.confirmed = false;
  ctrl.allActorsChosen = false;
  ctrl.canDelete = false;
  ctrl.actors = []; // list of actors, selected by the user to return to the caller
  ctrl.warnings = []; // list of warnings to display to the user
  ctrl.users = []; // list of users the user can choose from
  ctrl.teams = []; // list of teams the user can choose from

  ctrl.itemToDelete = $scope.ngDialogData.itemToDelete; // the item the user wants to delete (user/team/category)
  ctrl.entityType = $scope.ngDialogData.entityType; // the type of the itemToDelete
  ctrl.entityName = ctrl.itemToDelete.name || ctrl.itemToDelete.fullName; // the name of the type of the itemToDelete

  ctrl.closeDialog = closeDialog;
  ctrl.onDeleteConfirmed = onDeleteConfirmed;
  ctrl.onActorChosen = onActorChosen;
  ctrl.submitDelete = submitDelete;

  init(ctrl.itemToDelete);

  ////////// PUBLIC METHODS

  function submitDelete() {
    // close the dialog, returning the actors chosen by the user (submit delete)
    if (ctrl.entityType === enums.deleteEntityTypes.userEntityType) {
      ngDialog.closeAll({
        actor: ctrl.actors.actor,
        manager: ctrl.actors.manager,
        customdatabaseactor: ctrl.actors.customdatabaseactor
      });
    } else {
      ngDialog.closeAll({ actor: ctrl.actors.actor });
    }
  }

  function closeDialog() {
    // cancel the delete
    ngDialog.closeAll(undefined);
  }

  function onDeleteConfirmed(confirmed) {
    // user has type the confirmation phrase (delete)
    var noReassignRequired =
      !ctrl.reassignActorRequired &&
      !ctrl.reassignManagerRequired &&
      !ctrl.reassignCustomDatabaseRequired;
    ctrl.confirmed = confirmed;
    ctrl.canDelete =
      ctrl.confirmed && (noReassignRequired === true || ctrl.allActorsChosen);
  }

  function onActorChosen(actors) {
    // user has chosen a new actor from dropdowns
    ctrl.actors = actors;

    if (ctrl.entityType === enums.deleteEntityTypes.userEntityType) {
      //USERS
      //if user is any flows, they need to be resassigned to a new actor (user/team)
      var hasSelectedActor = true;
      var hasSelectedManager = true;
      var hasSelectedCustomDatabaseActor = true;

      if (ctrl.reassignActorRequired) {
        hasSelectedActor = actors.actor !== undefined;
      }

      if (ctrl.reassignManagerRequired) {
        hasSelectedManager = actors.manager !== undefined;
      }

      if (ctrl.reassignCustomDatabaseRequired) {
        hasSelectedCustomDatabaseActor =
          actors.customdatabaseactor !== undefined;
      }

      ctrl.allActorsChosen =
        hasSelectedActor &&
        hasSelectedManager &&
        hasSelectedCustomDatabaseActor;
    } else {
      ctrl.allActorsChosen = actors.actor;
    }

    ctrl.canDelete = ctrl.confirmed && ctrl.allActorsChosen;
  }

  ///////// PRIVATE METHODS
  function init(itemToDelete) {
    ctrl.entityTypeName = setEntityTypeName(ctrl.entityType);
    ctrl.loading = true;

    const warnings = setupDeleteApiService
      .getWarnings(ctrl.entityType, itemToDelete)
      .then((warnings) => {
        if (ctrl.entityType === enums.deleteEntityTypes.categoryEntityType) {
          //warning is a simple string - if the string is not empty then reassignment required
          ctrl.warnings = warnings !== '' ? [warnings] : [];
          ctrl.reassignActorRequired = ctrl.warnings.length > 0;
        } else {
          //for all other types will be an object
          angular.copy(warnings.warnings, ctrl.warnings);
          ctrl.reassignActorRequired = warnings.reassignActorRequired;
          ctrl.reassignManagerRequired = warnings.reassignManagerRequired;
          ctrl.reassignCustomDatabaseRequired =
            warnings.reassignCustomDatabaseRequired;
        }
      });

    if (ctrl.entityType !== enums.deleteEntityTypes.categoryEntityType) {
      var teams = setupDeleteApiService.getTeams().then((teams) => {
        teams = filterOutItemToDelete(teams);
        if (teams.length !== 0) {
          teams = sortAlhpaBetically(
            teams,
            enums.deleteEntityTypes.teamEntityType
          );
        }
        ctrl.teams = angular.copy(teams);
      });

      // wait for all promises to resolve
      $q.all([warnings, teams]).then(() => {
        ctrl.loading = false;
      });
    } else {
      var categories = setupDeleteApiService.getCategories().then((cats) => {
        cats = filterOutItemToDelete(cats);
        cats = sortAlhpaBetically(
          cats,
          enums.deleteEntityTypes.categoryEntityType
        );
        ctrl.categories = angular.copy(cats);
      });

      $q.all([warnings, categories]).then(() => {
        ctrl.loading = false;
      });
    }
  }

  function filterOutItemToDelete(actors) {
    return actors.filter((d) => {
      return d.id !== ctrl.itemToDelete.id;
    });
  }

  function sortAlhpaBetically(actors, entityType) {
    if (entityType === enums.deleteEntityTypes.userEntityType) {
      return lodashService.sortBy(actors, 'fullName');
    } else {
      return lodashService.sortBy(actors, 'name');
    }
  }

  function setEntityTypeName(type) {
    switch (type) {
      case enums.deleteEntityTypes.userEntityType:
        return 'User';

      case enums.deleteEntityTypes.teamEntityType:
        return 'Team';

      case enums.deleteEntityTypes.categoryEntityType:
        return 'Category';

      case enums.deleteEntityTypes.flowModelEntityType:
        return 'Flow Model';

      case enums.deleteEntityTypes.databaseEntityType:
        return 'Database';

      case enums.deleteEntityTypes.roleEntityType:
        return 'Role';
    }
  }
}
