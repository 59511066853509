/**
 * @ngdoc module
 * @name flowingly.runner.directives
 * @module flowingly.runner.directives
 * @description Directives that are only used by the Runner
 */
import angular from 'angular';

const directivesModule = angular.module('flowingly.runner.directives', []);
directivesModule.run([
  '$templateCache',
  function ($templateCache: angular.ITemplateCacheService) {
    $templateCache.put(
      'confirmDialog.tmpl.html',
      require('./common/confirmDialog.tmpl.html').default
    );

    $templateCache.put(
      'Client/runner.directives/flow/flow.instruction.history.html',
      require('./flow/flow.instruction.history.html').default
    );

    $templateCache.put(
      'Client/runner.directives/flow/flow.timeline.file.tmpl.html',
      require('./flow/flow.timeline.file.tmpl.html').default
    );

    $templateCache.put(
      'Client/runner.directives/flow/runner.edit-flow-model.tmpl.html',
      require('./flow/runner.edit-flow-model.tmpl.html').default
    );

    $templateCache.put(
      'Client/runner.directives/flow/runner.info-flow-model.tmpl.html',
      require('./flow/runner.info-flow-model.tmpl.html').default
    );

    $templateCache.put(
      'Client/runner.directives/input/bulk-actor-selector/bulk.select.actors.tmpl.html',
      require('./input/bulk-actor-selector/bulk.select.actors.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.directives/input/bulk-actor-selector/dialogue/bulk.select.actor.dialog.tmpl.html',
      require('./input/bulk-actor-selector/dialogue/bulk.select.actor.dialog.tmpl.html')
        .default
    );
  }
]);
