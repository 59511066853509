/**
 * @ngdoc directive
 * @name teamsListActions
 * @module flowingly.runner.setup
 * @description This component is used to display actions button list in a teams row
 * @usage
 * ```
     <teams-list-actions team='dataItem' on-teams-updated='$ctrl.handleTeamsUpdated()'></teams-list-actions>
 * ``` 
 * ### Notes
 * See Also: 
 * ### Properties
 * #### Inputs
 * team: team object to act upon
 * onTeamsUpdated: event listener when team is updated
 */
(function () {
  'use strict';

  angular.module('flowingly.runner.setup').component('teamsListActions', {
    bindings: {
      team: '<',
      onTeamsUpdated: '&'
    },
    templateUrl:
      'Client/runner.setup/runner.setup.teams/runner.setup.teams.list.actions.tmpl.html',
    controller: [
      '$scope',
      'dialogService',
      'notificationService',
      'teamApiService',
      function ($scope, dialogService, notificationService, teamApiService) {
        let $ctrl = this;

        const team = 2;

        $ctrl.editClick = editClick;
        $ctrl.deleteClick = deleteClick;

        function editClick() {
          dialogService
            .showDialog({
              template:
                'Client/runner.setup/runner.setup.teams/runner.setup.teams.dialog.tmpl.html',
              controller: 'teamDialogController',
              appendClassName: 'ngdialog-normal',
              scope: $scope,
              data: { team: $ctrl.team }
            })
            .then(function (successSaved) {
              if (dialogService.isCloseModalWithCancelAction(successSaved)) {
                //user closed modal by clicking on overlay (or cancel or press Esc key)
                return;
              }

              if (successSaved === true) {
                //raise an "event" to notify the parent
                $ctrl.onTeamsUpdated();
              }
            });
        }

        function deleteClick() {
          dialogService
            .showDialog({
              template:
                'Client/runner.setup/runner.setup.delete/runner.setup.delete.dialog.tmpl.html',
              controller: 'setupDeleteDialogController',
              appendClassName: 'ngdialog-normal',
              scope: $scope,
              data: {
                itemToDelete: $ctrl.team,
                entityType: team
              }
            })
            .then(function (response) {
              if (dialogService.isCloseModalWithCancelAction(response)) {
                //user closed modal by clicking on overlay (or cancel or press Esc key)
                return;
              }

              teamApiService
                .deleteTeam($ctrl.team, response.actor)
                .then(function () {
                  notificationService.showSuccessToast(
                    `${$ctrl.team.name} successfully Deleted.`,
                    1000
                  );
                  //raise an 'event' to notify the parent
                  $ctrl.onTeamsUpdated();
                });
            });
        }
      }
    ]
  });
})();
