/**
 * @ngdoc directive
 * @name flowsIminGroups
 * @module flowingly.runner.flows.imin
 * @description  This component is used to display the groups of flows that the user is in
 *               This is the component you put on the main page to display the group list.
 * @usage
 * ```
     <flows-imin-groups groups="ctrl.categories"></flows-imin-groups>
 * ``` 
 * ### Notes
 *  Module structure:
 *    route (app.runner.flowsin) --> runner.flows.imin.tmpl
 *      flowsIminGroups            // Handles the list of groups
 *        flowsIminGroup                 // Handles each group (which contains a group.name and the group.flows)
 *          flowsIminGroupHeader          // Handles the group header for a group of flows
 *          flowsIminGroupFlows            // Handles the flow list for a group
 *              --> runner.flows.imin.group.flows.tmpl.html //uses the flow-list directive to present all the flows in the group
 * 
 * ### Properties
 * #### Inputs
 * * groups:  list of groups where each group contains the list of flows
 * * isMobile: show mobile view if set
 */

'use strict';
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular from 'angular';

angular.module('flowingly.runner.flows.imin').component('flowsIminGroups', {
  bindings: {
    groups: '<', // list of flows, grouped
    isMobile: '<' // show mobile view if set
  },
  controller: [
    'pubsubService',
    'avatarService',
    'flowinglyDiagramService',
    function (
      pubsubService: SharedAngular.PubSubService,
      avatarService: SharedAngular.AvatarService,
      flowinglyDiagramService: SharedAngular.FlowinglyDiagramService
    ) {
      const $ctrl = this;

      this.$onInit = function () {
        pubsubService.subscribe(
          'CLIENT_USER_PROFILE_UPDATED',
          onClientUserProfileUpdatedReceived,
          'runner.flows.imin.groups.component'
        );

        function onClientUserProfileUpdatedReceived(event, data) {
          if (data.id === undefined) {
            return;
          }

          $ctrl.groups.forEach((group) => {
            group.Flows.forEach((flow) => {
              if (flow.expanded) {
                flowinglyDiagramService.generateProcessModel({
                  flow: flow,
                  applyAvatar: false
                });
              }

              if (flow.Steps) {
                flow.Steps.forEach((step) => {
                  if (
                    step.WhoCompletedStep === 'You' ||
                    (step.IsWaitingForYou === 1 &&
                      step.WhoIsAssignedStep === data.fullName)
                  ) {
                    step.ActorAssignedAvatarUrl = avatarService.getAvatarUrl(
                      data.id
                    );
                  } else {
                    step.ActorAssignedAvatarUrl = avatarService.getAvatarUrl(
                      step.ActorAssignedId
                    );
                  }
                  step.CompletedByAvatarUrl = avatarService.getAvatarUrl(
                    step.CompletedById
                  );
                });
              }

              if (flow.IsStartedByYou === 1) {
                flow.StartedByUserAvatarUrl = avatarService.getAvatarUrl(
                  data.id
                );
              }
            });
          });
        }
      };
    }
  ],
  templateUrl: 'Client/runner.flows.imin/runner.flows.imin.groups.tmpl.html'
});
