/**
 * @ngdoc type
 * @module flowingly.signup
 * @name signupController
 *
 * @description
 */
'use strict';
import * as angular from 'angular';
import SignupService, { BusinessDetail, SettingDetail } from './signup.service';

export interface BusinessSignup {
  userEmail: string;
  userFirstName: string;
  userLastName: string;
  phoneNumber: string;
  parentId: string;
  password: string;
  name: string;
  templateId: string;
  industry: string;
  userTeam: string;
  logoId?: string;
  settings: SettingDetail[];
}

class SignupController {
  static $inject = [
    'signupService',
    '$state',
    'zxcvbnAdapter',
    'sessionService',
    'tokenService',
    'authService',
    'validationService',
    'fileService',
    'permissionsService',
    'flowinglyConstants'
  ];
  public emailValidationPattern;
  public businessNameValidationPattern = /a-z/i;
  public errorOnSubmit = false;
  public errorMessagesOnSubmit = '';
  public signup = {} as BusinessSignup;
  public templates = [];
  public businesses: BusinessDetail[];
  public featureSettings: SettingDetail[];
  public brandingSettings: SettingDetail[];
  public teams;
  public industries;
  public signupForm;
  public passwordCalculator;
  public loading = true;
  public logoUploaded = (fileId: string) => {
    if (this.signup.logoId) {
      this.fileService.removeFileForId(this.signup.logoId);
    }
    this.signup.logoId = fileId;
  };

  private templateGroups = {};

  constructor(
    private signupService: SignupService,
    private $state,
    private zxcvbn,
    private sessionService,
    private tokenService,
    private authService,
    private validationService,
    private fileService,
    private permissionsService,
    private flowinglyConstants
  ) {
    if (
      !this.permissionsService.currentUserHasPermission(
        this.flowinglyConstants.permissions.BUSINESS_SIGNUP
      )
    ) {
      $state.go('app.runner.flowsactive');
      return;
    }

    this.emailValidationPattern =
      this.validationService.getEmailValidationPattern();
    this.teams = signupService.defaultTeams;
    this.industries = signupService.industries;

    const templatePromise = this.getTemplateBusinesses();
    const businessPromise = this.getParentBusinesses();

    const loadingPromises = [templatePromise, businessPromise];
    Promise.all(loadingPromises).then(() => (this.loading = false));
  }

  public signupBusiness() {
    if (this.signupForm.$valid) {
      this.errorOnSubmit = false;
      this.errorMessagesOnSubmit = '';
      this.submitNewBusiness();
    }
  }

  public passwordStrengthCalculator() {
    const passScore = {
      0: { description: 'Very Weak', color: 'red', percent: '20%' },
      1: { description: 'Weak', color: 'red', percent: '40%' },
      2: { description: 'Fair', color: 'yellow', percent: '60%' },
      3: { description: 'Good', color: 'green', percent: '80%' },
      4: { description: 'Strong', color: 'green', percent: '100%' }
    };

    const pwd = this.signup.password || '';
    const result = this.zxcvbn(pwd);
    const passResult = passScore[result.score];

    this.passwordCalculator = {
      show: true,
      color: passResult.color,
      strength: passResult.percent,
      strengthText: passResult.description,
      suggestions: result.feedback.suggestions
    };
  }

  private submitNewBusiness() {
    this.loading = true;
    this.featureSettings.forEach((setting) => (setting.value = 'true'));
    this.signup.settings = this.featureSettings
      .filter((setting) => setting.copy)
      .concat(this.brandingSettings.filter((setting) => setting.copy));

    this.signupService.signupBusiness(this.signup).then(
      async (result) => {
        await this.saveUserAndToken(result);
        this.loading = false;
        this.sessionService.setuserAuthenticated();
        $('.changed-input').removeClass('changed-input');
        this.$state.go('app.login');
      },
      (response) => {
        this.signupForm.$invalid = true;
        this.errorOnSubmit = true;
        this.errorMessagesOnSubmit = response.data?.message;
        this.loading = false;
      }
    );
  }

  public loadTemplateTeams() {
    const groups = this.templateGroups[this.signup.templateId];
    this.teams = groups || this.signupService.defaultTeams;
  }

  private async saveUserAndToken(result) {
    await this.authService.setUserAfterSuccessfulLogin(result.data.user);
    this.tokenService.setToken(result.data.token);
  }

  private getTemplateBusinesses(): Promise<any> {
    return this.signupService.getTemplateBusinesses().then((templates) => {
      this.templateGroups = templates.reduce((result, current) => {
        result[current.id] = current.groups;
        return result;
      }, {});
      this.templates = templates.map((template) => {
        return {
          name: template.name,
          id: template.id
        };
      });
    });
  }

  private getParentBusinesses(): Promise<any> {
    return this.signupService.getExistingBusinesses().then((businesses) => {
      this.signup.parentId = businesses.find((b) => b.name === 'Flowingly').id;
      this.businesses = businesses;
      this.getParentSettings(this.signup.parentId);
    });
  }

  public getParentSettings(parentId: string) {
    this.signupService.getSignupSettings(parentId).then((settings) => {
      this.featureSettings = settings
        .filter((setting) => setting.tags.some((tag) => tag === 'feature'))
        .sort((a, b) => a.name.localeCompare(b.name));
      this.brandingSettings = settings
        .filter((setting) => setting.tags.some((tag) => tag === 'branding'))
        .sort((a, b) => a.name.localeCompare(b.name));
      this.featureSettings.forEach((setting) => (setting.copy = true));
      this.brandingSettings.forEach((setting) => (setting.copy = true));
    });
  }
}

angular
  .module('flowingly.signup')
  .controller('signupController', SignupController);
