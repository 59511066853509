/**
 * @ngdoc service
 * @name reportsApiService
 * @module flowingly.runner.services
 *
 * @description A service responsible solely for fetching report data
 *
 * ## Notes
 * No Caching or formatting to be performed here.
 * i.e. we return response.data.DataModel so the consumer can work with the data directly
 *
 */
import * as angular from 'angular';
import { IPageResult } from '../interfaces/flowingly.pagination';
import {
  ISearchOptions,
  IReportFlowData
} from '../interfaces/flowingly.report';

export default class ReportsApiService {
  static $inject: string[] = ['$http', 'APP_CONFIG', 'sessionService'];
  constructor(
    private $http: angular.IHttpService,
    private APP_CONFIG: AppConfig
  ) {}

  //getFlowDataItemsPage
  public fetchReportByFlowModelId(
    flowModelId,
    filters: ISearchOptions = {}
  ): angular.IPromise<IPageResult<IReportFlowData>> {
    const url =
      this.APP_CONFIG.apiBaseUrl + 'reports/flowDataItems/' + flowModelId;
    return this.$http
      .post<IPageResult<IReportFlowData>>(url, { ...filters })
      .then((response) => {
        return response.data;
      });
  }
}

angular
  .module('flowingly.runner.services')
  .service('reportsApiService', ReportsApiService);

export type ReportsApiServiceType = InstanceType<typeof ReportsApiService>;
