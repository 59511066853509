/**
 * @ngdoc service
 * @name runnerPublicFormApiService
 * @module flowingly.runner.services
 *
 * @description A helper API service for public form
 *
 * ## Notes
 *
 * ###API
 * * getFormById - get the form data by form guid
 * * submitForm - submit the form and then will complete the first step under the hood
 *
 */

import angular from 'angular';
import { IFormElement } from './runner.public-form.service';

export interface IApiPublicFormModel {
  publicFormId: string;
  stepName: string;
  modelerNodeId: string;
  cardTasks: string;
  fields: IFieldForUser[];
  backgroundColor: string;
  selectedFieldForSubject: string;
  userId: string;
  businessId: string;
  schema: ICardSchema;
}

export interface ICardSchema {
  fields: IFormElement[];
}

export interface IFieldForUser {
  stepId: string;
  name: string;
  displayName: string;
  type: number;
  label: string;
  placeHolder: string;
  toolTip: string;
  noLabel: string;
  options: string;
  validation: string;
  value: string;
  text: string;
  instruction: string;
  TableHasRows: number;
  alignment: string;
  notifyFields: INotifyField;
  parentStepId: string;
  parentModelNodeId: string;
  lookupValues: ILookupValue[];
  attachDocumentElements: IAttachDocumentElement[];
  customValidationFormFieldId: string;
  customValidationPreviousValue: string;
  fieldConditionStatus: any;
}

export interface INotifyField {
  fieldname: string;
  tableColumnId: string;
  dbDataSource: IDbDataSource;
}

export interface ILookupValue {
  columnId: number;
  value: string;
}

export interface IAttachDocumentElement {
  fileName: string;
  fieldId: string;
  size: number;
  uploadedDate: string;
}

export interface IDbDataSource {
  dbName: string;
  displayValue: string;
  filters: IFilter[];
  displayValueOptions: IDisplayValueOption[];
}

export interface IFilter {
  column: string;
  operation: string;
  value: string;
}

export interface IDisplayValueOption {
  name: string;
  dataType: string;
}

export class RunnerPublicFormApiService {
  constructor(
    private $http: angular.IHttpService,
    private APP_CONFIG: AppConfig
  ) {}

  getFormById(flowModelId: string, inIframe: boolean, noSpinner: boolean) {
    return this.$http
      .get<IResponseData<IApiPublicFormModel>>(
        `${this.APP_CONFIG.apiBaseUrl}form/${flowModelId}/${inIframe}`,
        {
          noSpinner: noSpinner || true
        }
      )
      .then((response) => {
        return response.data;
      });
  }

  submitForm(flowModelId: string, formData, noSpinner: boolean) {
    return this.$http.post(
      `${this.APP_CONFIG.apiBaseUrl}form/${flowModelId}`,
      formData,
      { noSpinner: noSpinner || false }
    );
  }

  uploadAnonymousFile(
    formData: HTMLFormElement,
    flowModelId: string,
    stepId: string,
    fieldKey: string
  ) {
    return this.$http.post(
      `${this.APP_CONFIG.apiBaseUrl}form/${flowModelId}/step/${stepId}/field/${fieldKey}/file`,
      formData,
      {
        transformRequest: angular.identity,
        timeout: 90000,
        headers: { 'Content-Type': undefined }
      }
    );
  }

  deleteAnonymousFile(fileId: string, stepId: string) {
    return this.$http.delete(
      `${this.APP_CONFIG.apiBaseUrl}form/step/${stepId}/file/${fileId}`
    );
  }
}

angular
  .module('flowingly.runner.services')
  .factory('runnerPublicFormApiService', [
    '$http',
    'APP_CONFIG',
    ($http: angular.IHttpService, APP_CONFIG: AppConfig) =>
      new RunnerPublicFormApiService($http, APP_CONFIG)
  ]);

export type RunnerPublicFormApiServiceType = InstanceType<
  typeof RunnerPublicFormApiService
>;
