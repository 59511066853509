import React from 'react';
import { Box, Typography } from '@mui/material';

type Props = {
  processOwner: string;
  processDescription: string;
};

const Header = (props: Props) => {
  const { processOwner, processDescription } = props;
  const typographyWithEmptyString = (text: string, emptyString: string) => {
    if (text) {
      return (
        <Typography
          className="process-map-v2-view-left-panel-header-non-empty-string"
          fontSize={15}
          fontWeight={600}
        >
          {text}
        </Typography>
      );
    } else {
      return (
        <Typography
          className="process-map-v2-view-left-panel-header-empty-string"
          fontStyle="italic"
          fontSize={15}
          fontWeight={600}
        >
          {emptyString}
        </Typography>
      );
    }
  };

  return (
    <Box className={'process-map-v2-view-left-panel-header'}>
      <Box className={'process-map-v2-view-left-panel-header-content'}>
        <Box className={'process-map-v2-view-left-panel-header-process-owner'}>
          Process Owner
        </Box>
        <Box
          className={
            'process-map-v2-view-left-panel-header-process-description'
          }
        >
          Process Description
        </Box>
      </Box>

      <Box className={'process-map-v2-view-left-panel-header-details-content'}>
        <Box className={'process-map-v2-view-left-panel-header-process-owner'}>
          {typographyWithEmptyString(
            processOwner,
            'Process Owner not yet defined'
          )}
        </Box>
        <Box
          className={
            'process-map-v2-view-left-panel-header-process-description'
          }
        >
          <Typography
            fontFamily={'Open Sans, sans-serif'}
            fontSize={15}
            fontWeight={600}
            className={
              'process-map-v2-view-left-panel-header-description-typography'
            }
          >
            {processDescription}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
