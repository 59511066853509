/**
 * @ngdoc type
 * @name nudgeDialogController
 * @module flowingly.runner.flow
 * @description This controller is used to control send nudge modal dialog
 */

'use strict';
import angular from 'angular';

angular
  .module('flowingly.runner.flow')
  .controller('nudgeDialogController', nudgeDialogController);

nudgeDialogController.$inject = ['$scope', 'flowApiService'];

function nudgeDialogController(
  $scope: IScopeNgDialog,
  flowApiService: FlowApiService
) {
  const $ctrl = this;

  $ctrl.whoIsNudged = $scope.ngDialogData.whoIsNudged;
  $ctrl.stepId = $scope.ngDialogData.stepId;
  $ctrl.stepName = $scope.ngDialogData.stepName;
  $ctrl.nudgedById = $scope.ngDialogData.nudgedById;
  $ctrl.message = '';

  $ctrl.sendNudge = () => {
    const stepNudgeHistory = {
      stepId: $ctrl.stepId,
      nudgedById: $ctrl.nudgedById,
      nudgedByUserName: $scope.ngDialogData.nudgedByUserName,
      message: $ctrl.message,
      flowSubject: $scope.ngDialogData.flowSubject,
      actorNameNudged: $ctrl.whoIsNudged
    };

    flowApiService.nudgeFlowWaitingOnActor(stepNudgeHistory).then(() => {
      $scope.closeThisDialog(stepNudgeHistory);
    });
  };

  $ctrl.cancelNudge = () => {
    $scope.closeThisDialog();
  };
}
