/**
 * @ngdoc type
 * @module flowingly.runner.user.accept.invite
 * @name acceptInviteController
 *
 * @description Controller that allows users to complete their profiles when accepting an invitation
 */

'use strict';
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular from 'angular';

angular
  .module('flowingly.runner.user.accept.invite')
  .controller('acceptInviteController', acceptInviteController);

acceptInviteController.$inject = [
  '$location',
  'acceptInviteApiService',
  'sessionService',
  'tokenService',
  'authService',
  '$window'
];

function acceptInviteController(
  $location: angular.ILocationService,
  acceptInviteApiService: AcceptInviteApiService,
  sessionService: SharedAngular.SessionService,
  tokenService: SharedAngular.TokenService,
  authService: AuthService,
  $window
) {
  const ctrl = this;
  ctrl.model = {
    isLoaded: false
  };

  ctrl.passwordStrengthCalculator = passwordStrengthCalculator;
  ctrl.completeUserProfile = completeUserProfile;

  init();

  function init() {
    ctrl.model = {
      isValidToken: false,
      user: {},
      inviteToken: $location.search().inviteToken
        ? $location.search().inviteToken
        : undefined,
      errorMessage:
        'Looks like your invitation has expired. Please request a new invitation link from your team.'
    };

    tokenService.setToken(ctrl.model.inviteToken);

    // Public endpoint that will validate the JWT and if successful will use the token to retrieve
    // other information required by the component
    acceptInviteApiService.validateSecurityToken(ctrl.model.inviteToken).then(
      function (response: {
        user: unknown;
        completedProfile: unknown;
        redirectLink: string;
      }) {
        ctrl.model.isValidToken = true;

        ctrl.model.user = response.user;

        //Redirect user to login page from old email link
        if (response.completedProfile) {
          if (response.redirectLink) {
            $window.location.href = response.redirectLink;
          } else {
            $window.location.href = '/flowsactive';
          }
        } else {
          ctrl.model.isLoaded = true;
        }
      },
      //Error, something went wrong here. We need to display an error message
      function (error) {
        if (error.data.message) ctrl.model.errorMessage = error.data.message;

        ctrl.model.isLoaded = true;
      }
    );
  }

  function completeUserProfile() {
    const userToSave = {
      id: ctrl.model.user.id, //only used in angular service
      userManagerId: ctrl.model.user.manager.id,
      teamId: ctrl.model.user.teamId,
      password: ctrl.model.user.password
    };

    //Update user profile information
    acceptInviteApiService
      .updateUserProfile(ctrl.model.inviteToken, userToSave)
      .then(function (response: {
        success: unknown;
        userPermissions: unknown;
        redirectLink: string;
      }) {
        //saved successfully
        if (response.success) {
          tokenService.setToken(ctrl.model.inviteToken);
          sessionService.setUser(ctrl.model.user);

          const profile = JSON.stringify({
            email: ctrl.model.user.email,
            name: ctrl.model.user.email,
            permissions: response.userPermissions,
            nameidentifier: ctrl.model.user.id,
            businessidentifier: ctrl.model.user.businessId
          });

          sessionService.setProfile(profile);

          authService.getUserPendingAcceptCondition(function () {
            if (response.redirectLink) {
              $window.location.href = response.redirectLink;
            } else {
              $window.location.href = '/flowsactive';
            }
          });
        }
      });
  }

  //TODO - split this into reusable component
  function passwordStrengthCalculator() {
    const passScore = {
      0: { description: 'Very Weak', color: 'red', percent: '20%' },
      1: { description: 'Weak', color: 'red', percent: '40%' },
      2: { description: 'Fair', color: 'yellow', percent: '60%' },
      3: { description: 'Good', color: 'green', percent: '80%' },
      4: { description: 'Strong', color: 'green', percent: '100%' }
    };

    const pwd = ctrl.model.user.password || '';
    const result = zxcvbn(pwd);
    const passResult = passScore[result.score];

    ctrl.passwordCalculator = {
      show: true,
      color: passResult.color,
      strength: passResult.percent,
      strengthText: passResult.description,
      suggestions: result.feedback.suggestions
    };
  }
}
