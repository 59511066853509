import React from 'react';
import { Tabs } from '@mui/material';
import CustomTabHeader from './CustomTabHeader';

const CustomTabs = (props) => {
  const { value, tabLabels, onChange } = props;

  return (
    <Tabs
      value={value}
      onChange={onChange}
      variant="fullWidth"
      className="v2-custom-tabs"
    >
      {tabLabels.map((label, index) => (
        <CustomTabHeader key={index} label={label} />
      ))}
    </Tabs>
  );
};

export default CustomTabs;
