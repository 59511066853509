import React from 'react';
import FlowModelSummaryComponent from './FlowModelSummary/FlowModelSummary';
import ProcessMap from '@Client/runner.processmapsv2/@types/processMap';

type Props = {
  isMobile: boolean;
  processMaps: ProcessMap[];
  onFlowModelClick(id: string): void;
};

const ProcessMapList = (props: Props) => {
  const { processMaps, isMobile } = props;

  return (
    <div className={`start-category-list ${isMobile ? 'isMobile' : ''}`}>
      <div className={`flow-block ${isMobile ? 'isMobile' : ''}`}>
        <div className={`flow-list ${isMobile ? 'isMobile' : ''}`}>
          <ul>
            {processMaps.map((processMap) => (
              <li key={processMap.id}>
                <FlowModelSummaryComponent
                  flowModelSummary={processMap}
                  isMobile={isMobile}
                  onFlowModelClick={(id) => props.onFlowModelClick(id)}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
export default ProcessMapList;
