(function () {
  'use strict';

  ///
  /// This state displays user profile
  ///

  // Register the application's routes configuration.
  angular.module('flowingly.runner.user.profile').config(config);

  // Inject the dependencies.
  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider.state('app.runner.profile', {
      url: 'profile',
      controller: [
        'accountApiService',
        'lodashService',
        'pubsubService',
        function (accountApiService, lodashService, pubsubService) {
          var ctrl = this;
          ctrl.user = tranform(accountApiService.userProfile);

          pubsubService.subscribe(
            'SIGNALR_USER_PROFILE_UPDATED',
            updateUserProfile
          );

          function tranform(json) {
            return lodashService.mapKeys(json, function (v, k) {
              return lodashService.camelCase(k);
            });
          }

          function updateUserProfile() {
            accountApiService.getUserProfile().then(function (response) {
              ctrl.user = tranform(angular.extend(ctrl.user, response));
            });
          }
        }
      ],
      controllerAs: 'ctrl',
      resolve: {
        userProfile: [
          'accountApiService',
          function (accountApiService) {
            return accountApiService.getUserProfile();
          }
        ]
      },
      template: '<user-profile user="ctrl.user"></user-profile>'
    });
  }
})();
