(function () {
  'use strict';

  angular
    .module('flowingly.runner.setup')
    .controller(
      'runnerSetupCreateDatabaseController',
      runnerSetupCreateDatabaseController
    );

  runnerSetupCreateDatabaseController.$inject = [
    '$http',
    '$scope',
    '$state',
    '$timeout',
    'APP_CONFIG',
    'notificationService',
    'databaseApiService',
    'validationService',
    'flowinglyConstants',
    'lodashService'
  ];

  function runnerSetupCreateDatabaseController(
    $http,
    $scope,
    $state,
    $timeout,
    APP_CONFIG,
    notificationService,
    databaseApiService,
    validationService,
    flowinglyConstants,
    lodashService
  ) {
    var $ctrl = this;

    $ctrl.nameIsInvalid = false;
    $ctrl.isMobile = $scope.isMobile;
    $ctrl.data = {
      name: '',
      isUserExtension: false,
      columns: []
    };
    $timeout(() => {
      let grid = angular.element('#newDatabaseGrid').data('kendoGrid');
      if (grid) {
        grid.addRow();
      }
    }, 200);

    $http
      .get(`${APP_CONFIG.apiBaseUrl}customdatabase/schema/datatypes`)
      .then((result) => {
        // we map adapt this for now since the frontend does not
        // yet use the backend structure
        $ctrl.dataTypeOptions = result.data.map((dataType) => ({
          value: dataType.type,
          text: dataType.type
        }));

        $ctrl.options = {
          dataSource: {
            data: $ctrl.data.columns,
            pageSize: APP_CONFIG.gridReportPageSize,
            schema: {
              model: {
                fields: {
                  name: { validation: { required: true } },
                  dataType: { defaultValue: { value: 'text', text: 'text' } }
                }
              }
            }
          },
          scrollable: true,
          pageable: true,
          sortable: true,
          resizable: true,
          columns: getColumns(),
          toolbar: [{ name: 'create', text: 'Add new column' }],
          editable: {
            createAt: 'bottom'
          }
        };
      });

    $ctrl.onSaveClick = () => {
      const { isEmpty, hasSpace } = validationService;

      $ctrl.errorMsg = '';
      $ctrl.nameIsInvalid = false;

      const name = $ctrl.data.name;
      if (isEmpty(name)) {
        $ctrl.errorMsg = 'Database name is required';
        $ctrl.nameIsInvalid = true;
        return;
      } else if (hasSpace(name)) {
        $ctrl.errorMsg = 'Database name cannot have space';
        $ctrl.nameIsInvalid = true;
        return;
      } else if (/[^a-zA-Z0-9|_]/.test($ctrl.data.name)) {
        $ctrl.errorMsg = 'Database name cannot have special characters';
        $ctrl.nameIsInvalid = true;
        return;
      }

      let grid = angular.element('#newDatabaseGrid').data('kendoGrid');
      let options = grid.getOptions();

      // transform to api ready payload
      $ctrl.data.columns = [];
      let hasInvalidColumns = false;

      options.dataSource.data.forEach((r) => {
        if (isEmpty(r.name)) {
          $ctrl.errorMsg = 'Column Name cannot be empty';
          $ctrl.nameIsInvalid = true;
          hasInvalidColumns = true;
          return;
        } else if (/[^a-zA-Z0-9 ]/.test(r.name)) {
          $ctrl.errorMsg = 'Column Name cannot have special characters';
          $ctrl.nameIsInvalid = true;
          hasInvalidColumns = true;
          return;
        }

        $ctrl.data.columns.push({
          name: r.name,
          dataType: r.dataType.value
        });
      });

      if (hasInvalidColumns) {
        return;
      }

      if ($ctrl.data.columns.length === 0) {
        $ctrl.errorMsg = 'The Database cannot be saved without columns';
        $ctrl.nameIsInvalid = true;
        return;
      }

      databaseApiService.saveDatabase($ctrl.data).then(() => {
        notificationService.showSuccessToast('Database saved');
        $state.go('app.runner.edit-database', { dbName: $ctrl.data.name });
      }); // no catch required, $http already shows notifications on failed requests
    };

    /// PRIVATE METHODS //////////////////////////////////
    function getColumns() {
      let dataTypeEditor = function (container) {
        angular
          .element(
            `<input
                    kendo-drop-down-list
                    required
                    k-data-text-field="'text'"
                    k-data-value-field="'value'"
                    k-data-source="$ctrl.dataTypeOptions"
                    data-bind="value:dataType"
                    />`
          )
          .appendTo(container);
      };

      //Note: field needs to match the name (and case) of the returned data
      return [
        {
          field: 'dataType',
          title: 'Data Type',
          editor: dataTypeEditor,
          template: '#=dataType.text#'
        },
        {
          field: 'name',
          title: 'Column Name'
        },
        {
          command: 'destroy',
          title: 'Actions',
          width: '150px'
        }
      ];
    }
  }
})();
