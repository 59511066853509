/**
 * @ngdoc service
 * @name databaseApiService
 * @module flowingly.runner.services
 *
 * @description A service responsible solely for fetching databases data
 *
 * ## Notes
 * No Caching or formatting to be performed here.
 *
 */

'use strict';
import angular from 'angular';

/// i.e. we return response.data.DataModel so the consumer can work with the data directly
angular
  .module('flowingly.runner.services')
  .factory('databaseApiService', databaseApiService);

databaseApiService.$inject = ['$http', 'APP_CONFIG'];

function databaseApiService(
  $http: angular.IHttpService,
  APP_CONFIG: AppConfig
) {
  const service = {
    databases: [],
    getDatabases: getDatabases,
    saveDatabase: saveDatabase,
    saveRecords: saveRecords,
    deleteDatabase: deleteDatabase,
    isDatabaseInUse: isDatabaseInUse,
    deleteRecord: deleteRecord,
    getRecords: getRecords,
    getColumns: getColumns
  };

  const databasesApiBaseUrl = APP_CONFIG.apiBaseUrl + 'customdatabase';

  return service;

  //////////// Public API Methods

  function deleteDatabase(item) {
    return $http({
      url: `${databasesApiBaseUrl}/${item}`,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      }
    });
  }

  function deleteRecord(tableName, itemId) {
    return $http({
      url: `${databasesApiBaseUrl}/${tableName}/${itemId}`,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      }
    });
  }

  function isDatabaseInUse(tableName) {
    return $http({
      url: `${databasesApiBaseUrl}/${tableName}/involved`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      }
    });
  }

  function saveDatabase(data) {
    return $http.post(databasesApiBaseUrl, data);
  }

  function saveRecords(dbName, dataString) {
    dataString = dataString.replace(/'/g, "\\'");
    dataString = dataString.replace(/\\"/g, '\\\\\\"');
    return $http.post(
      `${databasesApiBaseUrl}/${dbName}`,
      "'" + dataString + "'"
    );
  }

  function getDatabases(noSpinner) {
    return $http
      .get(databasesApiBaseUrl, { noSpinner: noSpinner || false })
      .then(function (response) {
        angular.copy(response.data, service.databases);
        sortDatabasesByName();
        return response.data;
      });
  }

  function getRecords(tableName, noSpinner) {
    return $http
      .get(`${databasesApiBaseUrl}/${tableName}`, {
        noSpinner: noSpinner || false
      })
      .then(function (response) {
        return response.data;
      });
  }

  function getColumns(tableName, noSpinner) {
    return $http
      .get(`${databasesApiBaseUrl}/columns/${tableName}`, {
        noSpinner: noSpinner || false
      })
      .then(function (response) {
        return response.data;
      });
  }

  // PRIVATE METHODS /////////////////////////////////////////////////////////////////
  function sortDatabasesByName() {
    service.databases.sort((t1, t2) => {
      const t1NameToCompare = t1.name.trim().toLowerCase();
      const t2NameToCompare = t2.name.trim().toLowerCase();

      if (t1NameToCompare < t2NameToCompare) {
        return -1;
      }

      if (t1NameToCompare > t2NameToCompare) {
        return 1;
      }

      return t1NameToCompare > t2NameToCompare;
    });
  }
}

export type DatabaseApiServiceType = ReturnType<typeof databaseApiService>;
