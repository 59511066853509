/**
 * @ngdoc directive
 * @name flowList
 * @module flowingly.runner.flow
 * @description This comppnent is used to group a list of flows.
 *              It is responsible for opening / closing flows when one is clicked.
 *              It is also (currently) responsible for switching between mobile and desktop views
 * @usage
 * ```
     <flow-list flows="ctrl.flows" is-mobile="ctrl.isMobile"></flow-list>
 * ``` 
 * ### Notes
 * See Also: https://bizflo.atlassian.net/wiki/display/TECH/Angular+Flow+Components
 * ### Properties
 * #### Inputs
 * * flows: list of flows Im In for the group
 * * groups: list of all groups
 * * isMobile: show mobile view if set
 * * userId: userid of the logged in user
 */

'use strict';
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular from 'angular';
import { IStateService } from 'angular-ui-router';

angular.module('flowingly.runner.flow').component('flowList', {
  bindings: {
    typeflows: '@',
    flows: '<',
    groups: '<',
    isMobile: '<', //show mobile view if set
    userId: '<' //userid of the logged in user
  },
  controller: [
    '$state',
    'appInsightsService',
    function (
      $state: IStateService,
      appInsightsService: SharedAngular.AppInsightsService
    ) {
      const $ctrl = this;
      $ctrl.showComments = false;

      this.showFlowDetails = function (flow) {
        // FLOW-6452 Set the time user clicked on the flow to load it.
        appInsightsService.startEventTimer(
          appInsightsService.Events.FLOW_SELECTED_FROM_LIST
        );
        $ctrl.showComments = false;
        $state.go('app.runner.flow', { flowId: flow.FlowId });
      };
    }
  ],
  controllerAs: '$ctrl',
  templateUrl: 'Client/runner.flow/runner.flow.list/runner.flow.list.tmpl.html'
});
