import * as angular from 'angular';

class RunnerProcessMapsViewController {
  public static ID = 'runnerProcessMapsViewController';
  public static $inject = [
    '$q',
    '$state',
    'APP_CONFIG',
    'sessionService',
    'tempModelerUrlService',
    '$stateParams',
    'flowApiService',
    'flowinglyDiagramService',
    'goService',
    '$timeout',
    'lodashService',
    'commentApiService',
    'avatarService',
    '$scope',
    'flowinglyConstants',
    '$window',
    'flowListManager',
    'permissionsService'
  ];

  constructor(
    private $q,
    private $state,
    private APP_CONFIG,
    private sessionService,
    private tempModelerUrlService,
    private $stateParams,
    private flowApiService,
    private flowinglyDiagramService,
    private goService,
    private $timeout,
    private lodashService,
    private commentApiService,
    private avatarService,
    private $scope,
    private flowinglyConstants,
    private $window,
    private flowListManager,
    private permissionsService
  ) {
    this.commentTargetTypeFlowModel =
      this.flowinglyConstants.commentTargetType.FLOW_MODEL;
    sessionService.onReady(() => this.init());
  }

  private processMap: any;
  private canEdit: boolean;
  private editableByCurrentUser: boolean;
  private diagram: any;
  private selectedNode: any;
  private tabStrip: any;
  private tabStrip2: any;
  private commentTargetTypeFlowModel: number;
  private flowComments: any;
  private flowOwnerOnly: boolean;
  private FIXED_ELEMS_TOTAL_HEIGHT: number = 193;
  private THRESHOLD_TO_ADJUST: number = 520;
  private allowProcessMap;
  private initialized = false;
  private DEADLINETYPES: any = {
    none: 'None'
  };
  private isProcessMap: boolean = true;
  private hasAccessToProcessMap: boolean = true;

  private nodeDetails: any;
  private NOT_YET_DEFINED_PROCESS_OWNER: string =
    'Process Owner not yet defined';

  private init() {
    this.allowProcessMap = this.APP_CONFIG.allowProcessMap;
    this.initialized = true;
    if (!this.allowProcessMap) {
      return;
    }

    this.$window.addEventListener('resize', this.setDynamicMaxHeightResize);
    this.setDynamicHeightsOnInit();
    this.registerKendoWidget();
    this.displayProcessMap();
  }

  private registerKendoWidget() {
    this.$scope.$on('kendoWidgetCreated', (ev, widget) => {
      if (widget === this.tabStrip) {
        this.tabStrip.select(0);
      }
      if (widget === this.tabStrip2) {
        this.tabStrip2.select(1);
      }
    });
  }

  private displayProcessMap() {
    const { sessionService, $q } = this;

    this.onChangedSelection = this.onChangedSelection.bind(this);
    this.flowOwnerOnly = this.isFlowOwnerOnly();
    this.hasAccessToProcessMap = this.hasAccessToProcessMap;

    const processMapId = this.$stateParams.processMapId;
    const user = sessionService.getUser();

    $q.all([this.flowApiService.getProcessMap(processMapId)])
      .then(([processMap]) => {
        this.nodeDetails = typeof (processMap.flowSchema !== 'undefined')
          ? JSON.parse(processMap.flowSchema)
          : [];

        processMap.Id = processMapId;
        processMap.isProcessMap = this.isProcessMap;
        this.processMap = processMap;
        this.processMap.processOwner =
          this.processMap.processOwner == ''
            ? this.NOT_YET_DEFINED_PROCESS_OWNER
            : this.processMap.processOwner;

        this.editableByCurrentUser = processMap.editableByCurrentUser;
        this.canEdit =
          this.permissionsService.currentUserHasPermission(
            this.flowinglyConstants.permissions.FLOWMODEL_UPDATE
          ) ||
          (processMap.IsFlowModelOwner && this.APP_CONFIG.fmoCanPublish);

        let _dynamicInitialHt =
          this.$window.innerHeight - this.FIXED_ELEMS_TOTAL_HEIGHT;

        return this.$timeout(() => {
          this.diagram = this.flowinglyDiagramService.generateProcessModel({
            flow: processMap,
            applyAvatar: false,
            modelCustomArgs: {
              scrollMode: this.goService.Diagram.DocumentScroll
            },
            allowSelect: true,
            dynamicInitialHeight: _dynamicInitialHt
          });

          if (typeof this.diagram !== 'undefined') {
            this.diagram.addDiagramListener(
              'ChangedSelection',
              this.onChangedSelection
            );
            (window as any).diagram = this.diagram;
          }

          (window as any).processMap = this.processMap;
        });
      })
      .then(() => {
        this.showComments();
      })
      .catch(() => {
        console.log(
          'No access allowed to this process map or an error occured while retrieving process map object'
        );
        this.hasAccessToProcessMap = false;
      });
  }

  private tabOptions = {
    activate: this.onTabActive
  };

  private onTabActive(e) {
    const tabName = angular
      .element(e.item)
      .text()
      .replace(/(\(\d*\))/gm, '')
      .trim();
  }

  private showComments() {
    if (!this.isFeedbackVisible()) {
      return;
    }

    const { lodashService, commentApiService, avatarService } = this;

    commentApiService
      .getFlowComments(this.commentTargetTypeFlowModel, this.processMap.Id)
      .then((data) => {
        this.flowComments = data;
        this.processMap.CommentCount = this.flowComments.length;
        lodashService.forEach(this.flowComments, (comment) => {
          comment.avatarUrl = avatarService.getAvatarUrl(comment.userId);
        });
      });
  }

  private isFeedbackVisible() {
    return (
      this.APP_CONFIG.flowModelFeedbackVisibility.toLowerCase() === 'all' ||
      (this.APP_CONFIG.flowModelFeedbackVisibility.toLowerCase() ===
        'flowowners' &&
        this.editableByCurrentUser)
    );
  }

  private onChangedSelection(e) {
    let selectedNode = this.diagram.selection.first();
    let selectedNodeData =
      selectedNode instanceof this.goService.Node ? selectedNode.data : null;

    if (selectedNodeData == null) {
      //checks if an actual node is clicked
      return;
    }

    let selectedStep = this.processMap.steps.find(
      (n) => n.id == selectedNodeData.id
    );

    if (typeof selectedStep !== 'undefined') {
      //checks if the node clicked is a "normal" step

      if (
        selectedStep.stepType === this.flowinglyConstants.taskType.CUSTOM_EMAIL
      ) {
        this.selectedNode = null;
      } else {
        if (!selectedStep.fields || selectedStep.fields.length === 0) {
          this.flowApiService
            .getProcessMapNodeElement(selectedNodeData.id, false)
            .then((data) => {
              selectedStep.fields = data;
              this.selectedNode = selectedStep;
              this.setSeletedNodeValues(this.selectedNode);
            });
        } else {
          this.selectedNode = selectedStep;
          this.setSeletedNodeValues(this.selectedNode);
        }
      }
    } else {
      this.selectedNode = selectedStep;
    }
  }

  private setSeletedNodeValues(selectedNode) {
    selectedNode.deadLine = this.formatDisplay(
      selectedNode.deadLineNumber,
      selectedNode.deadLineType
    );
    selectedNode.plannedTime = this.formatDisplay(
      selectedNode.costTimeNumber,
      selectedNode.costTimeType
    );
    selectedNode.stepActor = this.nodeDetails.nodeDataArray.find(
      (n) => n.id == selectedNode.id
    ).actorName;
    selectedNode.reminder = this.formatDisplay(
      selectedNode.reminderNumber,
      selectedNode.reminderType
    );
    this.setupStepActorAvatar(selectedNode.stepActor);
    this.lodashService.forEach(selectedNode.fields, (f) => {
      f.isNodeFromRunnerProcessMap = true;
      f.type = this.convertNumericToEnumFieldType(f.type);
    });
  }

  private setDynamicHeightsOnInit() {
    let dynamicHeight =
      this.$window.innerHeight - this.FIXED_ELEMS_TOTAL_HEIGHT;

    $('.pmap-dynamic-height').css('max-height', dynamicHeight);

    $('.pmap-dynamic-height-with-abs-elem').css('height', dynamicHeight);
    let positionValue =
      dynamicHeight < this.THRESHOLD_TO_ADJUST ? 'relative' : 'absolute';
    $('.edit-or-view-flowmodel-btn').css('position', positionValue);
  }

  private setDynamicMaxHeightResize() {
    const FIXED_ELEMS_TOTAL_HEIGHT = 193,
      THRESHOLD_TO_ADJUST = 520;

    let dynamicHeight = window.innerHeight - FIXED_ELEMS_TOTAL_HEIGHT,
      dynamicHeight_px = dynamicHeight + 'px';

    $('.pmap-dynamic-height').css('max-height', dynamicHeight_px);

    $('.pmap-dynamic-height-with-abs-elem').css('height', dynamicHeight_px);

    let positionValue =
      dynamicHeight < THRESHOLD_TO_ADJUST ? 'relative' : 'absolute';
    $('.edit-or-view-flowmodel-btn').css('position', positionValue);
    $('.flow-model').css('height', dynamicHeight_px);
  }

  private onEditOrViewFlowModelClick() {
    this.tempModelerUrlService.openModeler(this.processMap.Id);
  }

  private onBackButtonClick() {
    this.$state.go(
      'app.runner.processmap',
      {},
      { notify: true, replace: true, relative: false }
    );
  }

  private onBackToFlowImButtonClick() {
    this.$state.go(
      'app.runner.flowsin',
      {},
      { notify: true, replace: true, relative: false }
    );
  }

  private onCommentAdded(processMapId, count) {
    this.flowListManager.updateFlowCommentCountInLists(processMapId, count);
    this.processMap.CommentCount = count;
  }

  private isFlowOwnerOnly() {
    return (
      this.APP_CONFIG.flowModelFeedbackVisibility.toLowerCase() === 'flowowners'
    );
  }

  private formatDisplay(num, type) {
    let _deadline = null;

    if (type == this.DEADLINETYPES.none) {
      return this.DEADLINETYPES.none;
    }

    if (num && type) {
      if (num == 1 && type != this.DEADLINETYPES.none) {
        type = type.slice(0, type.length - 1); //Hours to Hour, Days to Day
      }

      _deadline = num + ' ' + type;
    }

    return _deadline;
  }

  private convertNumericToEnumFieldType(numfield) {
    switch (numfield) {
      case 0:
        return this.flowinglyConstants.formFieldType.APPROVAL_COMMENT;
      case 1:
        return this.flowinglyConstants.formFieldType.APPROVAL_RULE;
      //case 2: return this.flowinglyConstants.formFieldType.;    Button?
      case 3:
        return this.flowinglyConstants.formFieldType.CHECKBOX;
      case 4:
        return this.flowinglyConstants.formFieldType.CURRENCY;
      case 5:
        return this.flowinglyConstants.formFieldType.EMAIL;
      case 6:
        return this.flowinglyConstants.formFieldType.FILE_UPLOAD;
      case 7:
        return this.flowinglyConstants.formFieldType.INSTRUCTION;
      case 8:
        return this.flowinglyConstants.formFieldType.TABLE;
      case 9:
        return this.flowinglyConstants.formFieldType.TEXT;
      case 10:
        return this.flowinglyConstants.formFieldType.TEXTAREA;
      //case 11: return this.flowinglyConstants.formFieldType.;   TextBox?
      case 12:
        return this.flowinglyConstants.formFieldType.NUMBER;
      case 13:
        return this.flowinglyConstants.formFieldType.PASSWORD;
      case 14:
        return this.flowinglyConstants.formFieldType.RADIO_BUTTON_LIST;
      case 15:
        return this.flowinglyConstants.formFieldType.SELECT_LIST;
      //case 16: return this.flowinglyConstants.formFieldType.;   Submit?
      case 17:
        return this.flowinglyConstants.formFieldType.TASK_LIST;
      case 18:
        return this.flowinglyConstants.formFieldType.MULTISELECT_LIST;
      case 19:
        return this.flowinglyConstants.formFieldType.DATE;
      case 20:
        return this.flowinglyConstants.formFieldType.DATETIME;
      case 21:
        return this.flowinglyConstants.formFieldType.SIGNATURE;
      //case 22: return this.flowinglyConstants.formFieldType.;  DynamicActors?
      case 23:
        return this.flowinglyConstants.formFieldType.LOOKUP;
      case 24:
        return this.flowinglyConstants.formFieldType.FORMULA;
      case 25:
        return this.flowinglyConstants.formFieldType.ATTACH_DOCUMENT;
      case 26:
        return this.flowinglyConstants.formFieldType.IMAGE;
      default:
        return numfield;
    }
  }

  private setupStepActorAvatar(actor) {
    if (this.selectedNode.stepActor) {
      this.selectedNode.actorFirstLetter = this.avatarService.getUserInitial(
        this.selectedNode.stepActor
      );
      let avatarBgColor =
        this.avatarService.getBgColorForProcessMapRightPaneAvatar(
          this.selectedNode.actorFirstLetter
        );
      $('#pmap-avatar').css('background', avatarBgColor);
    } else {
      this.selectedNode.stepActor.firstLetter = '?';
    }
  }
}
angular
  .module('flowingly.runner.processmap')
  .controller(
    RunnerProcessMapsViewController.ID,
    RunnerProcessMapsViewController
  );
