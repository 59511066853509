'use strict';
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular from 'angular';
// Register the application's routes configuration.
angular.module('flowingly.runner.maintenance').config(config);

// Inject the dependencies.
config.$inject = ['$stateProvider'];

const IDENTIFIER_VALIDATION_MESSAGE = 'Please enter Flow ID or Identifier';

function config($stateProvider: angular.ui.IStateProvider) {
  $stateProvider.state('app.runner.maintenance.flows', {
    url: '/flows',
    params: { title: 'Maintenance - Flows' },
    views: {
      childcontent: {
        templateUrl:
          'Client/runner.maintenance/flows/runner.maintenance.flows.tmpl.html',
        controllerAs: '$ctrl',
        controller: [
          'maintenanceService',
          'authService',
          'dialogService',
          function (
            maintenanceService: MaintenanceService,
            authService: AuthService,
            dialogService: SharedAngular.DialogService
          ) {
            const $ctrl = this;

            $ctrl.loadFlowErrorElementId = 'load-flow-error';
            $ctrl.businesses = [];
            const recommendations = {
              DELETE_STEP:
                'Delete the incomplete Step so the prior Step can be resubmitted.',
              DELETE_STEP_CHILD_FLOW:
                'Delete the incomplete Step so the prior Step can be resubmitted, ' +
                "there's no automatic delete for Steps of child Flows available yet.",
              DELETE_STEP_MULTIPLE:
                'Delete the incomplete Steps so the prior Steps can be resubmitted, ' +
                "there's no automatic delete for multiple active Steps available yet.",
              FIX_ACTIVITY_NAME:
                'Before Step(s) can be resubmitted the Flow ActivityName(s) must be updated ' +
                'to reference the Step(s) requiring resubmission. ' +
                'WARNING: If the missing Step(s) are before or after a Diverge or Merge this is complicated!',
              RESUBMIT_STEP:
                'Try resubmit the last completed Step to create the next Step.'
            };

            authService.getUserDeferred().then((user) => {
              maintenanceService.getBusinesses().then((businesses) => {
                $ctrl.businesses = businesses.sort((a, b) =>
                  a.name.localeCompare(b.name)
                );
                $ctrl.selectedBusinessId = user.businessId;
              });
            });

            let loadFlowErrorTimeoutHandle = undefined;
            $ctrl.loadFlow = function (identifier) {
              $ctrl.flow = null;
              if (!isInputEmpty(identifier)) {
                showValidationErrorForFlowInput(IDENTIFIER_VALIDATION_MESSAGE);
                return;
              }
              maintenanceService
                .getFlow($ctrl.selectedBusinessId, identifier)
                .then((response) => {
                  const errorElement = document.getElementById(
                    $ctrl.loadFlowErrorElementId
                  );
                  if (response.success) {
                    errorElement.classList.toggle('hide', true);
                    const flow = response.dataModel;
                    identifyFlowIssues(flow);
                    $ctrl.flow = flow;
                  } else {
                    errorElement.textContent = response.errorMessage;
                    if (loadFlowErrorTimeoutHandle) {
                      clearTimeout(loadFlowErrorTimeoutHandle);
                      loadFlowErrorTimeoutHandle = undefined;
                    }
                    errorElement.classList.toggle('hide', false);
                    loadFlowErrorTimeoutHandle = setTimeout(() => {
                      errorElement.classList.toggle('hide', true);
                    }, 10000);
                  }
                });
            };

            $ctrl.cancelFlow = function (identifier, changeRequestId) {
              identifier = getIdentifier();

              if (!isInputEmpty(identifier)) {
                showValidationErrorForFlowInput(IDENTIFIER_VALIDATION_MESSAGE);
                return;
              }

              const options = {
                template:
                  'Client/runner.maintenance/flows/dialogs/action.tmpl.html',
                controller: 'maintenanceFlowActionDialogController',
                data: {
                  flowIdentifier: identifier,
                  action: 'Delete',
                  message: 'The flow will be deleted!',
                  isFlow: true
                }
              };
              dialogService.showDialog(options).then((actionConfirmed) => {
                if (actionConfirmed === true) {
                  maintenanceService
                    .cancelFlow(identifier, changeRequestId)
                    .then((response) => {
                      if (response.Success) {
                        $ctrl.loadFlow(identifier);
                      } else {
                        const options = {
                          headerText: 'Error',
                          message: response.ErrorMessage
                        };
                        dialogService.showMessageDialog(options);
                      }
                    });
                }
              });
            };

            $ctrl.markFlowAsCompleted = function (identifier, changeRequestId) {
              identifier = getIdentifier();

              if (!isInputEmpty(identifier)) {
                showValidationErrorForFlowInput(IDENTIFIER_VALIDATION_MESSAGE);
                return;
              }

              const options = {
                template:
                  'Client/runner.maintenance/flows/dialogs/action.tmpl.html',
                controller: 'maintenanceFlowActionDialogController',
                data: {
                  flowIdentifier: identifier,
                  action: 'Mark As Complete',
                  message: 'The flow will be marked as completed!',
                  isFlow: true
                }
              };
              dialogService.showDialog(options).then((actionConfirmed) => {
                if (actionConfirmed === true) {
                  maintenanceService
                    .markFlowAsCompleted(identifier, changeRequestId)
                    .then((response) => {
                      if (response.Success) {
                        $ctrl.loadFlow(identifier);
                      } else {
                        const options = {
                          headerText: 'Error',
                          message: response.ErrorMessage
                        };
                        dialogService.showMessageDialog(options);
                      }
                    });
                }
              });
            };

            $ctrl.copyToClipboard = function copyToClipboard(text) {
              const type = 'text/plain';
              const blob = new Blob([text], { type });
              const data = [new ClipboardItem({ [type]: blob })];
              navigator.clipboard.write(data);
            };

            $ctrl.deleteStep = function (step) {
              const businessId = $ctrl.selectedBusinessId;
              const options = {
                template:
                  'Client/runner.maintenance/flows/dialogs/action.tmpl.html',
                controller: 'maintenanceFlowActionDialogController',
                data: {
                  stepName: step.name,
                  action: 'Delete',
                  message:
                    'The targeted Step will be deleted and the previous Step will be made active, ' +
                    "don't do this if the targeted Step is the first Step after a Diverge or Merge!"
                }
              };
              dialogService.showDialog(options).then((actionConfirmed) => {
                if (actionConfirmed === true) {
                  maintenanceService
                    .deleteStep(step.id, step.flowId, businessId)
                    .then((response) => {
                      if (response.success) {
                        $ctrl.loadFlow($ctrl.flow.id);
                      } else {
                        const options = {
                          headerText: 'Error',
                          message: response.errorMessage
                        };
                        dialogService.showMessageDialog(options);
                      }
                    });
                }
              });
            };

            $ctrl.resubmitStep = function (stepId, stepName) {
              const flowId = $ctrl.flow.id;
              const businessId = $ctrl.selectedBusinessId;
              const options = {
                template:
                  'Client/runner.maintenance/flows/dialogs/action.tmpl.html',
                controller: 'maintenanceFlowActionDialogController',
                data: {
                  stepName: stepName,
                  action: 'Resubmit',
                  message:
                    'The Step will be resubmitted with the same data as was originally submitted, in the hope ' +
                    'that the error which prevented the next Step from being created is now fixed, or was transient.'
                }
              };
              dialogService.showDialog(options).then((actionConfirmed) => {
                if (actionConfirmed === true) {
                  maintenanceService
                    .resubmitStep(stepId, flowId, businessId)
                    .then((response) => {
                      if (response.success) {
                        $ctrl.loadFlow($ctrl.flow.id);
                      } else {
                        const options = {
                          headerText: 'Error',
                          message: response.errorMessage
                        };
                        dialogService.showMessageDialog(options);
                      }
                    });
                }
              });
            };

            function isInputEmpty(input) {
              if (input === undefined || input === null || input.length < 1) {
                return false;
              }
              return true;
            }

            function showValidationErrorForFlowInput(validationMessage) {
              const errorElement = document.getElementById(
                $ctrl.loadFlowErrorElementId
              );
              errorElement.textContent = validationMessage;
              if (loadFlowErrorTimeoutHandle) {
                clearTimeout(loadFlowErrorTimeoutHandle);
                loadFlowErrorTimeoutHandle = undefined;
              }
              errorElement.classList.toggle('hide', false);
              loadFlowErrorTimeoutHandle = setTimeout(() => {
                errorElement.classList.toggle('hide', true);
              }, 10000);
            }

            function getIdentifier() {
              //Identifier is having a debounce value.
              //If the user changes the value of identifier input and clicks the delete button within debounce time, the identifier binding will still be showing old value.
              //So we are fetching the identifier input without using binding here!
              return document.getElementById('flowIdInput').value.trim();
            }

            function identifyFlowIssues(flow) {
              $ctrl.flowIssues = [];
              $ctrl.flowRecommendations = [];

              if (flow.status === 'In Progress') {
                identifyPartiallyCreatedSteps(flow, $ctrl.flowIssues);
                identifyInvalidActivity(flow, $ctrl.flowIssues);
                identifyStalledFlow(flow, $ctrl.flowIssues);
              }
            }

            function identifyPartiallyCreatedSteps(flow, issues) {
              const problemSteps =
                flow.steps.filter(
                  (step) => step.status === 'Todo' && step.fieldCount === 0
                ) || [];
              problemSteps.forEach((step) => {
                issues.push(
                  `Incomplete Step "${step.name}" has no fields` +
                    ' - probably due to an exception during Step creation.'
                );

                if (problemSteps.length === 1) {
                  if (step.flowId === flow.id) {
                    step.canDelete = true;
                    addRecommendation(recommendations.DELETE_STEP);
                  } else {
                    addRecommendation(recommendations.DELETE_STEP_CHILD_FLOW);
                  }
                } else {
                  addRecommendation(recommendations.DELETE_STEP_MULTIPLE);
                }
              });
            }

            function identifyInvalidActivity(flow, issues) {
              const APPROVAL_GATEWAY_PREFIX = 'ApprovalRuleGateway_';
              if (flow.activityName.startsWith(APPROVAL_GATEWAY_PREFIX)) {
                issues.push(
                  'Flow.ActivityName is not referencing a valid Step' +
                    ' - probably due to an exception during Step creation.'
                );

                const todoSteps =
                  flow.steps.filter((step) => step.status === 'Todo') || [];
                if (todoSteps.length === 0) {
                  const failedStepName = flow.activityName.replace(
                    APPROVAL_GATEWAY_PREFIX,
                    ''
                  );
                  const failedStep = flow.steps.find(
                    (step) => step.name === failedStepName
                  );
                  failedStep.canResubmit = true;
                  addRecommendation(recommendations.RESUBMIT_STEP);
                } else if (todoSteps.length === 1) {
                  todoSteps[0].canDelete = true;
                  addRecommendation(recommendations.DELETE_STEP);
                } else {
                  addRecommendation(recommendations.DELETE_STEP_MULTIPLE);
                }
              }
            }

            function identifyStalledFlow(flow, issues) {
              if (flow.steps.some((step) => step.status === 'Todo')) {
                return;
              }

              issues.push(
                'Flow is incomplete but has no active steps to submit.'
              );
              let foundStepToResubmit = false;

              flow.steps.forEach((step) => {
                const stepFlow =
                  step.flowId === flow.id
                    ? flow
                    : flow.childFlows.find((flow) => flow.id === step.flowId);
                if (step.name === flow.activityName) {
                  step.canResubmit = true;
                  addRecommendation(recommendations.RESUBMIT_STEP);
                  foundStepToResubmit = true;
                }
              });

              if (!foundStepToResubmit) {
                issues.push(
                  "Flow ActivityName(s) reference Steps that haven't been instantiated."
                );
                addRecommendation(recommendations.FIX_ACTIVITY_NAME);
              }
            }

            function addRecommendation(recommendation) {
              if (
                $ctrl.flowRecommendations.some((fr) => fr === recommendation)
              ) {
                return;
              }

              $ctrl.flowRecommendations.push(recommendation);
            }
          }
        ]
      }
    }
  });
}
