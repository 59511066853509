'use strict';
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular, {
  IQService,
  IRootScopeService,
  ITimeoutService,
  dialog
} from 'angular';

angular
  .module('flowingly.runner.setup')
  .controller(
    'maintenanceUserDetailController',
    maintenanceUserDetailController
  );

maintenanceUserDetailController.$inject = [
  '$scope',
  'zxcvbnAdapter',
  'ngDialog',
  'maintenanceService',
  'roleApiService',
  'notificationService',
  'lodashService',
  'accountApiService',
  'pubsubService',
  'sessionService',
  'authService',
  'avatarService',
  'validationService',
  'APP_CONFIG',
  '$q',
  '$rootScope',
  '$timeout',
  'permissionsService',
  'flowinglyConstants'
];

function maintenanceUserDetailController(
  $scope: IScopeNgDialog,
  zxcvbn: zxcvbn,
  ngDialog: dialog.IDialogService,
  maintenanceService: MaintenanceService,
  roleApiService: RoleApiService,
  notificationService: SharedAngular.NotificationService,
  lodashService: Lodash,
  accountApiService: AccountApiService,
  pubsubService: SharedAngular.PubSubService,
  sessionService: SharedAngular.SessionService,
  authService: AuthService,
  avatarService: SharedAngular.AvatarService,
  validationService: SharedAngular.ValidationService,
  APP_CONFIG: AppConfig,
  $q: IQService,
  $rootScope: IRootScopeService,
  $timeout: ITimeoutService,
  permissionsService: SharedAngular.PermissionsService,
  flowinglyConstants: SharedAngular.FlowinglyConstants
) {
  const ctrl = this;
  ctrl.hasFinishedLoading = false;
  ctrl.selectedManager = null;
  ctrl.changeRequestId = null;
  ctrl.closeDialog = closeDialog;
  ctrl.update = update;

  init();

  function closeDialog(updated) {
    ngDialog.closeAll(updated);
  }

  function update() {
    maintenanceService
      .updateUserStatuses(
        ctrl.user.id,
        ctrl.user.businessId,
        ctrl.selectedManager.id,
        ctrl.user.inviteUser,
        ctrl.user.delete,
        ctrl.user.active,
        ctrl.changeRequestId
      )
      .then((response) => {
        if (!response.errorMessage) {
          closeDialog(true);
          notificationService.showSuccessToast('User Updated');
        }
      });
  }

  function init() {
    const user = $scope.ngDialogData;
    ctrl.userFullName = user.fullName;
    return getUserSummaryById(user.id, user.businessId)
      .then((currentUserSummary) => {
        ctrl.user = currentUserSummary;
        ctrl.selectedManager = {
          id: ctrl.user.managerUserId,
          FullName: ctrl.user.managerName || ''
        };
      })
      .then(() => {
        ctrl.hasFinishedLoading = true;
      });
  }

  function getUserSummaryById(userId, businessId) {
    if (!userId || !businessId) {
      return undefined;
    }

    const filterParams = {
      filter: {
        filters: [{ field: 'id', value: userId }]
      }
    };

    return maintenanceService
      .getUsersWithOptions(filterParams, businessId)
      .then((response) => {
        if (response.errorMessage) {
          setTimeout(() => {
            closeDialog(true);
          }, 5000);
          return;
        }
        return response.users[0];
      });
  }
}
