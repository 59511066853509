/**
 * @ngdoc service
 * @name runnerStartFlowsFormatter
 * @module flowingly.runner.services
 *
 * @description Service repsonsible for formatting flows available (can start)
 *
 *
 * ###API
 * * groupFlowModelsByCategory - format the server response into something the client side can consume.
 */
import angular from 'angular';

export class RunnerStartFlowsFormatter {
  public static ID = 'runnerStartFlowsFormatter';

  public groupFlowModelsByCategory(flowModels) {
    if (flowModels.length === 0) {
      return flowModels;
    }
    return this.doGrouping(flowModels);
  }

  private doGrouping(flowModels) {
    const categories = [];
    flowModels.forEach((flowModel) => {
      const category = categories.find((c) => c.name === flowModel.category);
      if (category == undefined) {
        categories.push({
          name: flowModel.category,
          id: flowModel.categoryId,
          flowModels: [flowModel]
        });
      } else {
        category.flowModels.push(flowModel);
      }
    });
    return categories;
  }
}

angular
  .module('flowingly.runner.services')
  .service(RunnerStartFlowsFormatter.ID, RunnerStartFlowsFormatter);

export type RunnerStartFlowsFormatterType = InstanceType<
  typeof RunnerStartFlowsFormatter
>;
