/**
 * @ngdoc type
 * @module flowingly.runner.exago.single.imin
 * @name runnerExagoControllerSingle
 *
 * @description
 */

'use strict';
import angular from 'angular';

angular
  .module('flowingly.runner.exago.single')
  .controller('runnerExagoControllerSingle', runnerExagoControllerSingle);
runnerExagoControllerSingle.$inject = ['APP_CONFIG'];

function runnerExagoControllerSingle(APP_CONFIG: AppConfig) {
  this.reportFolder = APP_CONFIG.exagoConfig.defaultDashboard.folder;
  this.reportName = APP_CONFIG.exagoConfig.defaultDashboard.name;
}
