/**
 * @ngdoc type
 * @module flowingly.runner.flows.todo
 * @name runnerFlowsTodoController
 *
 * @description
 */

'use strict';
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular, { IScope, ITimeoutService } from 'angular';
import { IStateService } from 'angular-ui-router';

angular
  .module('flowingly.runner.flows.todo')
  .controller('runnerFlowsTodoController', runnerFlowsTodoController);

runnerFlowsTodoController.$inject = [
  '$scope',
  'flowListManager',
  'sessionService',
  'authService',
  'pubsubService',
  'devLoggingService',
  'lodashService',
  '$timeout',
  'APP_CONFIG',
  'permissionsService',
  'flowinglyConstants',
  '$state'
];

function runnerFlowsTodoController(
  $scope: IScope,
  flowListManager: FlowListManager,
  sessionService: SharedAngular.SessionService,
  authService: AuthService,
  pubsubService: SharedAngular.PubSubService,
  logger: SharedAngular.DevLoggingService,
  lodashService: Lodash,
  $timeout: ITimeoutService,
  APP_CONFIG: AppConfig,
  permissionsService: SharedAngular.PermissionsService,
  flowinglyConstants: SharedAngular.FlowinglyConstants,
  $state: IStateService
) {
  if (
    !permissionsService.currentUserHasPermission(
      flowinglyConstants.permissions.TODO_ACCESS
    )
  ) {
    $state.go('app.runner.flowsin');
    return;
  }

  const ctrl = this;

  ctrl.isMobile = $scope.isMobile;
  ctrl.groups = flowListManager.groupedFlowsTodo;
  ctrl.categories = flowListManager.flowToDoCategories;
  ctrl.showDefaultMessage = showDefaultMessage;
  ctrl.changeFlowFilter = changeFlowFilter;
  ctrl.changeCategoryFilter = changeCategoryFilter;
  ctrl.selectedFlowCategory = flowListManager.getTodoFlowCategory();
  ctrl.initiating = true;
  ctrl.categoryClicked = categoryClicked;
  ctrl.allCategoriesId = 'all-categories';

  init();

  function init() {
    const user = authService.getUser();
    ctrl.userId = user.id;

    if (sessionService.isInitialLogin()) {
      logger.log(
        'Todo controller: initial login so retrieve todo and imin flows.'
      );
      flowListManager.refreshFlowInstanceLists(false).then(() => {
        ctrl.initiating = false;
        setFilterOptions();
        selectAllOption();
      });
      sessionService.clearLoginFlag();
    } else {
      if (ctrl.groups.length === 0) {
        flowListManager.refreshFlowsTodo().then(() => {
          logger.log(
            'Todo controller: have retrieved the todo flows. #todogroups: ' +
              ctrl.groups.length
          );

          setFilterOptions();
          selectAllOption();
          ctrl.initiating = false;
        });
      } else {
        ctrl.selectedFlowCategory = null; // All.
        changeCategoryFilter();
        selectAllOption();
        ctrl.initiating = false;
      }
    }

    const flowsToDoSubscriberId = 'runnerFlowsTodoController';
    pubsubService.subscribe(
      'SIGNALR_WORKFLOW_NAME_CHANGED',
      onFlowModelNameChanged,
      flowsToDoSubscriberId
    );
    $scope.$on('$destroy', () => {
      pubsubService.unsubscribeAll(flowsToDoSubscriberId);
    });
  }

  function setFilterOptions() {
    setFlowFilters();
    ctrl.selectedFlowFilter = flowListManager.getTodoFlowFilter();
    ctrl.selectedFlowCategory = flowListManager.getTodoFlowCategory();
  }

  // truncate lengthy flownames for displaying flow filter dropdown to fit in the screen. tooltip will show full flow name
  function setFlowFilters() {
    ctrl.flowFilters = [];
    const maxchars = APP_CONFIG.flowFilterMaxCharacters;
    lodashService.forEach(
      flowListManager.getTodoFlowFilters(),
      function (item) {
        let fname = item.name;
        if (fname.length > maxchars) {
          fname = fname.substring(0, maxchars) + '...';
        }
        const flowFilter = {
          id: item.id,
          name: fname,
          title: item.name
        };
        ctrl.flowFilters.push(flowFilter);
      }
    );
  }

  function onFlowModelNameChanged(msg, data) {
    const msgdata = JSON.parse(data);
    flowListManager.updateFlowNames(msgdata);
    ctrl.groups = flowListManager.groupedFlowsTodo;
  }

  function changeFlowFilter() {
    flowListManager.setTodoFlowFilter(ctrl.selectedFlowFilter);
    flowListManager.applyFlowTodoFilter();

    const visibleFlows = ctrl.groups.filter((group) => group.show);
    if (visibleFlows && visibleFlows.length > 0) {
      // All the visible flows are for the same flow model which belongs to one flow category.
      const selectedFlowModelCategoryId = visibleFlows[0].FlowCategoryId;
      if (selectedFlowModelCategoryId) {
        highlightSelectedCategory(selectedFlowModelCategoryId);
      }
    }
  }

  function changeCategoryFilter() {
    flowListManager.setTodoFlowCategory(ctrl.selectedFlowCategory);
    flowListManager.applyFlowTodoFilter();
    setFilterOptions();
  }

  function showDefaultMessage() {
    const hasVisibleGroup = ctrl.groups.some((g) => {
      return g.show;
    });

    return !hasVisibleGroup;
  }

  function categoryClicked(categoryId) {
    if (categoryId !== ctrl.allCategoriesId) {
      ctrl.selectedFlowCategory = categoryId;
      highlightSelectedCategory(categoryId);
    } else {
      ctrl.selectedFlowCategory = null; // All Categories.
      highlightSelectedCategory(ctrl.allCategoriesId);
    }

    changeCategoryFilter();
  }

  function selectAllOption() {
    $timeout(() => {
      highlightSelectedCategory(ctrl.allCategoriesId);
    });
  }

  function highlightSelectedCategory(categoryId) {
    const selectedCategoryClassName = 'selected-category';

    // First unselect all.
    const allSelected = document.getElementsByClassName(
      selectedCategoryClassName
    );
    if (allSelected) {
      lodashService.forEach(allSelected, (elem) => {
        elem.classList.remove(selectedCategoryClassName);
      });
    }

    // Select the category.
    if (categoryId != null && categoryId !== '') {
      const categoryNameElement = document.getElementById(categoryId);
      if (categoryNameElement) {
        categoryNameElement.classList.toggle(selectedCategoryClassName);
      }
    }
  }
}
