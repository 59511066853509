import React, { useState } from 'react';
import { Tab, Typography } from '@mui/material';

const CustomTabHeader = ({ label, ...rest }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const tabClassNames = `v2-custom-tab-header ${isHovered ? 'is-hovered' : ''}`;
  return (
    <Tab
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...rest}
      disableRipple
      component="div"
      className={tabClassNames}
      label={
        <div>
          <Typography variant="inherit" className="label">
            {label}
          </Typography>
          <div className="indicator" />
        </div>
      }
    />
  );
};

export default CustomTabHeader;
