/**
 * @name flowHistoryFieldValue
 * @module flowingly.runner.directives
 * @description display field values consistently for completed steps
 * ### Notes
 * 
 * @usage
 * ```
    <flow-history-field-value field-value="{{item.Text}}"></flow-history-field-value>
 * ```
 */
'use strict';
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular from 'angular';

angular
  .module('flowingly.runner.directives')
  .component('flowHistoryFieldValue', {
    bindings: {
      fieldType: '<',
      fieldValue: '<'
    },
    controller: [
      'validationService',
      '$sce',
      function (
        validationService: SharedAngular.ValidationService,
        $sce: angular.ISCEService
      ) {
        const $ctrl = this;
        $ctrl.formValue = undefined;
        $ctrl.noValueText = false;

        $ctrl.$onInit = function () {
          setDefaultMessage();

          if (isFieldEmpty()) {
            $ctrl.noValueText = true;
          } else {
            $ctrl.fieldValue = $ctrl.fieldValue.trim();

            if (
              ($ctrl.fieldValue.startsWith('[') &&
                $ctrl.fieldValue.endsWith(']')) ||
              ($ctrl.fieldValue.startsWith('{') &&
                $ctrl.fieldValue.endsWith('}'))
            ) {
              if ($ctrl.fieldType == 'dropdown')
                $ctrl.fieldValue = JSON.parse($ctrl.fieldValue).text;

              if ($ctrl.fieldType == 'lookup')
                $ctrl.fieldValue = JSON.parse($ctrl.fieldValue)
                  .map((f) => f.text)
                  .join(', ');
            }

            $ctrl.formValue = validationService.sanitizeString(
              $sce.trustAsHtml(makeLinksClickable($ctrl.fieldValue))
            );
          }
        };

        function makeLinksClickable(fieldValue) {
          switch ($ctrl.fieldType) {
            case 'textArea': {
              //the kendo widget already converts links to HTML links, but it does not add target blank so they do not open in another window
              const linkPattern = new RegExp(/<a href="/g);
              //find them and add in target blank
              return fieldValue.replace(linkPattern, () => {
                return `<a target="_blank" href="`;
              });
            }
            case 'string': {
              //find any url and wrap in a link
              const urlPattern = new RegExp(
                /(?:http:\/\/|https:\/\/|www)[./\w#-]*/g
              );
              return fieldValue.replace(urlPattern, (value) => {
                return `<a target="_blank" href="${value}">${value}</a>`;
              });
            }
            default:
              return fieldValue;
          }
        }

        function setDefaultMessage() {
          switch ($ctrl.fieldType) {
            case 'fileupload':
              $ctrl.formValue = 'No file was uploaded.';
              break;
            case 'table':
              $ctrl.formValue =
                this.flowinglyConstants.tableNoRowMessage.TABLE_NO_ROW;
              break;
            default:
              $ctrl.formValue = 'No information was entered.';
              break;
          }
        }

        function isFieldEmpty() {
          return (
            !$ctrl.fieldValue ||
            $ctrl.fieldValue === 'Please Choose' ||
            $ctrl.fieldValue === 'null' ||
            $ctrl.fieldValue === null ||
            //ignore empty currency field
            $ctrl.fieldValue === '$null'
          );
        }
      }
    ],
    template: `
                <span ng-class ="{'no-value-text': $ctrl.noValueText}" ng-bind-html="$ctrl.formValue"></span>
            `
  });
