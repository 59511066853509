/**
 * @ngdoc type
 * @module flowingly.runner.maintenance
 * @name runnerMaintenanceController
 *
 * @description Controller for Maintenance page.
 */
'use strict';
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular from 'angular';
import { IStateService } from 'angular-ui-router';

angular
  .module('flowingly.runner.login')
  .controller('runnerMaintenanceController', runnerMaintenanceController);

runnerMaintenanceController.$inject = [
  '$state',
  'permissionsService',
  'flowinglyConstants'
];

function runnerMaintenanceController(
  $state: IStateService,
  permissionsService: SharedAngular.PermissionsService,
  flowinglyConstants: SharedAngular.FlowinglyConstants
) {
  if ($state.current.url === 'maintenance') {
    if (
      permissionsService.currentUserHasPermission(
        flowinglyConstants.permissions.ADMINISTRATION_ACCESS
      )
    ) {
      $state.go('app.runner.maintenance.settings');
    } else {
      $state.go('app.runner.flowsactive');
    }
  }
}
