/**
 * @ngdoc type
 * @module flowingly.runner.processmap
 * @name runnerProcessMapController
 *
 * @description Controller for ProcessMap page.
 */
(function () {
  'use strict';

  angular
    .module('flowingly.runner.processmap')
    .controller('runnerProcessMapsController', runnerProcessMapsController);

  runnerProcessMapsController.$inject = [
    'flowListManager',
    'pubsubService',
    'lodashService',
    '$state',
    '$timeout',
    '$scope',
    'APP_CONFIG',
    'sessionService'
  ];
  function runnerProcessMapsController(
    flowListManager,
    pubsubService,
    lodashService,
    $state,
    $timeout,
    $scope,
    APP_CONFIG,
    sessionService
  ) {
    let $ctrl = this;
    $ctrl.hasLoaded = false;
    $ctrl.setNullSelectedCategoryToAllCategory =
      setNullSelectedCategoryToAllCategory;
    $ctrl.onBackButtonClick = onBackButtonClick;
    $ctrl.categoryClicked = selectCategory;
    $ctrl.allCategoriesId = 'all-categories';

    sessionService.onReady(() => {
      $ctrl.allowProcessMap = APP_CONFIG.allowProcessMap;
      if ($ctrl.allowProcessMap) {
        flowListManager.getCategorizedProcessMaps().then((categories) => {
          $ctrl.categories = categories;
          init();
        });
      }
    });

    function init() {
      const processMapSubscriberId = 'flowingly.runner.processmap';
      pubsubService.subscribe(
        'SIGNALR_WORKFLOW_NAME_CHANGED',
        (event, params) => updateFlowModelName(params),
        processMapSubscriberId
      );
      pubsubService.subscribe(
        'SIGNALR_SETUP_CATEGORY_DELETED',
        onCategoryDeleted,
        processMapSubscriberId
      );
      pubsubService.subscribe(
        'SIGNALR_SETUP_FLOW_MODEL_DELETED',
        onFlowModelDeleted,
        processMapSubscriberId
      );
      pubsubService.subscribe(
        'SIGNALR_WORKFLOW_PUBLISHED',
        loadProcessMaps,
        processMapSubscriberId
      );
      pubsubService.subscribe(
        'SIGNALR_WORKFLOW_UNPUBLISHED',
        loadProcessMaps,
        processMapSubscriberId
      );
      pubsubService.subscribe(
        'SIGNALR_ACTOR_DELETED',
        (event, params) => removeDeletedActor(params),
        processMapSubscriberId
      );

      $scope.$on('$destroy', () => {
        pubsubService.unsubscribeAll(processMapSubscriberId);
      });

      if ($ctrl.categories.length === 0) {
        loadProcessMaps().then(() => {
          $ctrl.hasLoaded = true;
        });
      } else {
        selectAllCategory();
        $ctrl.hasLoaded = true;
      }
    }

    function loadProcessMaps() {
      return flowListManager.getCategorizedProcessMaps().then((categories) => {
        $ctrl.categories = categories;
        selectAllCategory();
      });
    }

    function setNullSelectedCategoryToAllCategory() {
      if (!$ctrl.selectedCategory) {
        $ctrl.selectedCategory = {
          name: $ctrl.allCategoriesId,
          flowModels: lodashService.flatMap($ctrl.categories, 'flowModels')
        };
      }
    }

    function onCategoryDeleted() {
      loadProcessMaps();
    }

    function onFlowModelDeleted() {
      loadProcessMaps();
    }

    function updateFlowModelName(jsonParams) {
      if (!Array.isArray($ctrl.categories)) {
        return;
      }

      const params = JSON.parse(jsonParams);
      let updatedCategoryIndex = -1;
      $ctrl.categories.some((category, index) =>
        category.flowModels.some((flowModel) => {
          if (flowModel.id === params.id) {
            flowModel.name = params.name;
            updatedCategoryIndex = index;
            return true;
          }
          return false;
        })
      );
      if (updatedCategoryIndex >= 0) {
        $ctrl.categories[updatedCategoryIndex].flowModels = angular.copy(
          $ctrl.categories[updatedCategoryIndex].flowModels
        );
        selectCategory($ctrl.selectedCategory.name);
      }
    }

    function removeDeletedActor(jsonParams) {
      if (!Array.isArray($ctrl.categories)) {
        return;
      }

      const params = JSON.parse(jsonParams);
      let updatedFlowModels = false;
      $ctrl.categories.forEach((category) => {
        category.flowModels.forEach((flowModel) => {
          if (flowModel.processOwnerName === params.actorName) {
            flowModel.processOwnerName = null;
            updatedFlowModels = true;
          }
        });
      });

      if (updatedFlowModels) {
        $ctrl.categories = angular.copy($ctrl.categories);
        selectCategory($ctrl.selectedCategory.name);
      }
    }

    function onBackButtonClick() {
      $state.go('app.runner.flowsin');
    }

    function selectCategory(categoryName) {
      $ctrl.selectedCategory = $ctrl.categories.find(
        ({ name }) => name === categoryName
      );
      setNullSelectedCategoryToAllCategory();
      setTimeout(highlightSelectedCategory, 1); // Highlight after angular re-renders or it will be lost
    }

    function selectAllCategory() {
      $timeout(() => {
        $ctrl.selectedCategory = null;
        setNullSelectedCategoryToAllCategory();
        highlightSelectedCategory();
      });
    }

    function highlightSelectedCategory() {
      const categoryName = $ctrl.selectedCategory
        ? $ctrl.selectedCategory.name
        : $ctrl.allCategoriesId;
      const selectedCategoryClassName = 'selected-category';

      // First unselect all.
      const allSelected = document.getElementsByClassName(
        selectedCategoryClassName
      );
      if (allSelected) {
        lodashService.forEach(allSelected, (elem) => {
          elem.classList.remove(selectedCategoryClassName);
        });
      }

      // Select the category.
      if (categoryName != null && categoryName !== '') {
        const categoryNameElement = document.getElementById(categoryName);
        if (categoryNameElement) {
          categoryNameElement.classList.toggle(selectedCategoryClassName);
        }
      }
    }
  }
})();
