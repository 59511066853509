/**
 * @ngdoc directive
 * @name flowLibraryList
 * @module flowingly.runner.library
 * @description  This comppnent is used to display the list of flow models
 * @usage
 * ```
     <flow-library-list flow-models="$ctrl.flowModels"></flow-library-list>
 * ```
 * ### Notes
 * See Also:
 * ### Properties
 * #### Inputs
 * * flowModels: the lsit of available flow models (JSON[])
 */

'use strict';
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular, { IFilterDate, IFilterService, IScope } from 'angular';

declare const moment: Moment;
angular.module('flowingly.runner.library').component('flowLibraryList', {
  bindings: {
    flowModels: '<',
    gridNeedResize: '<',
    listUpdated: '&',
    canCreateFlows: '<'
  },
  templateUrl: 'Client/runner.library/runner.library.list.tmpl.html',
  controller: [
    '$scope',
    'validationService',
    'categoryApiService',
    'pubsubService',
    'lodashService',
    '$filter',
    function (
      $scope: IScope,
      validationService: SharedAngular.ValidationService,
      categoryApiService: CategoryApiService,
      pubsubService: SharedAngular.PubSubService,
      lodashService: Lodash,
      $filter: IFilterService
    ) {
      const ALL = 'All';

      const _flowsToRender = [];
      let _categories;
      let _flowOwners;
      let _hasConfidentialFlows;

      const $ctrl = this;

      $ctrl.handleListUpdated = handleListUpdated;

      this.$onInit = function () {
        updateFlowModels($ctrl.flowModels);

        $ctrl.noRows = true;

        _categories = categoryApiService.categories;
        _categories.unshift({ name: ALL, value: '' });

        _flowOwners = lodashService.uniqBy(
          lodashService.map(_flowsToRender, function (flow) {
            return { creatorName: flow.creatorName };
          }),
          'creatorName'
        );

        $ctrl.toolTipOptions = {
          filter: 'td:nth-child(1),td:nth-child(2),td:nth-child(3)',
          position: 'top',
          content: function (e) {
            if (e.target[0].scrollWidth > e.target[0].offsetWidth) {
              return validationService.sanitizeString(e.target[0].innerText);
            } else {
              return '';
            }
          },
          show: function (e) {
            if (this.content[0].scrollWidth < 300) {
              this.popup.element[0].style.width = '300px';
            } else {
              this.popup.element[0].style.width = this.content[0].scrollWidth;
            }

            if (this.content.text() !== '') {
              $('[role="tooltip"]').css('visibility', 'visible');
            }
          },
          hide: function () {
            $('[role="tooltip"]').css('visibility', 'hidden');
          }
        };

        $ctrl.options = {
          dataSource: {
            data: _flowsToRender,
            pageSize: 15,
            schema: {
              model: {
                fields: {
                  lastEditedDate: { type: 'date' }
                }
              }
            },
            sort: {
              field: 'lastEditedDate',
              dir: 'desc'
            }
          },
          noRecords: {
            template: '<flow-library-no-results></flow-library-no-results>'
          },
          scrollable: true,
          pageable: true,
          sortable: true,
          resizable: true,
          filterable: {
            mode: 'row',
            operators: {
              date: {
                gte: 'Is after or equal to',
                lte: 'Is before or equal to'
              }
            }
          },
          columns: getColumns()
        };

        pubsubService.subscribe(
          'SIGNALR_FLOW_MODEL_STATUS_CHANGED',
          onFlowModelStatusChanged,
          'runner.library.list.component'
        );
        pubsubService.subscribe(
          'SIGNALR_NEW_FLOW_MODEL_SAVED',
          onNewFlowModelSaved,
          'runner.library.list.component'
        );
        pubsubService.subscribe(
          'SIGNALR_SETUP_FLOW_MODEL_DELETED',
          onFlowModelDeleted,
          'runner.library.list.component'
        );
        pubsubService.subscribe(
          'SIGNALR_WORKFLOW_PUBLISHED',
          onFlowModelStatusChanged,
          'runner.library.list.component'
        );
        pubsubService.subscribe(
          'SIGNALR_WORKFLOW_UNPUBLISHED',
          onFlowModelStatusChanged,
          'runner.library.list.component'
        );
      };

      this.$onChanges = function (changesObj) {
        // handle new models
        if (changesObj.flowModels && changesObj.flowModels.currentValue) {
          updateFlowModels(changesObj.flowModels.currentValue);
          if ($scope.libraryGrid) {
            $scope.libraryGrid.dataSource.read();
            $scope.libraryGrid.refresh();
          }
          $ctrl.noRows = !$ctrl.flowModels[0];
        }

        // handle resize request
        if (changesObj.gridNeedResize) {
          if (changesObj.gridNeedResize.currentValue !== $ctrl.doGridResize) {
            $ctrl.doGridResize = changesObj.gridNeedResize.currentValue;
          }
        }
      };

      /// PRIVATE METHODS //////////////////////////////////
      function updateFlowModels(flowModels) {
        angular.copy(flowModels, _flowsToRender);

        _hasConfidentialFlows = false;
        _flowsToRender.forEach((flow) => {
          /*
           * Drop the hours, minutes, and seconds part of the date so that
           * when kendo performs a GTE and an LTE, it doesn't calculate the minutes as
           * part of filter. This is because of how dates are compared in general.
           *                                                                  - Cassey
           */
          flow.lastEditedDate = $filter<IFilterDate>('utcToLocal')(
            flow.lastEditedDate,
            'yyyy-MM-dd'
          );
          flow.publishType = flow.workflowType
            ? flow.workflowType
            : flow.publishType;
          if (flow.isConfidential) {
            _hasConfidentialFlows = true;
          }
        });
      }

      function handleListUpdated(needResizeGrid) {
        $ctrl.listUpdated({ needResizeGrid: needResizeGrid });
      }

      // When a flow model is added we need to get it and also may need to increase the height of the grid
      function onNewFlowModelSaved(event, message) {
        handleListUpdated(true);
      }

      // When a flow model is deleted we need to remove it and decrease the height of the grid
      function onFlowModelDeleted(event, message) {
        handleListUpdated(true);
      }

      // When the status of a flow model is changed we need to show the changed status of the Flow Model in the Flow Library
      function onFlowModelStatusChanged(event, message) {
        handleListUpdated(false); // As this is an existing Flow Model we do not need to resize the grid
      }

      function getColumns() {
        //Note: field needs to match the name (and case) of the returned data
        return [
          {
            field: 'name',
            title: 'Name',
            template:
              '{{dataItem.name}}<unpublished-changes-message item="dataItem"></unpublished-changes-message>',
            filterable: {
              cell: {
                operator: 'contains',
                suggestionOperator: 'contains',
                showOperators: false,
                template: function (args) {
                  args.element.kendoAutoComplete({
                    dataSource: new kendo.data.DataSource({
                      data: $ctrl.flowModels
                    }),
                    dataTextField: 'name', //enables filtering on specified column
                    placeholder: 'Search by Name', //placeholder for text input
                    valuePrimitive: true,
                    filter: 'contains' //default autocomplete uses 'starts with'
                  });
                }
              }
            }
          },
          {
            field: 'lastEditedDate',
            title: 'Last Updated',
            sortable: true,
            template: ({ lastEditedDate }) => {
              return (
                '<span>' +
                moment(lastEditedDate).format('DD/MM/YYYY') +
                '</span>'
              );
            },
            filterable: {
              cell: {
                template: function (args) {
                  args.element
                    .kendoDatePicker({
                      placeholder: 'Search by last updated date',
                      format: 'dd/MM/yyyy'
                    })
                    .attr('placeholder', 'Search by last updated date');
                }
              }
            }
          },
          {
            field: 'creatorName',
            title: 'Created By',
            filterable: {
              cell: {
                operator: 'contains', //default filtering uses 'equal to' operator, we want to use contains.
                showOperators: false,
                template: function (args) {
                  args.element.kendoComboBox({
                    dataSource: _flowOwners,
                    dataTextField: 'creatorName', //enables filtering on specified
                    dataValueField: 'value', //this needs to be here for the selected value to display in the select list
                    placeholder: 'Search by creator', //placeholder for text input
                    valuePrimitive: true //default autocomplete uses 'starts with'
                  });
                }
              }
            }
          },
          {
            field: 'publishType',
            title: 'Type',
            filterable: {
              cell: {
                showOperators: false,
                template: function (args) {
                  const publishTypes = [
                    { name: 'Process Map' },
                    { name: 'Process Map Component' },
                    { name: 'Workflow' },
                    { name: 'Workflow Component' }
                  ];
                  if (_hasConfidentialFlows) {
                    publishTypes.push({ name: 'Confidential Workflow' });
                  }
                  args.element.kendoComboBox({
                    dataSource: publishTypes,
                    dataTextField: 'name', //enables filtering on specified
                    dataValueField: 'value', //this needs to be here for the selected value to display in the select list
                    placeholder: 'Search by Type', //placeholder for text input
                    valuePrimitive: true //default autocomplete uses 'starts with'
                  });
                }
              }
            }
          },
          {
            field: 'category',
            title: 'Category',
            filterable: {
              cell: {
                showOperators: false,
                template: function (args) {
                  args.element.kendoComboBox({
                    dataSource: _categories,
                    dataTextField: 'name',
                    dataValueField: 'value', //this needs to be here for the selected value to display in the select list
                    placeholder: 'Search by Category'
                  });
                }
              }
            }
          },
          {
            field: 'publishedTo',
            title: 'Published To',
            filterable: false
          },
          {
            field: 'status',
            title: 'Status',
            template:
              '<library-status-badge status="dataItem.status"></library-status-badge>',
            filterable: {
              cell: {
                showOperators: false,
                template: function (args) {
                  args.element.kendoComboBox({
                    dataSource: [
                      { text: 'All', value: '' },
                      { text: 'Draft' },
                      { text: 'Published' }
                    ],
                    dataTextField: 'text',
                    dataValueField: 'value', //this needs to be here for the selected value to display in the select list
                    placeholder: 'Search by Status'
                  });
                }
              }
            }
          },
          {
            title: 'Actions',
            width: '165px',
            filterable: false,
            template:
              "<flow-library-list-actions flow='dataItem' can-create-flows='$ctrl.canCreateFlows' category='dataItem.category' on-flow-list-updated='$ctrl.handleListUpdated(needResizeGrid)'></flow-library-list-actions>"
          }
        ];
      }
    }
  ]
});
