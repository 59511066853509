import React, { useState } from 'react';
import ProcessMap from './ProcessMap/ProcessMap';
import ProcessDetails from './ProcessDetails/ProcessDetails';
import Feedback from './Feedback/Feedback';
import Header from './Header/Header';
import { useService } from '@Client/runner.hooks/useService';
import { Box } from '@mui/material';
import CustomTabs from '../CustomTab/CustomTabs';
import CustomTabPanel from '../CustomTab/CustomTabPanel';
import { SharedAngular } from '@Client/@types/sharedAngular';
import ProcessMapType from '@Client/runner.processmapsv2/@types/processMap';

type Props = {
  processMapId: string;
  onSelectedNodeDataChange: (data: unknown) => void;
};

const LeftPanel = (props: Props) => {
  const { processMapId, onSelectedNodeDataChange } = props;
  const [selectedTab, setSelectedTab] = useState(0);
  const [processMap, setProcessMap] = useState<ProcessMapType>({
    Id: '',
    category: '',
    description: '',
    processOwner: '',
    isFlowModelOwner: false,
    processReviewDate: '',
    objective: '',
    background: '',
    processInput: '',
    processOutput: ''
  });
  const [flowComments, setFlowComments] = useState([]);
  const [commentTargetType, setCommentTargetType] = useState(null);

  const appConfig = useService<AppConfig>('APP_CONFIG');
  const commentApiService =
    useService<SharedAngular.CommentApiService>('commentApiService');
  const avatarService =
    useService<SharedAngular.AvatarService>('avatarService');
  const flowinglyConstants =
    useService<SharedAngular.FlowinglyConstants>('flowinglyConstants');
  const lodashService = useService<Lodash>('lodashService');

  const shouldFeedbackBeVisible = (editableByCurrentUser: boolean) => {
    return (
      appConfig.flowModelFeedbackVisibility.toLowerCase() === 'all' ||
      (appConfig.flowModelFeedbackVisibility.toLowerCase() === 'flowowners' &&
        editableByCurrentUser)
    );
  };

  const loadComments = () => {
    commentApiService
      .getFlowComments(
        flowinglyConstants.commentTargetType.FLOW_MODEL,
        processMapId,
        false
      )
      .then((data) => {
        const flowComments = data;
        lodashService.forEach(flowComments, (comment) => {
          comment.avatarUrl = avatarService.getAvatarUrl(comment.userId);
        });
        setFlowComments(flowComments);
      });
  };

  const handleChange = (
    _event: Event,
    newValue: React.SetStateAction<number>
  ) => {
    setSelectedTab(newValue);
    onSelectedNodeDataChange(null);
  };

  const handleSelectedNodeDataChange = (data) => {
    onSelectedNodeDataChange(data);
  };

  const handleProcessMapLoad = (data) => {
    setProcessMap(data);
    if (shouldFeedbackBeVisible(data.editableByCurrentUser)) {
      loadComments();
      setCommentTargetType(flowinglyConstants.commentTargetType.FLOW_MODEL);
    }
  };

  const processMapTabIndex = 0;
  const processDetailsTabIndex = 1;
  const feedbackTabIndex = 2;

  return (
    <Box width={'100%'} height={'100%'}>
      <Header
        processOwner={processMap.processOwner}
        processDescription={processMap.description}
      />
      <CustomTabs
        value={selectedTab}
        onChange={handleChange}
        tabLabels={[
          'Process Map',
          'Process Details',
          `Feedback (${flowComments.length})`
        ]}
        tabProps={{
          indicatorColor: 'primary',
          textColor: 'primary'
        }}
      />
      <CustomTabPanel value={selectedTab} index={processMapTabIndex}>
        <ProcessMap
          isVisible={selectedTab === processMapTabIndex}
          processMapId={processMapId}
          onSelectedNodeDataChange={handleSelectedNodeDataChange}
          onProcessMapLoad={handleProcessMapLoad}
        />
      </CustomTabPanel>
      <CustomTabPanel value={selectedTab} index={processDetailsTabIndex}>
        <ProcessDetails
          Id={processMap.Id}
          isFlowModelOwner={processMap.isFlowModelOwner}
          processOwner={processMap.processOwner}
          category={processMap.category}
          processReviewDate={processMap.processReviewDate}
          description={processMap.description}
          objective={processMap.objective}
          background={processMap.background}
          processInput={processMap.processInput}
          processOutput={processMap.processOutput}
        />
      </CustomTabPanel>
      <CustomTabPanel value={selectedTab} index={feedbackTabIndex}>
        <Feedback
          commentList={flowComments}
          commentTargetId={processMapId}
          commentTargetType={commentTargetType}
        />
      </CustomTabPanel>
    </Box>
  );
};

export default LeftPanel;
