(function () {
  'use strict';

  angular.module('flowingly.runner.public-form').config(config);

  // Inject the dependencies.
  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider.state('app.public-form', {
      url: '/form/{id}',
      params: {
        title: 'Flowingly Public Form',
        formType: 'flowingly_public_form'
      },
      controller: 'runnerPublicFormController',
      controllerAs: '$ctrl',
      templateUrl: 'Client/runner.public-form/runner.public-form.tmpl.html'
    });
  }
})();
