/**
 * @ngdoc directive
 * @name flowHistoryNudge
 * @module flowingly.runner.flow
 * @description This component is used to display the step nudged history information.
 * @usage
 * ```
     <flow-history-nudge step="step" is-mobile="$ctrl.isMobile"></flow-history-nudge>
 * ``` 
 * ### Notes
 * See Also: https://bizflo.atlassian.net/wiki/display/SCRUM/Runner+-+Nudge+user
 * ### Properties
 * #### Inputs
 * * step: the step data (JSON) 
 * * isMobile: show mobile view if set
 */

'use strict';
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular from 'angular';

angular.module('flowingly.runner.flow').component('flowHistoryNudge', {
  bindings: {
    step: '<',
    isMobile: '<' //show mobile view if set
  },
  controller: [
    'pubsubService',
    'sessionService',
    'avatarService',
    'flowApiService',
    'runnerFlowsFormatter',
    function (
      pubsubService: SharedAngular.PubSubService,
      sessionService: SharedAngular.SessionService,
      avatarService: SharedAngular.AvatarService,
      flowApiService: FlowApiService,
      runnerFlowsFormatter: RunnerFlowsFormatterService
    ) {
      const $ctrl = this;
      $ctrl.nudgeHistoryList = [];

      this.$onInit = function () {
        pubsubService.subscribe(
          'STEP_NEW_NUDGE',
          onStepNewNudge,
          'flowingly.runner.flow.flowHistoryNudge'
        );
        getStepNudgeHistoryList();
      };

      function onStepNewNudge(event, stepNudgeHistory) {
        // Only care for current step
        if (stepNudgeHistory && stepNudgeHistory.stepId === $ctrl.step.Id) {
          // Add data to collection
          runnerFlowsFormatter.formatStepNudgeHistory(
            stepNudgeHistory,
            $ctrl.step
          );
          /* For newly added nudge history, the moment text always 'A Few Seconds Ago'. 
                               Now manually add newly data to array rather than call API as we don't want to refresh 
                               already displayed history data for moment text. That involve much more board discussion if 
                               wish to refresh moment text in other places, as may affect user experence as such, question 
                               like do we want to refresh current flow only, or all the flows, both to do and flows I'm In, 
                               flow category may change as becomes overdue at the time refreshing etc...
                            */
          stepNudgeHistory.momentNudged = 'A Few Seconds Ago';
          stepNudgeHistory.nudgedByUserAvatarUrl = avatarService.getAvatarUrl(
            sessionService.getUser().id
          );
          $ctrl.nudgeHistoryList.push(stepNudgeHistory);
        }
      }

      function getStepNudgeHistoryList() {
        return flowApiService
          .getStepNudgeHistoryList(true, $ctrl.step.Id)
          .then((data) => {
            if (data) {
              $ctrl.nudgeHistoryList =
                runnerFlowsFormatter.formatStepNudgeHistoryList(
                  data,
                  $ctrl.step
                );
            }
          });
      }
    }
  ],
  templateUrl:
    'Client/runner.flow/runner.flow.history/runner.flow.history.nudge.tmpl.html'
});
