(function () {
  'use strict';

  // Register the application's routes configuration.
  angular.module('flowingly.runner.setup').config(config);

  // Inject the dependencies.
  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider
      .state('app.runner.setup.databases', {
        url: '/databases',
        params: { flowId: null, title: 'Setup - Databases' },
        views: {
          childcontent: {
            controllerAs: '$ctrl',
            controller: [
              '$state',
              'permissionsService',
              'databaseApiService',
              'flowinglyConstants',
              function (
                $state,
                permissionsService,
                databaseApiService,
                flowinglyConstants
              ) {
                if (
                  !permissionsService.currentUserHasPermission(
                    flowinglyConstants.permissions.SETUP_DATABASE_ACCESS
                  )
                ) {
                  $state.go('app.runner.flowsactive');
                }

                this.databases = databaseApiService.databases;
              }
            ],
            template: `<flow-databases databases="$ctrl.databases"></flow-databases>`,
            resolve: {
              databases: [
                'databaseApiService',
                function (databaseApiService) {
                  return databaseApiService.getDatabases();
                }
              ]
            }
          }
        }
      })
      .state('app.runner.create-database', {
        url: 'create-database',
        params: { title: 'Setup - Create Database' },
        controller: 'runnerSetupCreateDatabaseController',
        controllerAs: '$ctrl',
        templateUrl:
          'Client/runner.setup/runner.setup.databases/runner.setup.create-database.tmpl.html'
      })
      .state('app.runner.edit-database', {
        url: 'edit-database/{dbName}',
        params: { title: 'Setup - Edit Database' },
        controller: 'runnerSetupEditDatabaseController',
        controllerAs: '$ctrl',
        templateUrl:
          'Client/runner.setup/runner.setup.databases/runner.setup.edit-database.tmpl.html'
      });
  }
})();
