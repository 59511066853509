/**
 * @ngdoc directive
 * @name flowHistoryContainer
 * @module flowingly.runner.flow
 * @description  This comppnent is used to gruop the historical content that is displayed when the flow is expanded.
 * @usage
 * ```
   <flow-history-container flow="$ctrl.flow"></flow-history-container>
 * ``` 
 * ### Notes
 * See Also: https://bizflo.atlassian.net/wiki/display/TECH/Angular+Flow+Components
 * ### Properties
 * #### Inputs
 * * flow: the flow data to display (JSON)
 * * isMobile: show mobile view if set
 */

'use strict';
import angular, { IScope, IWindowService } from 'angular';

///
/// This component is used to group the historical content that is displayed when the flow is expanded.
///
/// <flow-history-container flow="$ctrl.flow"></flow-history-container>
///
/// See Also: https://bizflo.atlassian.net/wiki/display/TECH/Angular+Flow+Components

angular.module('flowingly.runner.flow').component('flowHistoryContainer', {
  bindings: {
    flow: '<',
    isMobile: '<', //show mobile view if set
    userId: '<', //userid of the logged in user
    onCommentsClick: '&' //show comments tab on click
  },
  controller: [
    '$scope',
    '$window',
    function ($scope: IScope, $window: IWindowService) {
      const FIXED_TOTAL_HEIGHT_LEFT_HISTORY = 224,
        FIXED_TOTAL_HEIGHT_LEFT_COMMENTS = 196,
        FIXED_TOTAL_HEIGHT_RIGHT = 106;

      $scope.init = function () {
        setDynamicMaxHeight();
        $window.addEventListener('resize', setDynamicMaxHeight);
      };

      function setDynamicMaxHeight() {
        const wih = $window.innerHeight,
          dh_flow_history_active = wih - FIXED_TOTAL_HEIGHT_LEFT_HISTORY + 'px',
          dh_flow_comments_active =
            wih - FIXED_TOTAL_HEIGHT_LEFT_COMMENTS + 'px',
          dh_flow_right_pane = wih - FIXED_TOTAL_HEIGHT_RIGHT + 'px';

        //console.log(wih+'px');

        if (wih <= 550) {
          $('.flow-comments-list').css('max-height', 'calc(100% - 196px)');
          $('.mention-contents').css('height', '50px');
        } else {
          $('.flow-comments-list').css('max-height', 'calc(100% - 243px)');
          $('.mention-contents').css('height', '100px');
        }

        $('.flow-history-tab-active-dynamic-height').css(
          'max-height',
          dh_flow_history_active
        );
        $('.flow-comments-tab-active-dynamic-height').css(
          'max-height',
          dh_flow_comments_active
        );
        $('.pane-right').css('max-height', dh_flow_right_pane);
      }

      $scope.init();
    }
  ],
  templateUrl:
    'Client/runner.flow/runner.flow.history/runner.flow.history.container.tmpl.html'
});
