/**
 * @ngdoc service
 * @name maintenanceService
 * @module flowingly.runner.services
 *
 * @description A service responsible solely for validating flowmodel, getting all published flow models
 *
 *
 */

'use strict';
import { SharedAngular } from '@Client/@types/sharedAngular';
import { ModelerValidation } from '@Shared.Angular/flowingly.modeler.validation/@types/services';
import angular from 'angular';

angular
  .module('flowingly.runner.services')
  .factory('maintenanceService', maintenanceService);

maintenanceService.$inject = [
  '$http',
  'APP_CONFIG',
  'modelerValidationService',
  'lodashService',
  'guidService',
  'flowinglyConstants'
];

function maintenanceService(
  $http: angular.IHttpService,
  appConfig: AppConfig,
  modelerValidationService: ModelerValidation.ModelerValidationService,
  lodashService: Lodash,
  guidService: SharedAngular.GuidService,
  flowinglyConstants: SharedAngular.FlowinglyConstants
) {
  const service = {
    getBusinesses: getBusinesses,
    getBusinessSettings: getBusinessSettings,
    getUsersWithOptions: getUsersWithOptions,
    updateUserStatuses: updateUserStatuses,
    getFlow: getFlow,
    cancelFlow: cancelFlow,
    markFlowAsCompleted: markFlowAsCompleted,
    getPublishedFlows: getPublishedFlows,
    updateBusinessSetting: updateBusinessSetting,
    validateWorkFlow: validateWorkFlow,
    workFlowDetails: workFlowDetails,
    deleteStep: deleteStep,
    resubmitStep: resubmitStep,
    getEmailMethods: getEmailMethods,
    sendEmail: sendEmail,
    clearCachedSettings: clearCachedSettings
  };

  return service;

  function clearCachedSettings() {
    return $http.get(appConfig.apiBaseUrl + 'business/reloadSettings').then(
      function (response) {
        return response.status === 200;
      },
      function () {
        return false;
      }
    );
  }

  function updateBusinessSetting(businessId, name, value) {
    const urlPath = `business/${businessId}/settings?settingName=${encodeURIComponent(
      name
    )}`;
    return $http
      .put(appConfig.apiBaseUrl + urlPath, JSON.stringify(value))
      .then(
        function (response) {
          return response.status === 200;
        },
        function () {
          return false;
        }
      );
  }

  function getPublishedFlows(businessId) {
    ///
    /// get all published flows models
    ///
    if (!businessId) businessId = guidService.empty();
    return $http
      .get<IResponseData>(
        appConfig.apiBaseUrl + 'modeler/publishedworkflows/' + businessId
      )
      .then(function (response) {
        angular.copy(response.data.dataModel, service.startFlows);
        return response.data.dataModel;
      });
  }

  function getBusinessSettings(businessId) {
    return $http
      .get(appConfig.apiBaseUrl + `business/${businessId}/settings`)
      .then(function (response) {
        return response.data;
      });
  }

  function workFlowDetails(businessId, flowId) {
    ///
    /// get all published flows models
    ///
    return $http
      .get(
        appConfig.apiBaseUrl +
          'modeler/workflowdetail/' +
          businessId +
          '/' +
          flowId
      )
      .then(function (response) {
        return response.data;
      });
  }

  function validateWorkFlow(businessId, flowId) {
    return workFlowDetails(businessId, flowId).then((workflow) => {
      if (workflow.Nodes.length > 0) {
        lodashService.forEach(workflow.Nodes, function (node) {
          if (typeof node.Card === 'object') {
            return;
          }
          node.Card = JSON.parse(node.Card);
        });
        if (
          workflow.PublishType ===
          flowinglyConstants.flowModelPublishType.WORKFLOW
        ) {
          return modelerValidationService
            .validateWorkFlow(workflow, appConfig)
            .then((errors) => {
              if (!appConfig.disableXssValidation) {
                return modelerValidationService
                  .validateWorkflowPassXssCheck(workflow)
                  .then(
                    () => {
                      return errors;
                    },
                    () => {
                      errors.push({
                        message: 'The workflow failed at XSS validation',
                        isServerError: true
                      });
                      return errors;
                    }
                  );
              } else {
                return errors;
              }
            });
        } else {
          return [];
        }
      } else {
        return [
          {
            message: "The workflow does not have 'Nodes' data",
            isServerError: true
          }
        ];
      }
    });
  }

  function getBusinesses() {
    return $http
      .get(appConfig.apiBaseUrl + 'business')
      .then(function (response) {
        return response.data;
      });
  }

  function getUsersWithOptions(options, businessId) {
    const defaultOpts = {
      take: 100,
      skip: 0,
      page: 1,
      pageSize: 100,
      group: []
    };
    options = lodashService.defaults(options, defaultOpts);
    options.businessId = businessId;
    return $http
      .post<{ users: unknown }>(
        appConfig.apiBaseUrl + 'maintenance/getUsersWithOptions',
        options,
        {
          noSpinner: true
        }
      )
      .then(function (response) {
        angular.copy(response.data.users, service.users);
        return response.data;
      }, errorResponse);
  }

  function updateUserStatuses(
    userId,
    businessId,
    managerId,
    inviteUser,
    deleteUser,
    active,
    changeRequestId
  ) {
    const request = {
      userId: userId,
      businessId: businessId,
      managerId: managerId,
      inviteUser: inviteUser,
      delete: deleteUser,
      active: active,
      changeRequestId: changeRequestId
    };
    return $http
      .post(`${appConfig.apiBaseUrl}maintenance/updateUserStatuses`, request)
      .then(function (response) {
        return response.data;
      }, errorResponse);
  }

  function getFlow(businessId, identifier) {
    return $http
      .get(
        `${appConfig.apiBaseUrl}maintenance/businesses/${businessId}/flows/${identifier}`
      )
      .then(function (response) {
        return response.data;
      }, errorResponse);
  }

  function cancelFlow(identifier, changeRequestId) {
    const request = {
      Identifier: identifier,
      ChangeRequestId: changeRequestId
    };
    return $http
      .post(`${appConfig.apiBaseUrl}maintenance/withdrawflow`, request)
      .then(function (response) {
        return response.data;
      }, errorResponse);
  }

  function markFlowAsCompleted(identifier, changeRequestId) {
    const request = {
      Identifier: identifier,
      ChangeRequestId: changeRequestId
    };
    return $http
      .post(`${appConfig.apiBaseUrl}maintenance/markflowascompleted`, request)
      .then(function (response) {
        return response.data;
      }, errorResponse);
  }

  function deleteStep(stepId, flowId, businessId) {
    return $http
      .delete(
        `${appConfig.apiBaseUrl}maintenance/businesses/${businessId}/flows/${flowId}/steps/${stepId}`
      )
      .then(function (response) {
        return response.data;
      }, errorResponse);
  }

  function resubmitStep(stepId, flowId, businessId) {
    return $http
      .put(
        `${appConfig.apiBaseUrl}maintenance/businesses/${businessId}/flows/${flowId}/steps/${stepId}`,
        undefined
      )
      .then(function (response) {
        return response.data;
      }, errorResponse);
  }

  function getEmailMethods() {
    return $http
      .get(`${appConfig.apiBaseUrl}maintenance/email/send`)
      .then(function (response) {
        return response.data;
      }, errorResponse);
  }

  function sendEmail(emailMethod) {
    return $http
      .post(
        `${appConfig.apiBaseUrl}maintenance/email/send/${emailMethod}`,
        undefined
      )
      .then(function (response) {
        return response.data;
      }, errorResponse);
  }

  function errorResponse() {
    return { success: false, errorMessage: 'An error occurred' };
  }
}

export type MaintenanceServiceType = ReturnType<typeof maintenanceService>;
