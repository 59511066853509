/**
 * @ngdoc directive
 * @name flowStatus
 * @module flowingly.runner.flow
 * @description  This comppnent is used to display the status (progress) of a flow. 
 * @usage
 * ```
    <flow-status></flow-status>
 * ``` 
 * ### Notes
 * See Also: https://bizflo.atlassian.net/wiki/display/TECH/Angular+Flow+Components
 * ### Properties
 * #### Inputs
 * * flow: the flow data to display (JSON)
 * * isMobile: show mobile view if set
 */

'use strict';
import angular from 'angular';

angular.module('flowingly.runner.flow').component('flowStatus', {
  bindings: {
    priority: '<', //0 = none, 1 = low, 2 = high
    progress: '<' //width of progress bar (0 to 100)%
  },
  controller: [
    function () {
      const $ctrl = this;

      $ctrl.$onChanges = function (changes) {
        if (changes.priority && changes.priority.currentValue) {
          switch (changes.priority.currentValue) {
            case 0:
              break;

            case 1:
              $ctrl.priorityLabel = 'Low priorty';
              break;

            case 2:
              $ctrl.priorityLabel = 'High priorty';
              break;
          }
        } else if (changes.progress && changes.progress.currentValue) {
          $ctrl.progress = changes.progress.currentValue;
          $ctrl.progressAlt = `${$ctrl.progress}%`;
        }
      };
    }
  ],
  controllerAs: '$ctrl',
  templateUrl:
    'Client/runner.flow/runner.flow.status/runner.flow.status.component.tmpl.html'
});
