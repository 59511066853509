/**
 * @ngdoc module
 * @name flowingly.runner.setup
 * @module flowingly.runner.setup
 * @description This module is used to display the setup area
 */
const m = angular.module('flowingly.runner.setup', ['kendo.directives']);
m.run([
  '$templateCache',
  function ($templateCache) {
    $templateCache.put(
      'Client/runner.setup/runner.setup.tabs.component.tmpl.html',
      require('./runner.setup.tabs.component.tmpl.html').default
    );

    $templateCache.put(
      'Client/runner.setup/runner.setup.roles/runner.setup.roles.tmpl.html',
      require('./runner.setup.roles/runner.setup.roles.tmpl.html').default
    );

    $templateCache.put(
      'Client/runner.setup/runner.setup.roles/runner.setup.roles.list.tmpl.html',
      require('./runner.setup.roles/runner.setup.roles.list.tmpl.html').default
    );

    $templateCache.put(
      'Client/runner.setup/runner.setup.roles/runner.setup.roles.list.actions.tmpl.html',
      require('./runner.setup.roles/runner.setup.roles.list.actions.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.setup/runner.setup.roles/runner.setup.roles.header.tmpl.html',
      require('./runner.setup.roles/runner.setup.roles.header.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.setup/runner.setup.roles/runner.setup.roles.dialog.tmpl.html',
      require('./runner.setup.roles/runner.setup.roles.dialog.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.setup/runner.setup.categories/runner.setup.categories.tmpl.html',
      require('./runner.setup.categories/runner.setup.categories.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.setup/runner.setup.categories/runner.setup.category.dialog.tmpl.html',
      require('./runner.setup.categories/runner.setup.category.dialog.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.setup/runner.setup.categories/runner.setup.category.list.actions.tmpl.html',
      require('./runner.setup.categories/runner.setup.category.list.actions.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.setup/runner.setup.categories/runner.setup.category.list.tmpl.html',
      require('./runner.setup.categories/runner.setup.category.list.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.setup/runner.setup.databases/runner.setup.create-database.tmpl.html',
      require('./runner.setup.databases/runner.setup.create-database.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.setup/runner.setup.databases/runner.setup.databases.dialog.add.tmpl.html',
      require('./runner.setup.databases/runner.setup.databases.dialog.add.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.setup/runner.setup.databases/runner.setup.databases.dialog.edit.tmpl.html',
      require('./runner.setup.databases/runner.setup.databases.dialog.edit.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.setup/runner.setup.databases/runner.setup.databases.list.actions.tmpl.html',
      require('./runner.setup.databases/runner.setup.databases.list.actions.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.setup/runner.setup.databases/runner.setup.databases.list.tmpl.html',
      require('./runner.setup.databases/runner.setup.databases.list.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.setup/runner.setup.databases/runner.setup.databases.tmpl.html',
      require('./runner.setup.databases/runner.setup.databases.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.setup/runner.setup.databases/runner.setup.edit-database.tmpl.html',
      require('./runner.setup.databases/runner.setup.edit-database.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.setup/runner.setup.delete/runner.setup.delete.component.warning.dialog.tmpl.html',
      require('./runner.setup.delete/runner.setup.delete.component.warning.dialog.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.setup/runner.setup.delete/runner.setup.delete.dialog.tmpl.html',
      require('./runner.setup.delete/runner.setup.delete.dialog.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.setup/runner.setup.delete/runner.setup.delete.search.tmpl.html',
      require('./runner.setup.delete/runner.setup.delete.search.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.setup/runner.setup.delete/runner.setup.delete.warnings.tmpl.html',
      require('./runner.setup.delete/runner.setup.delete.warnings.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.setup/runner.setup.delete/runner.setup.users.stepTasks.dialog.tmpl.html',
      require('./runner.setup.delete/runner.setup.users.stepTasks.dialog.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.setup/runner.setup.teams/runner.setup.teams.dialog.tmpl.html',
      require('./runner.setup.teams/runner.setup.teams.dialog.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.setup/runner.setup.teams/runner.setup.teams.list.actions.tmpl.html',
      require('./runner.setup.teams/runner.setup.teams.list.actions.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.setup/runner.setup.teams/runner.setup.teams.list.tmpl.html',
      require('./runner.setup.teams/runner.setup.teams.list.tmpl.html').default
    );

    $templateCache.put(
      'Client/runner.setup/runner.setup.teams/runner.setup.teams.tmpl.html',
      require('./runner.setup.teams/runner.setup.teams.tmpl.html').default
    );

    $templateCache.put(
      'Client/runner.setup/runner.setup.tmpl.html',
      require('./runner.setup.tmpl.html').default
    );

    $templateCache.put(
      'Client/runner.setup/runner.setup.users/add.users/runner.setup.users.add.tmpl.html',
      require('./runner.setup.users/add.users/runner.setup.users.add.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.setup/runner.setup.users/runner.setup.users.dialog.delegation-tab.tmpl.html',
      require('./runner.setup.users/runner.setup.users.dialog.delegation-tab.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.setup/runner.setup.users/runner.setup.users.dialog.profile-tab.tmpl.html',
      require('./runner.setup.users/runner.setup.users.dialog.profile-tab.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.setup/runner.setup.users/runner.setup.users.dialog.teams-tab.tmpl.html',
      require('./runner.setup.users/runner.setup.users.dialog.teams-tab.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.setup/runner.setup.users/runner.setup.users.dialog.tmpl.html',
      require('./runner.setup.users/runner.setup.users.dialog.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.setup/runner.setup.users/runner.setup.users.list.actions.tmpl.html',
      require('./runner.setup.users/runner.setup.users.list.actions.tmpl.html')
        .default
    );

    $templateCache.put(
      'Client/runner.setup/runner.setup.users/runner.setup.users.list.tmpl.html',
      require('./runner.setup.users/runner.setup.users.list.tmpl.html').default
    );

    $templateCache.put(
      'Client/runner.setup/runner.setup.users/runner.setup.users.tmpl.html',
      require('./runner.setup.users/runner.setup.users.tmpl.html').default
    );
  }
]);
