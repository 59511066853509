/**
 * @ngdoc directive
 * @name flowStartList
 * @module flowingly.runner.start
 * @description  This comppnent is used to group a list of flowmodels that can be started.
 *               It is responsible for opening / closing flowmodels when one is clicked.
 *               It is also (currently) responsible for switching between mobile and desktop views.
 * @usage
 * ```
     <flow-start-list flow-models="$ctrl.category.flowModels" is-mobile="isMobile"></flow-start-list>
 * ``` 
 * ### Notes
 * See Also: https://bizflo.atlassian.net/wiki/display/TECH/Angular+Start+Flow+Components
 * ### Properties
 * #### Inputs
 * * flowmodels: list of available flowmodels to start
 * * isMobile: show mobile view if set
 */

'use strict';
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular, { IScope } from 'angular';

angular.module('flowingly.runner.flow.start').component('flowStartList', {
  bindings: {
    flowModels: '<',
    isMobile: '<'
  },
  controller: [
    '$scope',
    'flowsUtilityService',
    function (
      $scope: IScope,
      flowsUtilityService: SharedAngular.FlowsUtilityService
    ) {
      const $ctrl = this;

      this.$onInit = function () {
        $ctrl.flowModels = flowsUtilityService.orderItemsAlphabetically(
          $ctrl.flowModels
        );
      };
    }
  ],
  controllerAs: '$ctrl',
  templateUrl:
    'Client/runner.flow.start/runner.flow.start.list.component.tmpl.html'
});
