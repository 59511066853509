/**
 * @name templateCard
 * @module flowingly.runner.templates
 * @description Displays a Template Library Card
 * 
 * @usage
 * ```
    <template-card></template-card>
 * ```
 */
'use strict';

import angular from 'angular';

export default class TemplateCardComponentController {
  private colorClass: string;
  private displayIcon: string;
  private iconTitle: string;

  // Bindings
  private categoryName: any;
  private public: boolean;
  private icon: string;

  $onChanges(changes) {
    if (
      (changes.categoryName || changes.public || changes.icon) &&
      this.categoryName
    ) {
      this.generateColorClass(this.categoryName);
      this.displayIcon = this.public ? this.icon : 'fa-eye-slash';
      this.iconTitle = this.public
        ? this.categoryName
        : 'This Template is not public - Users cannot see it';
    }
  }

  generateColorClass(source: string) {
    const classPrefix = 'template-category-';
    if (!source || source.length === 0) {
      this.colorClass = classPrefix + '8';
      return;
    }

    const aCharCode = 'a'.charCodeAt(0);
    const zCharCode = 'z'.charCodeAt(0);
    const categoryStartCode = source[0].toLowerCase().charCodeAt(0);
    if (categoryStartCode < aCharCode || categoryStartCode > zCharCode) {
      this.colorClass = classPrefix + '8';
    } else {
      this.colorClass = classPrefix + ((categoryStartCode - aCharCode) % 9);
    }
  }
}

export class TemplateCardComponent implements angular.IComponentOptions {
  public templateUrl =
    'Client/runner.templates/runner.templates.card.component.tmpl.html';
  public bindings = {
    templateName: '<',
    templateBlurb: '<',
    categoryName: '<',
    icon: '<',
    public: '<',
    actionText: '@'
  };

  controller = TemplateCardComponentController;
}

angular
  .module('flowingly.runner.templates')
  .component('templateCard', new TemplateCardComponent());
