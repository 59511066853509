/**
 * @ngdoc directive
 * @name flowStatusBar
 * @module flowingly.runner.flow
 * @description A directive that display a progress basr as proportion of its parent
 * @usage
 * ```
 * ```
 */

'use strict';
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular, { IScope } from 'angular';

angular.module('flowingly.runner.flow').directive('flowStatusBar', [
  'flowsUtilityService',
  function (flowsUtilityService: SharedAngular.FlowsUtilityService) {
    return {
      restrict: 'A',
      scope: {
        flowStatusBar: '<'
      },
      link: function (scope: IScope, element: JQuery<HTMLElement>) {
        const statusBarWatch = scope.$watch('flowStatusBar', (newVal) => {
          if (newVal !== undefined) {
            if (!flowsUtilityService.isTablet())
              element.parent().css({ width: '100%', 'max-width': '100px' });
            else element.parent().css({ width: '50%' });

            element.css({
              width: parseFloat(scope.flowStatusBar) + '%'
            });
          }
        });

        scope.$on('$destroy', function () {
          if (statusBarWatch) statusBarWatch();
        });
      }
    };
  }
]);
