'use strict';
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular from 'angular';
import { IStateProvider } from 'angular-ui-router';
///
/// This state displays a list of available reports
///

// Register the application's routes configuration.
angular.module('flowingly.runner.reports').config(config);

// Inject the dependencies.
config.$inject = ['$stateProvider'];

function config($stateProvider: IStateProvider) {
  $stateProvider.state('app.runner.reports', {
    url: 'reports',
    params: { title: 'Reports' },
    templateUrl: 'Client/runner.reports/runner.reports.tmpl.html',
    controller: [
      'lodashService',
      'pubsubService',
      'flowListManager',
      'flowsUtilityService',
      function (
        lodashService: Lodash,
        pubsubService: SharedAngular.PubSubService,
        flowListManager: FlowListManager,
        flowsUtilityService: SharedAngular.FlowsUtilityService
      ) {
        const ctrl = this;

        init();

        function init() {
          pubsubService.subscribe(
            'SIGNALR_WORKFLOW_NAME_CHANGED',
            onFlowModelNameChanged,
            'runnerReportsController'
          );
          pubsubService.subscribe(
            'SIGNALR_SETUP_CATEGORY_DELETED',
            onCategoryDeleted,
            'runnerReportsController'
          );

          ctrl.selectedCategory = null;
          ctrl.allCategoriesId = 'all-categories';
          ctrl.categoryClicked = categoryClicked;
          ctrl.isMobile = flowsUtilityService.isMobileDevice();

          ctrl.categories = flowListManager.reportFlows;

          if (ctrl.categories.length === 0) {
            refreshFlows();
          } else {
            addAllReportsCategory(ctrl.categories);
            selectAllOption();
          }
        }

        function onCategoryDeleted() {
          refreshFlows();
        }

        function onFlowModelNameChanged(msg, data) {
          const msgdata = JSON.parse(data);
          flowListManager.updateFlowNames(msgdata);
          angular.copy(flowListManager.reportFlows, ctrl.categories);
        }

        function refreshFlows() {
          return flowListManager
            .refreshReportFlows(false)
            .then((reportCategories) => {
              ctrl.categories = reportCategories;
              addAllReportsCategory(ctrl.categories);
              selectAllOption();
            });
        }

        function categoryClicked(categoryId) {
          const category = this.categories.find(({ id }) => id === categoryId);
          if (category) {
            ctrl.selectedCategory = category;
          } else {
            selectAllOption();
          }
        }

        function selectAllOption() {
          ctrl.selectedCategory = ctrl.categories.find(
            (category) => category.id === ctrl.allCategoriesId
          );
        }

        function addAllReportsCategory(categories) {
          if (
            !categories ||
            categories.find((category) => category.id === ctrl.allCategoriesId)
          ) {
            return;
          }

          const copyOfAllFlows = lodashService.flatMap(
            categories,
            'flowModels'
          );

          categories.unshift({
            id: ctrl.allCategoriesId,
            name: 'All',
            flowModels: copyOfAllFlows
          });
        }
      }
    ],
    controllerAs: 'ctrl'
  });
}
