/**
 * @ngdoc directive
 * @name runner.flow.roles
 * @module flowingly.runner.setup
 * @description  This component is the root for roles
 */
'use strict';

import angular from 'angular';

export default class SetupRolesController {
  static $inject = ['$scope'];
  static ROLES_UPDATED_EVENT = 'SetupRolesUpdated';

  constructor(private $scope) {}

  onRolesUpdated() {
    // called by roles.header when it adds a new role
    // broadcasts to roles list so that it will perform a reload of the roles
    this.$scope.$broadcast(SetupRolesController.ROLES_UPDATED_EVENT);
  }
}

angular
  .module('flowingly.runner.setup')
  .controller('setupRolesController', SetupRolesController);
