/**
 * @ngdoc service
 * @name setupDeleteApiService
 * @module flowingly.runner.setup
 *
 * @description A service responsible solely for deleting setup items (users, teams, categories)
 *
 * ## Notes
 * No Caching or formatting to be performed here.
 * i.e. we return response.data.DataModel so the consumer can work with the data directly
 *
 */
'use strict';

class SetupDeleteApiService {
  static $inject = ['$http', '$q', 'APP_CONFIG', 'Enums', 'tokenService'];

  constructor(
    private $http,
    private $q,
    private APP_CONFIG,
    private enums,
    private tokenService
  ) {}
  //////////// Public API Methods

  public getTeams() {
    return this.$http
      .get(this.APP_CONFIG.apiBaseUrl + 'teams?includeDetails=false')
      .then((res) => {
        return res.data.dataModel;
      });
  }

  public getCategories() {
    return this.$http
      .get(this.APP_CONFIG.apiBaseUrl + 'categories')
      .then((res) => {
        return res.data.dataModel;
      });
  }

  public getWarnings(entityType, itemId) {
    if (entityType === this.enums.deleteEntityTypes.categoryEntityType) {
      return this.$http
        .get(
          this.APP_CONFIG.apiBaseUrl +
            'categories/deletewarnings?categoryId=' +
            itemId.id
        )
        .then((res) => {
          return res.data;
        });
    } else if (entityType === this.enums.deleteEntityTypes.teamEntityType) {
      return this.$http
        .get(
          this.APP_CONFIG.apiBaseUrl +
            'teams/deletewarnings?teamId=' +
            itemId.id
        )
        .then((res) => {
          return res.data;
        });
    } else if (entityType === this.enums.deleteEntityTypes.userEntityType) {
      return this.$http
        .get(
          this.APP_CONFIG.apiBaseUrl +
            'users/deletewarnings?userId=' +
            itemId.id
        )
        .then((res) => {
          return res.data;
        });
    } else if (
      entityType === this.enums.deleteEntityTypes.flowModelEntityType
    ) {
      const tenant = this.tokenService.getTenant();
      return this.$q.when({
        warnings: [
          `The flow model will be removed from ${tenant.name}. This cannot be undone!`
        ]
      });
    } else if (entityType === this.enums.deleteEntityTypes.databaseEntityType) {
      return this.$q.when({
        warnings: []
      });
    } else if (entityType === this.enums.deleteEntityTypes.roleEntityType) {
      return this.$http
        .get(
          this.APP_CONFIG.apiBaseUrl +
            'roles/deletewarnings?roleId=' +
            itemId.id
        )
        .then((res) => {
          return res.data;
        });
    } else {
      console.error('Unknown entity type');
    }
  }

  public getUserAssignedStepTasks(userId) {
    return this.$http.get(
      this.APP_CONFIG.apiBaseUrl + 'users/userStepTasks/' + userId,
      {
        noSpinner: false
      }
    );
  }
}

angular
  .module('flowingly.runner.setup')
  .factory('setupDeleteApiService', SetupDeleteApiService);
