/**
 * @ngdoc directive
 * @name usersList
 * @module flowingly.runner.setup
 * @description This comppnent is used to display the list of users
 * @usage
 * ```
     <users-list users="$ctrl.users"></users-list>
 * ``` 
 * ### Notes
 * See Also: 
 * ### Properties
 * #### Inputs
 * * users: the list of available users (JSON[])
 */
'use strict';

angular.module('flowingly.runner.setup').component('usersList', {
  bindings: {
    users: '<',
    onusersUpdated: '&'
  },
  templateUrl:
    'Client/runner.setup/runner.setup.users/runner.setup.users.list.tmpl.html',
  controller: [
    '$scope',
    'APP_CONFIG',
    'userApiService',
    'pubsubService',
    'roleApiService',
    function (
      $scope,
      APP_CONFIG,
      userApiService,
      pubsubService,
      roleApiService
    ) {
      const $ctrl = this;

      $ctrl.options = {
        dataSource: {
          transport: {
            read: function (options) {
              userApiService.getUsersWithOptions(options.data).then((data) => {
                data.users.forEach(
                  (user) =>
                    (user.displayRole = user.roles
                      .map((role) => role.name)
                      .sort()
                      .join(', '))
                );
                options.success({ data: data.users, total: data.total });
                $ctrl.gridNeedResize = true;
              });
            }
          },
          schema: {
            data: 'data',
            total: 'total'
          },
          pageSize: APP_CONFIG.gridReportPageSize,
          serverPaging: true,
          serverSorting: true,
          serverFiltering: true,
          serverGrouping: true,
          serverAggregates: true
        },
        noRecords: {
          template: '<users-no-results></users-no-results>'
        },
        pageable: true,
        sortable: true,
        filterable: {
          mode: 'row'
        },
        columns: getColumns()
      };

      $ctrl.gridInitDone = true;

      $ctrl.$onInit = () => {
        pubsubService.subscribe(
          'CLIENT_USER_PROFILE_UPDATED',
          updateGrid,
          'setup.users.list'
        );
      };

      $ctrl.$onChanges = function (changes) {
        if (!changes.users.isFirstChange()) {
          updateGrid();
        }
      };

      $ctrl.handleUsersUpdated = () => {
        updateGrid();
      };

      /// PRIVATE METHODS //////////////////////////////////

      function getColumns() {
        //Note: field needs to match the name (and case) of the returned data
        return [
          {
            field: 'fullName',
            title: 'Name',
            filterable: getAutoCompleteFilter('fullName', 'Name')
          },
          {
            field: 'email',
            title: 'Email',
            filterable: getAutoCompleteFilter('email', 'Email')
          },
          {
            field: 'displayRole',
            title: 'Roles',
            sortable: false,
            filterable: getRoleComboBoxFilter()
          },
          {
            field: 'managerName',
            title: 'Manager',
            filterable: getAutoCompleteFilter('managerName', 'Manager')
          },
          {
            field: 'teamsForDisplay',
            title: 'Teams',
            filterable: getAutoCompleteFilter('teamsForDisplay', 'Teams')
          },
          {
            field: 'status',
            title: 'Status',
            filterable: getStatusComboBoxFilter()
          },
          {
            title: 'Actions',
            width: '120px',
            filterable: false,
            template:
              "<users-list-actions user='dataItem' on-users-updated='$ctrl.handleUsersUpdated()'></users-list-actions>"
          }
        ];
      }

      function getAutoCompleteFilter(fieldName, displayName) {
        return {
          cell: {
            operator: 'contains', //default filtering uses 'equal to' operator, we want to use contains.
            showOperators: false,
            template: function (args) {
              args.element.kendoAutoComplete({
                dataSource: args.dataSource,
                dataTextField: fieldName, //enables filtering on specified column
                placeholder: `Search by ${displayName}`, //placeholder for text input
                valuePrimitive: true,
                filter: 'contains' //default autocomplete uses 'starts with'
              });
            }
          }
        };
      }

      function getStatusComboBoxFilter() {
        return {
          cell: {
            showOperators: false,
            template: function (args) {
              args.element.kendoComboBox({
                dataSource: [{ val: 'Active' }, { val: 'Invited' }],
                dataTextField: 'val', //enables filtering on specified column
                placeholder: `Search by Status`, //placeholder for text input
                dataValueField: 'status'
              });
            }
          }
        };
      }

      function getRoleComboBoxFilter() {
        return {
          cell: {
            showOperators: false,
            template: function (args) {
              roleApiService.getRoles().then(function (rolesList) {
                args.element.kendoComboBox({
                  dataSource: getColumnUniqueValues(rolesList),
                  dataTextField: 'val', //enables filtering on specified column
                  placeholder: `Search by Role`, //placeholder for text input
                  dataValueField: 'role'
                });
              });
            }
          }
        };
      }

      function getColumnUniqueValues(rolesList) {
        let dataSource = [];
        let uniqueValues = {};

        // insert unique value list
        for (let row of rolesList) {
          let value = row['name'];

          if (value) {
            uniqueValues[value] = value;
          }
        }

        // transform to array
        for (let value in uniqueValues) {
          dataSource.push({ val: value });
        }

        dataSource.sort((data1, data2) => {
          if (data1.val > data2.val) {
            return 1;
          }

          if (data1.val < data2.val) {
            return -1;
          }

          return 0;
        });

        return dataSource;
      }

      function updateGrid() {
        $scope.usersGrid.dataSource.read();
        $ctrl.gridNeedResize = true;
      }
    }
  ]
});
