/**
 * @ngdoc service
 * @name flowApiService
 * @module flowingly.runner.services
 *
 * @description A service responsible solely for fetching flow data
 *
 * ## Notes
 * No Caching or formatting to be performed here.
 * We flatten the responses from the server.
 * i.e. we return response.data.DataModel so the consumer can work with the data directly
 *
 */
import angular from 'angular';
import { Guid } from '../interfaces/util';
import { SharedAngular } from '@Client/@types/sharedAngular';
import { IUserBasicInfo } from '@Client/interfaces/user.interface';

export interface IFlowsForUserDataModel {
  FlowStates: string[];
  FlowModels: IFlowModelForUser[];
  GroupedFlows: IGroupedFlowsForUser[];
}

export interface IFlowModelForUser {
  Id: string;
  Name: string;
  FlowCategory: string;
  FlowCategoryId: string;
}

export interface IGroupedFlowsForUser {
  FlowCategory: string;
  FlowCategoryId: string;
  Flows: IMapFlowForGetFlows[];
  GroupName: string;
}

export interface IMapFlowForGetFlows {
  FlowId: string;
  FlowModelId: string;
  Name: string;
  Subject: string;
  StartedByName: string;
  StartedDate: string;
  FinalisedDate: string;
  FinalisedReason: string;
  DueDate: string;
  CommentCount: number;
  FilesCount: number;
  CurrentStep: string;
  CurrentStepsHeader: string;
  WaitingForName: string;
  WaitingForDate: string;
  PercentageComplete: number;
  CCUsers: string;
  WaitingFor: string;
  SortingField: string;
  FlowIdentifier: string;
  FlowCategory: string;
  FlowCategoryId: string;
  RequestedByUserId: string;
  RequestedByUser: IUserBasicInfo;
  IsConfidential: boolean;
}

export interface IStepReassignment {
  reassignType: string;
  reassignedToUserId: string;
  reassignedFromUserOrGroupId: string;
  stepTaskId: string;
  reason: string;
  flowId: string;
  stepId: string;
}

export interface IWebhookOverride {
  stepId: string;
  flowId: string;
  userId: string;
  reason: string;
}

export default class FlowApiService {
  public static $inject = [
    '$http',
    'APP_CONFIG',
    'sessionService',
    'runnerCardService',
    'flowinglyConstants'
  ];

  constructor(
    private $http: angular.IHttpService,
    private APP_CONFIG: AppConfig,
    private sessionService: SharedAngular.SessionService,
    private runnerCardService: RunnerCardService,
    private flowinglyConstants: SharedAngular.FlowinglyConstants
  ) {}

  /**
   * To see the original non-ts version checkout db2f05fd4238679926a31398717c57f78edd0e20
   *
   *
   * @param stepId
   * @param flowId
   * @param formData
   * @param files
   * @param assignedDynamicActors
   * @param selectedApprovers
   */
  public completeTask(
    stepId,
    flowId,
    formData,
    files,
    assignedDynamicActors,
    selectedApprovers
  ) {
    const cardData = this.runnerCardService.addFileToForm(formData, files);
    return this.$http
      .post(this.APP_CONFIG.apiBaseUrl + 'workflowinstance/completeTask', {
        UserId: this.getUserId(),
        FlowInstanceId: flowId,
        StepId: stepId,
        cardData: cardData,
        isNewFlow: false,
        assignedDynamicActors: assignedDynamicActors,
        selectedApprovers: selectedApprovers
      })
      .then((response) => {
        return response;
      });
  }

  /**
   * To see the original non-ts version checkout db2f05fd4238679926a31398717c57f78edd0e20
   *
   * @param flowModelId
   * @param noSpinner
   */
  public getPublishedFlowModel(flowModelId, noSpinner) {
    return this.$http
      .get<IResponseData>(
        `${this.APP_CONFIG.apiBaseUrl}workflow/published/${flowModelId}?maxUsersToShow=${this.flowinglyConstants.maxNumberOfUsersToShow}`,
        { noSpinner: noSpinner }
      )
      .then((response) => {
        return response.data.dataModel;
      });
  }

  public getProcessMap(flowModelId, noSpinner) {
    return this.$http
      .get(
        `${this.APP_CONFIG.apiBaseUrl}modeler/getProcessMap/${flowModelId}`,
        { noSpinner: noSpinner }
      )
      .then((response) => response.data);
  }

  public getProcessMapNodeElement(nodeId, noSpinner) {
    return this.$http
      .get(
        `${this.APP_CONFIG.apiBaseUrl}modeler/getProcessMapNodeElement/${nodeId}`,
        { noSpinner: noSpinner }
      )
      .then((response) => response.data);
  }
  /**
   * To see the original non-ts version checkout db2f05fd4238679926a31398717c57f78edd0e20
   *
   * @param noSpinner
   * @param version
   */
  public getPublishedFlowModels(noSpinner, publishType) {
    return this.$http<IResponseData>({
      method: 'GET',
      url: this.APP_CONFIG.apiBaseUrl + 'workflow/published',
      params: {
        publishType: publishType
      },
      noSpinner: noSpinner
    }).then((response) => response.data.dataModel);
  }

  /**
   * To see the original non-ts version checkout db2f05fd4238679926a31398717c57f78edd0e20
   *
   * @param noSpinner
   */
  public getReportFlows(noSpinner) {
    return this.$http
      .get(this.APP_CONFIG.apiBaseUrl + 'reports/getFlowModelsCanReportOn', {
        noSpinner: noSpinner
      })
      .then((response) => {
        return response.data;
      });
  }
  /**
   * To see the original non-ts version checkout db2f05fd4238679926a31398717c57f78edd0e20
   *
   * @param stepNudgeHistory
   */
  public nudgeFlowWaitingOnActor(stepNudgeHistory) {
    return this.$http.post(
      this.APP_CONFIG.apiBaseUrl + 'nudge/step/',
      stepNudgeHistory
    );
  }
  /**
   * To see the original non-ts version checkout db2f05fd4238679926a31398717c57f78edd0e20
   *
   * @param noSpinner
   * @param stepId
   */
  public getStepNudgeHistoryList(noSpinner, stepId) {
    return this.$http
      .get<IResponseData>(`${this.APP_CONFIG.apiBaseUrl}nudge/step/${stepId}`, {
        noSpinner: noSpinner
      })
      .then((response) => {
        return response.data.dataModel;
      });
  }

  /*
   * GetFlows API Data Model returned:
   *
   * DataModel
   *  - FlowModels
   *  - GroupedFlows
   *  - - GroupName
   *  - - Flows
   *  - - - Steps
   *  - - - - Fields
   */
  public getFlowById(flowId, noSpinner) {
    return this.$http
      .get(
        this.APP_CONFIG.apiBaseUrl + 'workflowinstance/flows?flowId=' + flowId,
        { noSpinner: false }
      )
      .then((response) => {
        return response.data;
      });
  }

  /**
   * To see the original non-ts version checkout db2f05fd4238679926a31398717c57f78edd0e20
   *
   * @param options
   */
  public getApproversAndDynamicActorsForNodes(options) {
    return this.$http
      .post<IResponseData>(
        this.APP_CONFIG.apiBaseUrl +
          'workflowinstance/approversAndDynamicActorsForNodes',
        options,
        {
          noSpinner: true
        }
      )
      .then((response) => {
        return response.data.dataModel;
      });
  }

  /**
   * To see the original non-ts version checkout db2f05fd4238679926a31398717c57f78edd0e20
   *
   * @param status
   * @param onlyStartedByMe
   */
  public getFlowsImin(status, onlyStartedByMe) {
    const userId = this.getUserId();
    return this.$http
      .get<IUResponseData<IFlowsForUserDataModel>>(
        this.APP_CONFIG.apiBaseUrl +
          'workflowinstance/getflows?userId=' +
          userId +
          '&flowStatus=' +
          status +
          '&startedByMeOnly=' +
          onlyStartedByMe +
          '&waitingForYouOnly=false',
        { noSpinner: false }
      )
      .then((response) => {
        return response.data.DataModel;
      });
  }

  public getFlowsTodo() {
    return this.$http
      .get<IFlowsForUserDataModel>(
        this.APP_CONFIG.apiBaseUrl + 'workflowinstance/todo',
        {
          noSpinner: false
        }
      )
      .then((response) => {
        return response.data;
      });
  }
  /**
   * To see the original non-ts version checkout db2f05fd4238679926a31398717c57f78edd0e20
   *
   * @param searchTerm
   */
  public getFlowSearchResults(searchTerm) {
    const userId = this.getUserId();
    return this.$http
      .get<IUResponseData>(
        this.APP_CONFIG.apiBaseUrl +
          'workflowinstance/search?userId=' +
          userId +
          '&term=' +
          encodeURIComponent(searchTerm),
        { noSpinner: false }
      )
      .then((response) => {
        return response.data.DataModel;
      });
  }

  private saveFormPending = false;
  public saveFormProgress(flowId, stepId, formData, files) {
    if (this.saveFormPending) {
      return Promise.resolve();
    }

    const cardData = this.runnerCardService.addFileToForm(formData, files);
    this.saveFormPending = true;
    return this.$http
      .post<void>(
        this.APP_CONFIG.apiBaseUrl + 'workflowinstance/saveStepProgress',
        {
          StepId: stepId,
          UserId: this.getUserId(),
          FlowInstanceId: flowId,
          cardData: cardData
        },
        {
          noSpinner: true
        }
      )
      .then(angular.noop)
      .finally(() => (this.saveFormPending = false));
  }
  /**
   * To see the original non-ts version checkout db2f05fd4238679926a31398717c57f78edd0e20
   *
   * @param process
   * @param request
   * @param value
   */
  public callBoomiProcess(process, request, value) {
    return this.$http.post(
      this.APP_CONFIG.apiBaseUrl + 'workflowinstance/boomi',
      { Process: process, Request: request, Value: value },
      {
        noSpinner: true
      }
    );
  }

  /**
   * To see the original non-ts version checkout db2f05fd4238679926a31398717c57f78edd0e20
   *
   * @param flowId
   * @param subject
   * @param assignedActorId
   * @param ccActors
   */
  public startFlow(flowId, subject, assignedActorId, ccActors) {
    //start a flow with specified flow id and the subject user has entered
    return this.$http
      .post<IResponseData>(this.APP_CONFIG.apiBaseUrl + 'workflowinstance', {
        UserId: this.getUserId(),
        FlowId: flowId,
        Subject: subject,
        AssignedActorId: assignedActorId,
        CCActors: ccActors
      })
      .then((response) => {
        return response.data.dataModel;
      });
  }

  /**
   * To see the original non-ts version checkout db2f05fd4238679926a31398717c57f78edd0e20
   *
   * @param flowModelId
   * @param requesterId
   * @param subject
   * @param actors
   * @param ccActors
   * @param assignedActorId
   */
  public bulkStartFlow(
    flowModelId,
    subject,
    actors,
    ccActors,
    assignedActorId,
    shouldAppendNameToSubject?: boolean,
    excludeActors?: Guid[]
  ) {
    const url = this.APP_CONFIG.apiBaseUrl + 'workflowinstance/bulkStart';
    const data = {
      FlowModelId: flowModelId,
      Subject: subject,
      ActorsToStartFlowFor: actors,
      CCActors: ccActors,
      AssignedActorId: assignedActorId,
      ShouldAppendNameToSubject: shouldAppendNameToSubject,
      ExcludeActors: excludeActors
    };

    return this.$http.post(url, data).then((response) => {
      return response.data;
    });
  }

  /**
   * To see the original non-ts version checkout db2f05fd4238679926a31398717c57f78edd0e20
   *
   * @param data An object which contains the flowId and comment.
   */
  public withdrawFlow(data) {
    const request = {
      FlowInstanceId: data.flowId,
      CancellationComment: data.comment,
      EntitiesToNotify: data.selectedActors
    };

    return this.$http
      .post<IUResponseData>(
        `${this.APP_CONFIG.apiBaseUrl}workflowinstance/withdraw`,
        request
      )
      .then((response) => {
        return response.data.Success;
      });
  }

  public reassignStep(data: IStepReassignment) {
    return this.$http
      .post(this.APP_CONFIG.apiBaseUrl + 'delegation/reassignTaskToUser', data)
      .then((response) => {
        return response.data;
      });
  }

  /**
   *  To see the original non-ts version checkout db2f05fd4238679926a31398717c57f78edd0e20
   *
   */
  public getUserId() {
    return this.sessionService.getUser().id;
  }

  /**
   * To see the original non-ts version checkout db2f05fd4238679926a31398717c57f78edd0e20
   *
   * @param noSpinner
   */
  public getFlowCategories(noSpinner) {
    return this.$http
      .get<IResponseData>(this.APP_CONFIG.apiBaseUrl + 'categories', {
        noSpinner: noSpinner
      })
      .then((response) => {
        return response.data.dataModel;
      });
  }
  /**
   * To get get flow support details
   *
   * @param flowId
   */
  public getFlowSupportDetails(flowId) {
    return this.$http
      .get(this.APP_CONFIG.apiBaseUrl + 'workflowinstance/support/' + flowId)
      .then((response) => {
        return response.data;
      });
  }

  public addStepTask(data) {
    return this.$http
      .post(this.APP_CONFIG.apiBaseUrl + 'workflowinstance/addStepTask', data)
      .then((response) => {
        return response;
      });
  }

  public updateStepTaskStatus(stepTaskId, status) {
    return this.$http
      .put(
        this.APP_CONFIG.apiBaseUrl +
          'workflowinstance/steptask/' +
          stepTaskId +
          '/status/' +
          status,
        undefined
      )
      .then((response) => {
        return response;
      });
  }

  public updateStepTaskApproval(stepTaskId, approverUserId, approved, comment) {
    const request = {
      stepTaskId: stepTaskId,
      approverUserId: approverUserId,
      approved: approved,
      comment: comment
    };

    return this.$http
      .post(
        this.APP_CONFIG.apiBaseUrl + 'workflowinstance/steptask/approval',
        request
      )
      .then((response) => {
        return response;
      });
  }

  public cancelStepTask(data) {
    const request = {
      UserId: this.getUserId(),
      StepTaskId: data.stepTaskId,
      CancellationComment: data.comment,
      EntitiesToNotify: data.selectedActors
    };

    return this.$http
      .post(
        this.APP_CONFIG.apiBaseUrl + 'workflowinstance/steptask/cancel',
        request
      )
      .then(
        (response) => {
          if (response.status === 200) {
            return true;
          } else {
            return false;
          }
        },
        () => {
          return false;
        }
      );
  }

  public getStepTask(stepTaskId) {
    return this.$http
      .get(
        this.APP_CONFIG.apiBaseUrl + 'workflowinstance/steptask/' + stepTaskId
      )
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
        return null;
      });
  }

  public overrideProcessingIntegration(data: IWebhookOverride) {
    return this.$http
      .post(
        this.APP_CONFIG.apiBaseUrl +
          'workflowinstance/overrideIntegrationState',
        data
      )
      .then((response) => {
        return response.data;
      });
  }
}

angular
  .module('flowingly.runner.services')
  .service('flowApiService', FlowApiService);

export type FlowApiServiceType = InstanceType<typeof FlowApiService>;
