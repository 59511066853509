/**
 * @ngdoc type
 * @module flowingly.runner.templates
 * @name RunnerEditTemplateController
 *
 * @description Controller for editing a Template.
 */
'use strict';

import { SharedAngular } from '@Client/@types/sharedAngular';
import angular from 'angular';

export default class RunnerEditTemplateController {
  static $inject = [
    'templateService',
    'flowinglyDiagramService',
    'goService',
    '$stateParams',
    'categoryApiService',
    'notificationService',
    'guidService',
    'fileService',
    '$scope',
    'flowinglyConstants'
  ];

  private currentForm;
  private categories;
  private schemaFiles: FileList;
  private template;
  private appOrigin;
  private requestPending = false;
  private steps;
  private selectedStep;
  private icon;
  private editorOptions = {
    tools: [
      'formatting',
      'bold',
      'italic',
      'underline',
      'fontSize',
      'strikethrough',
      'justifyLeft',
      'justifyCenter',
      'justifyRight',
      'justifyFull',
      'insertUnorderedList',
      'insertOrderedList',
      'indent',
      'outdent',
      'createLink',
      'unlink',
      'foreColor',
      'backColor',
      'insertImage',
      'insertFile',
      {
        name: 'insertVideo',
        tooltip: 'Embed Youtube Video',
        exec: (e) =>
          this.$scope.$broadcast('EditorYoutubeClicked', {
            event: e,
            selector: '#template-description'
          })
      },
      'viewHtml'
    ]
  };

  constructor(
    private templateService: SharedAngular.TemplateService,
    private flowinglyDiagramService: SharedAngular.FlowinglyDiagramService,
    private goService: GoJS,
    private $stateParams: angular.ui.IStateParamsService,
    private categoryApiService: CategoryApiService,
    private notificationService: SharedAngular.NotificationService,
    private guidService: SharedAngular.GuidService,
    private fileService: SharedAngular.FileService,
    private $scope: angular.IScope,
    private flowinglyConstants: SharedAngular.FlowinglyConstants
  ) {
    const loadingPromises = [];
    if (this.$stateParams.templateKey) {
      const templatePromise = this.templateService
        .getTemplate(this.$stateParams.templateKey)
        .then((details) => {
          this.fileService
            .replaceFileIdsWithImages(details.description)
            .then((result) => {
              details.description = result;
              this.template = details;
              this.$scope.$apply();
            });
          this.template = details;
          if (this.template.flowSchema) {
            this.templateSchemaChanged();
            // Have to wait for angular to render the target div
            setTimeout(() => this.displayFlowModel());
          }
          this.setupValidation();
        });
      loadingPromises.push(templatePromise);
    } else {
      this.template = {
        id: guidService.empty()
      };
      this.setupValidation();
      loadingPromises.push(Promise.resolve());
    }

    const categoriesPromise = this.categoryApiService
      .getCategories()
      .then((categories) => (this.categories = categories));
    loadingPromises.push(categoriesPromise);

    Promise.all(loadingPromises).then(() => this.categoryChanged());
    this.appOrigin = window.location.origin;
  }

  setupValidation() {
    if (!this.currentForm) {
      // Angular might not have created currentForm yet
      setTimeout(() => this.setupValidation(), 1);
      return;
    }
    const name = this.currentForm.templateName.$validators;
    name.noPublicName = (name) => {
      return !this.template.isPublic || !!name;
    };

    const key = this.currentForm.templateKey.$asyncValidators;
    key.notUnique = (newKey) => {
      return this.templateService
        .getTemplateIdForKey(newKey)
        .then((keyTemplateId) => {
          if (
            keyTemplateId !== this.template.id &&
            keyTemplateId !== this.guidService.empty()
          ) {
            return Promise.reject('Key already exists');
          }
        });
    };
  }

  validateForm() {
    for (const key of Object.keys(this.currentForm)) {
      if (key.startsWith('$')) {
        continue;
      }
      const control = this.currentForm[key];
      control.$setDirty();
      control.$validate();
    }
  }

  setEmptyKey() {
    if (this.template.key == undefined || this.template.key === '') {
      this.template.key = this.template.name;
      this.filterInvalidKeyChars();
    }
  }

  filterInvalidKeyChars() {
    this.template.key =
      this.template.key && this.template.key.replace(/[^A-Za-z]*/g, '');
  }

  categoryChanged() {
    const category = this.categories.find(
      (c) => c.id == this.template.categoryId
    );
    if (category) {
      this.template.categoryName = category.name;
      this.icon = category.icon;
    }
  }

  templateSchemaChanged() {
    const schema = JSON.parse(this.template.flowSchema);
    if (schema.nodeDataArray) {
      this.steps = schema.nodeDataArray.filter(
        (node) => node.category === 'activity'
      );
      this.selectedStep = this.steps[0];
    }
  }

  templateSchemaFileSelected() {
    const reader = new FileReader();
    reader.addEventListener('load', (event) => {
      this.template.flowSchema = this.cleanSchema(event.target.result);
      this.templateSchemaChanged();
      this.displayFlowModel();
      this.$scope.$apply();
    });
    if (this.schemaFiles.length > 0) {
      reader.readAsText(this.schemaFiles[0]);
    }
  }

  private cleanSchema(schemaString) {
    const schema = JSON.parse(schemaString);
    if (!schema.nodes) {
      return schemaString;
    }
    schema.nodes.forEach((node) => {
      removeNodeUserReferences(node);
      removeImages(node);
      removeAttachments(node);
    });

    schema.nodeDataArray.forEach((nodeData) => {
      removeNodeDataUserReferences(nodeData);
    });

    return JSON.stringify(schema);

    function removeNodeUserReferences(node) {
      node.AssignedUserId = '';
      node.AssignedGroupId = null;
      node.ActorName = '';
      node.AvatarUrl = '';
      node.AssignedInitiator = false;
      node.AssignedInitiatorManager = false;
      node.AssignedPreviousActor = false;
      node.DynamicActorType = null;
      node.SelectedDynamicActors = [];
      node.SelectedApprovers = [];
      node.NumberOfApproversRequired = 0;
      node.MaxNumberOfApproversRequired = 0;
      node.NumberOfApproversRequiredType = 0;
      node.WhenApproversSelected = 0;
      node.SelectApproverModelerNodeId = null;
    }

    function removeImages(node) {
      if (!node.Card || !node.Card.formElements) {
        return;
      }
      node.Card.formElements = node.Card.formElements.filter(
        (field) => field.type !== 'image'
      );
      node.Card.formElements.forEach((field) => {
        if (
          (field.type !== 'instruction' && field.type !== 'textarea') ||
          !field.value
        ) {
          return;
        }
        field.value = field.value.replace(
          /<img data-file-id="[-a-z0-9]*" \/>/g,
          ''
        );
      });
    }

    function removeAttachments(node) {
      if (!node.Card || !node.Card.formElements) {
        return;
      }
      node.Card.formElements.forEach((field) => {
        if (field.type === 'attachdocument') {
          field.attachDocumentFileIds = [];
        }
      });
    }

    function removeNodeDataUserReferences(nodeData) {
      nodeData.avatarUrl = null;
      nodeData.actorName = null;
      nodeData.actorType = null;
      nodeData.actor = null;
      nodeData.assignedPreviousActor = false;
      delete nodeData.dynamicActorType;
    }
  }

  private displayFlowModel() {
    // Won't show unless we use diagram service settings for process map
    this.template.isProcessMap = true;
    const diagram = this.flowinglyDiagramService.generateProcessModel({
      flow: this.template,
      modelCustomArgs: {
        scrollMode: this.goService.Diagram.DocumentScroll
      },
      allowSelect: false,
      dynamicInitialHeight: 400
    });
  }

  updateNodeName(modelerNodeId, newName) {
    const model = JSON.parse(this.template.flowSchema);
    const modelerNode = model.nodeDataArray.find((n) => n.id === modelerNodeId);
    const flowinglyNode = model.nodes.find(
      (n) => n.ModelerNodeId === modelerNodeId
    );
    modelerNode.text = flowinglyNode.StepName = newName;
    this.template.flowSchema = JSON.stringify(model);
    this.displayFlowModel();
  }

  saveTemplate() {
    this.requestPending = true;
    this.uploadAndReplaceEmbeddedImages().then(() => {
      this.templateService
        .saveTemplate(this.template)
        .then((templateId) => {
          this.template.id = templateId;
          this.requestPending = false;
          this.notificationService.showSuccessToast('Template saved');
          this.fileService
            .replaceFileIdsWithImages(this.template.description)
            .then((result) => {
              this.template.description = result;
              this.$scope.$apply();
            });
        })
        .catch(() => {
          this.requestPending = false;
          this.notificationService.showErrorToast(
            'An error occurred while saving the Template'
          );
        });
    });
  }

  private uploadAndReplaceEmbeddedImages() {
    return this.fileService
      .convertEmbeddedImagesToFileReferences(
        this.template.description,
        this.template.key,
        this.flowinglyConstants.fileFolder.Templates
      )
      .then((result) => {
        this.template.description = result;
      });
  }
}

angular
  .module('flowingly.runner.templates')
  .controller('runnerEditTemplateController', RunnerEditTemplateController);
