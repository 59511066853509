/**
 * @ngdoc service
 * @name flowModelApiService
 * @module flowingly.runner.services
 *
 * @description A service responsible solely for fetching flow model data
 *
 * ## Notes
 * No Caching or formatting to be performed here.
 *
 */
/// i.e. we return response.data.DataModel so the consumer can work with the data directly
'use strict';
import angular from 'angular';

angular
  .module('flowingly.runner.services')
  .factory('flowModelApiService', flowModelApiService);

flowModelApiService.$inject = ['$http', 'APP_CONFIG'];

function flowModelApiService(
  $http: angular.IHttpService,
  APP_CONFIG: AppConfig
) {
  const service = {
    flowModels: [],
    deleteFlowModel: deleteFlowModel,
    getFlowModels: getFlowModels,
    publishFlowModel: publishFlowModel,
    unPublishFlow: unPublishFlow,
    getFlowModelById
  };

  return service;

  //////////// Public API Methods

  function deleteFlowModel(flowModelId) {
    return $http
      .post(APP_CONFIG.apiBaseUrl + `workflow/delete/${flowModelId}`, {})
      .then(() => {
        //remove from local array
        this.flowModels = this.flowModels.filter((f) => {
          return f.id !== flowModelId;
        });
      });
  }

  function getFlowModels(noSpinner = false) {
    return $http
      .get<IResponseData>(APP_CONFIG.apiBaseUrl + 'workflow', {
        noSpinner: noSpinner || false
      })
      .then(function (response) {
        angular.copy(response.data.dataModel, service.flowModels);
        return response.data.dataModel;
      });
  }

  function publishFlowModel(model) {
    //TODO fix case
    if (model.status === 'Draft') {
      return $http
        .post(APP_CONFIG.apiBaseUrl + `workflow/${model.FlowId}/publish`, model)
        .then(() => {
          //update status in local array
          const published = this.flowModels.find((f) => {
            return f.id === model.FlowId;
          });
          if (published) {
            published.status = 'Published';
          }
        });
    } else if (model.status === 'Published') {
      return $http.post(
        APP_CONFIG.apiBaseUrl + `workflow/${model.FlowId}/publish`,
        model
      );
    }
  }

  function unPublishFlow(flowId) {
    return $http.post(`workflow/${flowId}/unpublish`, {}).then(() => {
      //update status in local array
      const published = this.flowModels.find((f) => {
        return f.id === flowId;
      });
      if (published) {
        published.status = 'Draft';
      }
    });
  }

  function getFlowModelById(flowModelId) {
    return $http
      .get(`${APP_CONFIG.apiBaseUrl}workflow/${flowModelId}`)
      .then((res) => res.data);
  }
}

export type FlowModelApiServiceType = ReturnType<typeof flowModelApiService>;
