(function () {
  'use strict';

  // Register the application's routes configuration.
  angular.module('flowingly.runner.setup').config(config);

  // Inject the dependencies.
  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider.state('app.runner.setup.categories', {
      url: '/categories',
      params: { flowId: null, title: 'Setup - Categories' },
      views: {
        childcontent: {
          //if / when we upgrade to ui-router v1 these two controller lines can be replaced with
          //component: 'categorysetupComponent',
          controllerAs: '$ctrl',
          controller: [
            'permissionsService',
            'categoryApiService',
            'flowinglyConstants',
            function (
              permissionsService,
              categoryApiService,
              flowinglyConstants
            ) {
              if (
                !permissionsService.currentUserHasPermission(
                  flowinglyConstants.permissions.SETUP_CATEGORY_ACCESS
                )
              ) {
                $state.go('app.runner.flowsactive');
              }

              this.categories = categoryApiService.categories;
            }
          ],
          template: `<runner-setup-categories categories="$ctrl.categories"></runner-setup-categories>`
        }
      },
      resolve: {
        //get the list of categories on state load and pass to component BEFORE page loads
        categories: [
          'categoryApiService',
          function (categoryApiService) {
            return categoryApiService.getCategories();
          }
        ]
      }
    });
  }
})();
