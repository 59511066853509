/**
 * @ngdoc service
 * @name flowListManager
 * @module flowingly.runner.services
 *
 * @description This service is responsible managing the various lists,
 *              it will fetch / persist data using the apiService,
 *              and format the list data using appropriate services.
 */

'use strict';
import angular from 'angular';
import { IGroupedFlowsForUser } from './flow.api.service';

angular
  .module('flowingly.runner.services')
  .factory('flowListManager', flowListManager);

export interface IFlowGroupCategory {
  id: string;
  name: string;
  count: number;
}

flowListManager.$inject = [
  '$q',
  'lodashService',
  'sessionService',
  'flowApiService',
  'runnerFlowsFormatter',
  'runnerStartFlowsFormatter',
  'flowinglyConstants'
];

function flowListManager(
  $q,
  lodashService,
  sessionService,
  flowApiService: FlowApiService,
  runnerFlowsFormatter,
  runnerStartFlowsFormatter,
  flowinglyConstants
) {
  const _defaultIminFlowStatus = 'InProgress';
  const _defaultIminFlowFilter = '1';
  const _defaultTodoFlowFilter = '1';
  const _defaultIminFlowCategory = 'null';
  const _defaultTodoFlowCategory = 'null';

  // We need to store state so we know both the state for the Imin view and
  //  the Todo view when we refresh the flow lists
  let _selectedIminStatus = _defaultIminFlowStatus;
  let _selectedIminFlowFilterId = _defaultIminFlowFilter;
  let _selectedTodoFlowFilterId = _defaultTodoFlowFilter;
  let _onlyStartedByMe = true;
  let _selectedIminFlowCategoryId = _defaultIminFlowCategory;
  let _selectedTodoFlowCategoryId = _defaultTodoFlowCategory;

  const service = {
    reportFlows: [], // All flow models that are published (grouped by category in start.flows.formatter)
    groupedFlowsTodo: [] as IGroupedFlowsForUser[], // Flows To Do grouped by the selected group
    groupedFlowsImin: [] as IGroupedFlowsForUser[], // Flows Im In grouped by the selected group and status
    iminFlowFilters: [],
    todoFlowFilters: [],
    statusOptions: [
      { name: 'In Progress', id: 'InProgress' },
      { name: 'All', id: 'All' },
      { name: 'Completed', id: 'Completed' },
      { name: 'Rejected', id: 'Rejected' }
    ],
    flowToDoCategories: [] as IFlowGroupCategory[],
    flowImInCategories: [] as IFlowGroupCategory[],

    // -------------Methods----------------
    applyFlowTodoFilter: applyFlowTodoFilter,
    applyFlowIminFilter: applyFlowIminFilter,
    clearLists: clearLists,
    countflowsTodo: countflowsTodo, //used by the counter displayed in the LHS menu

    getOnlyStartedByMe: getOnlyStartedByMe,
    getIminStatus: getIminStatus,
    getIminFlowFilter: getIminFlowFilterId,
    getTodoFlowFilter: getTodoFlowFilterId,
    getTodoFlowCategory: getTodoFlowCategoryId,
    getIminFlowCategory: getIminFlowCategoryId,
    getTodoFlowFilters: getTodoFlowFilters,
    getIminFlowFilters: getIminFlowFilters,

    setOnlyStartedByMe: setOnlyStartedByMe,
    setIminStatus: setIminStatus,
    setIminFlowFilter: setIminFlowFilter,
    setTodoFlowFilter: setTodoFlowFilter,
    setTodoFlowCategory: setTodoFlowCategory,
    setIminFlowCategory: setIminFlowCategory,

    getCategorizedWorkflows: getCategorizedWorkflows,
    getCategorizedProcessMaps,

    refreshReportFlows: refreshReportFlows,
    refreshFlowsImin: refreshFlowsImin,
    //Call up the API to get the flows for the selected group and status
    refreshFlowsTodo: refreshFlowsTodo, //Call up the API to get the flows for the selected group and status
    refreshFlowInstanceLists: refreshFlowInstanceLists, // Called when we get a signalR notification
    replaceFlowInstanceListsActor: replaceFlowInstanceListsActor,
    refreshTodoFlowFilters: refreshTodoFlowFilters, // Refresh TodoFlowFilters if the filter does not exists, set it to default filter

    updateFlowProgress: updateFlowProgress,
    //called when flow progress saved (to ensure changes visible immediatly)
    updateFlowNames: updateFlowNames, //user has changed flow name in modeler, update here
    updateFlowInLists: updateFlowInLists,
    removeDeletedProcessOwner: removeDeletedProcessOwner,
    updateFlowCommentCountInLists: updateFlowCommentCountInLists
  };

  return service;

  //////////// Public API Methods

  function applyFlowTodoFilter() {
    lodashService.forEach(service.groupedFlowsTodo, function (group) {
      let groupHasFlows = false;
      lodashService.forEach(group.Flows, function (flow) {
        flow.show = false;
        if (
          (!_selectedTodoFlowCategoryId ||
            _selectedTodoFlowCategoryId === _defaultTodoFlowCategory ||
            _selectedTodoFlowCategoryId === flow.FlowCategoryId) &&
          (!_selectedTodoFlowFilterId ||
            _selectedTodoFlowFilterId === _defaultTodoFlowFilter ||
            _selectedTodoFlowFilterId === flow.FlowModelId)
        ) {
          flow.show = true;
          groupHasFlows = true;
        }
      });
      group.show = groupHasFlows;
    });
  }

  function applyFlowIminFilter() {
    lodashService.forEach(service.groupedFlowsImin, function (group) {
      let groupHasFlows = false;
      lodashService.forEach(group.Flows, function (flow) {
        flow.show = false;
        if (
          (!_selectedIminFlowCategoryId ||
            _selectedIminFlowCategoryId === _defaultIminFlowCategory ||
            _selectedIminFlowCategoryId === flow.FlowCategoryId) &&
          (!_selectedIminFlowFilterId ||
            _selectedIminFlowFilterId === _defaultIminFlowFilter ||
            _selectedIminFlowFilterId === flow.FlowModelId)
        ) {
          flow.show = true;
          groupHasFlows = true;
        }
      });
      group.show = groupHasFlows;
    });
  }

  function clearLists() {
    service.groupedFlowsTodo = [];
    service.groupedFlowsImin = [];
    service.reportFlows = [];
  }

  function countflowsTodo() {
    const groupCount = service.groupedFlowsTodo.length;
    let todoCount = 0;
    for (let groupIndex = 0; groupIndex < groupCount; groupIndex++) {
      todoCount += service.groupedFlowsTodo[groupIndex].Flows.length;
    }
    return todoCount;
  }

  function getOnlyStartedByMe() {
    return _onlyStartedByMe;
  }

  function getIminStatus() {
    return _selectedIminStatus;
  }

  function getIminFlowFilterId() {
    return _selectedIminFlowFilterId;
  }

  function getTodoFlowFilterId() {
    return _selectedTodoFlowFilterId;
  }

  function getTodoFlowCategoryId() {
    return _selectedTodoFlowCategoryId;
  }

  function getIminFlowCategoryId() {
    return _selectedIminFlowCategoryId;
  }

  function getTodoFlowFilters() {
    if (_selectedTodoFlowCategoryId !== _defaultTodoFlowCategory)
      return service.todoFlowFilters.filter(
        (f) => f.id === '1' || f.flowCategoryId === _selectedTodoFlowCategoryId
      );
    else return service.todoFlowFilters;
  }

  function getIminFlowFilters() {
    if (_selectedIminFlowCategoryId !== _defaultIminFlowCategory)
      return service.iminFlowFilters.filter(
        (f) => f.id === '1' || f.flowCategoryId === _selectedIminFlowCategoryId
      );
    else return service.iminFlowFilters;
  }

  function setOnlyStartedByMe(onlyStartedByMe) {
    _onlyStartedByMe = onlyStartedByMe;
  }

  function setIminStatus(status) {
    if (status) {
      _selectedIminStatus = status;
    } else {
      _selectedIminStatus = _defaultIminFlowStatus;
    }
  }

  function setIminFlowFilter(flowFilter) {
    if (flowFilter) {
      _selectedIminFlowFilterId = flowFilter;
    } else {
      _selectedIminFlowFilterId = null;
    }
  }

  function setTodoFlowFilter(flowFilter) {
    if (flowFilter) {
      _selectedTodoFlowFilterId = flowFilter;
    } else {
      _selectedTodoFlowFilterId = null;
    }
  }

  function setTodoFlowCategory(flowCategoryId) {
    _selectedTodoFlowCategoryId = flowCategoryId || _defaultTodoFlowCategory;
    _selectedTodoFlowFilterId = _defaultTodoFlowFilter;
  }

  function setIminFlowCategory(
    flowCategoryId,
    defaultFlowFilterRequired = true
  ) {
    _selectedIminFlowCategoryId = flowCategoryId || _defaultIminFlowCategory;
    if (defaultFlowFilterRequired) {
      _selectedIminFlowFilterId = _defaultIminFlowFilter;
    }
  }

  function updateFlowCommentCountInLists(flowId, commentCount) {
    updateFlowCommentCount(service.groupedFlowsImin, flowId, commentCount);
    updateFlowCommentCount(service.groupedFlowsTodo, flowId, commentCount);
  }

  function updateFlowProgress(flowId, stepId, formData) {
    // since a flow can appear in both flowsImIn and flowsTodo - we need to update both
    // flows can be grouped by due date, flow, completed status etc.
    // so rather than search each list, combine them all into one, then search

    // Start with FLOWS IM IN
    updateFlowInList(service.groupedFlowsImin, flowId, stepId, formData);

    // Then do FLOWS TO DO
    updateFlowInList(service.groupedFlowsTodo, flowId, stepId, formData);
  }

  function updateFlowNames(data) {
    service.groupedFlowsTodo = updateFlowModelNameInList(
      data,
      service.groupedFlowsTodo,
      false
    );

    service.groupedFlowsImin = updateFlowModelNameInList(
      data,
      service.groupedFlowsImin,
      false
    );

    service.reportFlows = updateFlowModelNameInList(
      data,
      service.reportFlows,
      true,
      'flowModels'
    );
  }

  function removeDeletedProcessOwner(flowModelIds) {
    service.reportFlows.forEach((category) => {
      category.flowModels.forEach((flowModel) => {
        if (flowModelIds.some((id) => flowModel.id === id)) {
          flowModel.processOwnerName = null;
        }
      });
    });
  }

  function updateFlowModelNameInList(msgDetails, source, camel, property?) {
    if (property === undefined) {
      property = camel ? 'flows' : 'Flows';
    }
    //we still have a mix of Pascal and Camel, rather than try and fix now and risk breaking other things
    //simply switch on a flag
    let changesMade = false;
    if (camel) {
      source.forEach((g) => {
        g[property].forEach((f) => {
          if (f.id === msgDetails.id) {
            changesMade = changesMade || f.name !== msgDetails.name;
            f.name = msgDetails.name;
            f.flowModelName = msgDetails.name;
          }
        });
      });
    } else {
      source.forEach((g) => {
        g[property].forEach((f) => {
          if (f.FlowModelId === msgDetails.id) {
            changesMade = changesMade || f.Name !== msgDetails.name;
            f.Name = msgDetails.name;
            g.GroupName = msgDetails.name;
          }
        });
      });
    }
    return changesMade ? angular.copy(source) : source;
  }

  function updateFlowInList(list, flowId, stepId, formData) {
    if (list.length > 0) {
      let allFlows = [];
      lodashService.each(list, (g) => {
        allFlows = allFlows.concat(g.Flows);
      });
      const flow = lodashService.find(allFlows, (f) => {
        return f.FlowId === flowId;
      });
      if (flow) {
        const step = lodashService.find(flow.Steps, (s) => {
          return s.Id === stepId;
        });
        copyFormDataToFormElements(step, formData);
      }
    }
  }

  function copyFormDataToFormElements(step, formData) {
    if (!step) {
      return;
    }
    lodashService.each(formData, (d) => {
      const field = lodashService.find(step.Fields, (f) => {
        return f.Name === d.key;
      });
      if (
        field &&
        (field.Type === 'tasklist' || field.Type === 'multiselectlist')
      ) {
        //anything that uses values and not value
        field.Value = d.values;
        lodashService.each(field.Value, function (val) {
          val.value = val.value.toString();
          val.values = '';
        });
      } else if (field && field.Type !== 'instruction') {
        field.Value = d.value;
      }
    });
  }

  /*
   * GetFlows API Data Model returned:
   *
   * DataModel
   *  - FlowModels
   *  - GroupedFlows
   *  - - GroupName
   *  - - Flows
   *  - - - Steps
   *  - - - - Fields
   */
  function refreshFlowsTodo() {
    return flowApiService.getFlowsTodo().then(function (response) {
      runnerFlowsFormatter.removeGroupsWithoutFlows(response.GroupedFlows);
      formatFlowsTodo(response.GroupedFlows);
      setTodoFlowFilters(response.FlowModels);
      applyFlowTodoFilter();
      return service.groupedFlowsTodo;
    });
  }

  function refreshFlowsImin() {
    return flowApiService
      .getFlowsImin(_selectedIminStatus, _onlyStartedByMe)
      .then(function (response) {
        runnerFlowsFormatter.removeGroupsWithoutFlows(response.GroupedFlows);
        formatFlowsImin(response.GroupedFlows, undefined);
        setIminFlowFilters(response.FlowModels);
        applyFlowIminFilter();
        return service.groupedFlowsImin;
      });
  }

  // Called after a signalr notification is received
  function refreshFlowInstanceLists() {
    const todo = refreshFlowsTodo();
    const imin = refreshFlowsImin();
    return $q.all([todo, imin]);
  }

  function updateFlowInLists(updatedFlow) {
    const user = sessionService.getUser();
    if (updatedFlow.WaitingForUserIds.includes(user.id)) {
      updateFlow(service.groupedFlowsTodo);
    } else {
      removeFlow(service.groupedFlowsTodo);
      generateCategoriesOfGroupedFlows(
        service.flowToDoCategories,
        service.groupedFlowsTodo
      );
    }

    if (
      _selectedIminStatus === 'InProgress' &&
      (updatedFlow.PercentageComplete === 100 ||
        updatedFlow.CurrentStepsHeader === '')
    ) {
      removeFlow(service.groupedFlowsImin);
      generateCategoriesOfGroupedFlows(
        service.flowImInCategories,
        service.groupedFlowsImin
      );
    } else {
      updateFlow(service.groupedFlowsImin);
    }

    applyFlowTodoFilter();
    applyFlowIminFilter();

    function updateFlow(groupedFlows) {
      if (!Array.isArray(groupedFlows)) {
        return;
      }

      const groupWasUpdated = groupedFlows.some((group) => {
        if (group.GroupName !== updatedFlow.Name) {
          return false;
        }

        const flowWasUpdated = group.Flows.some((flow, index, array) => {
          if (flow.FlowIdentifier !== updatedFlow.FlowIdentifier) {
            return false;
          }

          array[index] = updatedFlow;
          return true;
        });

        if (!flowWasUpdated) {
          group.Flows.push(updatedFlow);
        }
        return true;
      });

      if (!groupWasUpdated) {
        const group = {
          FlowCategory: updatedFlow.Category,
          FlowCategoryId: updatedFlow.FlowCategoryId,
          Flows: [updatedFlow],
          GroupName: updatedFlow.Name
        };
        groupedFlows.push(group);
        groupedFlows.sort((a, b) => a.GroupName.localeCompare(b.GroupName));
      }
    }

    function removeFlow(groupedFlows) {
      if (!Array.isArray(groupedFlows)) {
        return;
      }

      const groupIndex = groupedFlows.findIndex(
        (group) => group.GroupName === updatedFlow.Name
      );
      if (groupIndex === -1) {
        return;
      }

      const group = groupedFlows[groupIndex];
      const flowIndex = group.Flows.findIndex(
        (flow) => flow.FlowId === updatedFlow.FlowId
      );
      if (flowIndex === -1) {
        return;
      }

      group.Flows.splice(flowIndex, 1);
      if (group.Flows.length === 0) {
        groupedFlows.splice(groupIndex, 1);
      }
    }
  }

  // an actor has been deleted so we will replace references to them
  function replaceFlowInstanceListsActor(actorName, replacementActorName) {
    replaceWaitingForName(service.groupedFlowsTodo);
    replaceWaitingForName(service.groupedFlowsImin);

    function replaceWaitingForName(groupedFlows) {
      if (!Array.isArray(groupedFlows)) {
        return;
      }

      groupedFlows.forEach((group) => {
        group.Flows.forEach((flow) => {
          if (flow.WaitingForName.indexOf(actorName) === -1) {
            return;
          }

          const userNames = flow.WaitingForName.split(', ');
          const oldUserNameIndex = userNames.findIndex(
            (name) => name === actorName
          );
          if (oldUserNameIndex === -1) {
            return;
          }

          userNames[oldUserNameIndex] = replacementActorName;
          flow.WaitingForName = userNames.join(', ');
        });
      });
    }
  }

  /* End of GetFlows API calls */

  function getCategorizedWorkflows(noSpinner = false) {
    return flowApiService
      .getPublishedFlowModels(
        noSpinner,
        flowinglyConstants.flowModelPublishType.WORKFLOW
      )
      .then((flowModels) =>
        runnerStartFlowsFormatter.groupFlowModelsByCategory(flowModels)
      );
  }

  function getCategorizedProcessMaps(noSpinner) {
    return flowApiService
      .getPublishedFlowModels(
        noSpinner,
        flowinglyConstants.flowModelPublishType.PROCESS_MAP
      )
      .then((flowModels) =>
        runnerStartFlowsFormatter.groupFlowModelsByCategory(flowModels)
      );
  }

  function refreshReportFlows(noSpinner) {
    return flowApiService.getReportFlows(noSpinner).then((response) => {
      formatReportFlows(response);
      return service.reportFlows;
    });
  }

  function refreshTodoFlowFilters(flowFilters) {
    const previousSelectedFlowFilter = getTodoFlowFilterId();
    if (lodashService.find(flowFilters, { id: previousSelectedFlowFilter })) {
      return previousSelectedFlowFilter;
    } else {
      setTodoFlowFilter(_defaultTodoFlowFilter);
      refreshFlowsTodo();
      return _defaultTodoFlowFilter;
    }
  }

  //////////// Private Methods

  // The following format methods simply call other methods to format the API responses.
  // No formatting should be completed in this service itself
  // They are however responsible for updating the list contents

  function updateFlowCommentCount(listOfFlows, flowId, commentCount) {
    let allFlows = [];
    lodashService.each(listOfFlows, (g) => {
      allFlows = allFlows.concat(g.Flows);
    });
    const flow = lodashService.find(allFlows, (f) => {
      return f.FlowId === flowId;
    });

    if (flow) flow.CommentCount = commentCount;
  }

  function formatReportFlows(flowsFromServer) {
    const activeFlows =
      runnerStartFlowsFormatter.groupFlowModelsByCategory(flowsFromServer);
    angular.copy(activeFlows, service.reportFlows);
  }

  function generateCategoriesOfGroupedFlows(
    categories: IFlowGroupCategory[],
    groupedFlows: IGroupedFlowsForUser[]
  ) {
    categories.length = 0;
    if (!groupedFlows) {
      return;
    }
    groupedFlows.forEach((group) => {
      const category = categories.find((c) => c.name === group.FlowCategory);
      if (category === undefined) {
        const newCategory: IFlowGroupCategory = {
          id: group.FlowCategoryId,
          name: group.FlowCategory,
          count: group.Flows.length
        };
        categories.push(newCategory);
      } else {
        category.count += group.Flows.length;
      }
    });
  }

  function formatFlowsTodo(groupedFlows: IGroupedFlowsForUser[]) {
    generateCategoriesOfGroupedFlows(service.flowToDoCategories, groupedFlows);
    runnerFlowsFormatter.format(groupedFlows, 'DueDate');
    angular.copy(groupedFlows, service.groupedFlowsTodo);
  }

  function formatFlowsImin(
    groupedFlows: IGroupedFlowsForUser[],
    sortAscending = true
  ) {
    //flows Ive started is a little different, simply copy across
    //you cannot change the flow status here, so having intelligent update is not as important
    generateCategoriesOfGroupedFlows(service.flowImInCategories, groupedFlows);
    runnerFlowsFormatter.format(groupedFlows, null, sortAscending);
    angular.copy(groupedFlows, service.groupedFlowsImin);
  }

  function setIminFlowFilters(flowModels) {
    service.iminFlowFilters = [];
    service.iminFlowFilters.push({
      name: 'All Flows',
      id: _defaultIminFlowFilter
    });
    lodashService.forEach(flowModels, function (flowModel) {
      service.iminFlowFilters.push({
        name: flowModel.Name,
        id: flowModel.Id,
        flowCategory: flowModel.FlowCategory,
        flowCategoryId: flowModel.FlowCategoryId
      });
    });
  }

  function setTodoFlowFilters(flowModels) {
    service.todoFlowFilters = [];
    service.todoFlowFilters.push({
      name: 'All Flows',
      id: _defaultTodoFlowFilter
    });
    lodashService.forEach(flowModels, function (flowModel) {
      service.todoFlowFilters.push({
        name: flowModel.Name,
        id: flowModel.Id,
        flowCategory: flowModel.FlowCategory,
        flowCategoryId: flowModel.FlowCategoryId
      });
    });
  }
}

export type FlowListManagerType = ReturnType<typeof flowListManager>;
