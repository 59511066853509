/**
 * @ngdoc type
 * @module flowingly.runner.templates
 * @name runnerTemplateController
 *
 * @description Controller for Template.
 */
'use strict';

import { SharedAngular } from '@Client/@types/sharedAngular';
import angular from 'angular';

export default class RunnerTemplateController {
  static $inject = [
    'templateService',
    'permissionsService',
    '$scope',
    '$stateParams',
    'validationService',
    '$sce',
    'flowinglyConstants',
    'APP_CONFIG',
    '$location',
    'pdfService',
    'sessionService',
    'dialogService',
    'guidService',
    'tempModelerUrlService',
    'fileService'
  ];

  private template;
  private safeDescription;
  private linkCopied = false;
  private linkCopyFailed = false;
  private canCreateFlowModels: boolean;
  private inTenantBusiness = false;

  constructor(
    private templateService: SharedAngular.TemplateService,
    private permissionsService: SharedAngular.PermissionsService,
    private $scope: angular.IScope,
    private $stateParams: angular.ui.IStateParamsService,
    private validationService: SharedAngular.ValidationService,
    private $sce: angular.ISCEService,
    private flowinglyConstants: SharedAngular.FlowinglyConstants,
    private APP_CONFIG: AppConfig,
    private $location: angular.ILocationService,
    private pdfService: SharedAngular.PDFService,
    private sessionService: SharedAngular.SessionService,
    private dialogService: SharedAngular.DialogService,
    private guidService: SharedAngular.GuidService,
    private tempModelerUrlService: SharedAngular.TempModelerUrlService,
    private fileService: SharedAngular.FileService
  ) {
    this.sessionService.onReady(() => {
      this.canCreateFlowModels =
        !this.APP_CONFIG.disableFlowModelCreation &&
        this.permissionsService.currentUserHasPermission(
          this.flowinglyConstants.permissions.LIBRARY_ACCESS
        );
    });

    this.inTenantBusiness = sessionService.inTenantBusiness();

    this.templateService
      .getTemplate(this.$stateParams.templateKey)
      .then((details) => {
        this.template = details;
        this.safeDescription =
          details.description &&
          this.validationService.sanitizeString(
            this.$sce.trustAsHtml(
              this.makeLinksOpenInNewTab(details.description)
            )
          );
        this.fileService
          .replaceFileIdsWithImages(details.description)
          .then((result) => {
            this.safeDescription =
              result &&
              this.validationService.sanitizeString(
                this.$sce.trustAsHtml(this.makeLinksOpenInNewTab(result))
              );
            this.$scope.$apply();
          });
      });
  }

  private makeLinksOpenInNewTab(html: string) {
    const linkPattern = new RegExp(/<a [^>]*href="/g);
    return html.replace(linkPattern, (match: string) => {
      return '<a target="_blank"' + match.substring(2);
    });
  }

  createFromTemplate() {
    const flowModelId = this.guidService.empty();
    const cloneExistingModel = false;
    const openFeedback = false;
    this.tempModelerUrlService.openModeler(
      flowModelId,
      cloneExistingModel,
      openFeedback,
      this.template.key
    );
  }

  copyLink() {
    const uri = this.$location.absUrl();
    navigator.clipboard
      .writeText(uri)
      .then(() => {
        this.linkCopied = true;
        this.$scope.$apply();
        setTimeout(() => {
          this.linkCopied = false;
          this.$scope.$apply();
        }, 2000);
      })
      .catch(() => {
        this.linkCopyFailed = true;
        this.$scope.$apply();
      });
  }

  downloadTemplateInfoPdf() {
    const config = {
      pdfStyleClass: 'template-pdf-content'
    };
    this.pdfService.from.dom(
      '#template-description',
      this.template.name,
      config
    );
  }

  showAccessRequestDialog() {
    return this.dialogService.showDialog({
      template:
        'Client/runner.templates/dialogs/runner.templates.dialogs.access.tmpl.html',
      controller: 'templateAccessDialogController',
      appendClassName: 'ngdialog-normal',
      data: {
        templateId: this.template.id,
        templateKey: this.template.key,
        templateName: this.template.name
      }
    });
  }
}

angular
  .module('flowingly.runner.templates')
  .controller('runnerTemplateController', RunnerTemplateController);
