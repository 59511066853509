/**
 * @name runnerInfoFlowModel
 * @module flowingly.runner.directives
 * @description This is a component for showing info about the flow model.
 *
 */
import angular from 'angular';

class InfoFlowModelComponentController {
  static $inject = ['APP_CONFIG'];
  flowSize: number;
  flowName: string;
  flowDescription: string;
  flowIdentifier: string;
  flowIsObsolote: boolean;
  flowVersion: number;
  hasSteps: boolean;
  canEdit: boolean;
  flowIsTooBig: boolean;

  constructor(private APP_CONFIG: AppConfig) {}

  $onChanges() {
    //also comparing to 0 because the FlowSchema was already set to null when it already exceeds the FlowModelSizeLimit
    this.flowIsTooBig =
      this.flowSize > this.APP_CONFIG.flowModelSizeLimit || this.flowSize == 0;
  }
}

export class InfoFlowModelComponent implements angular.IComponentOptions {
  public bindings: Bindings;
  public templateUrl: string;

  constructor() {
    this.bindings = {
      flowSize: '<',
      flowName: '<',
      flowDescription: '<',
      flowIdentifier: '<',
      flowVersion: '<',
      flowLatestSchemaVersion: '<',
      hasSteps: '<',
      canEdit: '<'
    };

    this.templateUrl =
      'Client/runner.directives/flow/runner.info-flow-model.tmpl.html';
  }

  controller = InfoFlowModelComponentController;
}

angular
  .module('flowingly.runner.directives')
  .component('runnerInfoFlowModel', new InfoFlowModelComponent());
