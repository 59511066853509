'use strict';

import { SharedAngular } from '@Client/@types/sharedAngular';
import { IQService } from 'angular';

angular
  .module('flowingly.runner.library')
  .controller('flowModelOwnerDialogController', flowModelOwnerDialogController);

flowModelOwnerDialogController.$inject = [
  '$scope',
  '$q',
  'APP_CONFIG',
  'flowModelOwnerDialogService',
  'userApiService',
  'flowinglyConstants'
];

function flowModelOwnerDialogController(
  $scope: IScopeNgDialog,
  $q: IQService,
  APP_CONFIG: AppConfig,
  flowModelOwnerDialogService: FlowModelOwnerDialogService,
  userApiService: SharedAngular.UserApiService,
  flowinglyConstants: SharedAngular.FlowinglyConstants
) {
  const ctrl = this;

  ctrl.submitOwners = submitOwners;
  ctrl.selectedFlowModelOwnerList = [];
  ctrl.selectedReportOwnerList = [];
  ctrl.flowModelId = $scope.ngDialogData.flowModelId;
  ctrl.flowModelName = $scope.ngDialogData.flowModelName;

  ctrl.expandGroupMembersFilter = (user, roles) => {
    return (
      user &&
      roles.some((role) =>
        user.roles.some((userRole) => userRole.name === role)
      )
    );
  };

  ctrl.cancel = () => {
    $scope.closeThisDialog();
  };

  ctrl.enableReportOwner = APP_CONFIG.enableReportOwner;

  $scope.$on('kendoWidgetCreated', function (ev, widget) {
    if (widget === ctrl.tabStrip) {
      ctrl.tabStrip.select(0);
    }
  });

  init();

  function init() {
    ctrl.loading = true;
    const flowModelOwnersPromise = getFlowModelOwnerUsers();
    const reportOwnersPromise = getReportOwnerUsers();
    const existingFlowModelOwnersPromise = loadExistingFlowModelOwners();
    const existingReportOwnersPromise = loadExistingReportOwners();

    $q.all([
      flowModelOwnersPromise,
      reportOwnersPromise,
      existingFlowModelOwnersPromise,
      existingReportOwnersPromise
    ]).then(() => (ctrl.loading = false));
  }

  function submitOwners() {
    const flowModelAdminUserIds = ctrl.selectedFlowModelOwnerList
      .filter(
        (item) =>
          item.searchEntityType === flowinglyConstants.searchEntityType.USER
      )
      .map((item) => item.id);
    const flowModelAdminGroupIds = ctrl.selectedFlowModelOwnerList
      .filter(
        (item) =>
          item.searchEntityType === flowinglyConstants.searchEntityType.GROUP
      )
      .map((item) => item.id);
    const reportAdminUserIds = ctrl.selectedReportOwnerList
      .filter(
        (item) =>
          item.searchEntityType === flowinglyConstants.searchEntityType.USER
      )
      .map((item) => item.id);
    const reportAdminnGroupIds = ctrl.selectedReportOwnerList
      .filter(
        (item) =>
          item.searchEntityType === flowinglyConstants.searchEntityType.GROUP
      )
      .map((item) => item.id);
    flowModelOwnerDialogService
      .saveFlowModelOwners({
        flowModelId: ctrl.flowModelId,
        userIds: flowModelAdminUserIds,
        groupIds: flowModelAdminGroupIds
      })
      .then(() =>
        $scope.closeThisDialog({
          success: true
        })
      );

    if (ctrl.enableReportOwner) {
      flowModelOwnerDialogService
        .saveReportOwners({
          flowModelId: ctrl.flowModelId,
          userIds: reportAdminUserIds,
          groupIds: reportAdminnGroupIds
        })
        .then(() =>
          $scope.closeThisDialog({
            success: true
          })
        );
    }
  }

  function getFlowModelOwnerUsers() {
    return userApiService
      .getUsersWithPermission(
        flowinglyConstants.permissions.FLOWMODEL_OWNER_CANDIDATE
      )
      .then((users) => {
        ctrl.flowModelOwnerUsers = users.sort((a, b) =>
          a.fullName.localeCompare(b.fullName)
        );
      });
  }

  function getReportOwnerUsers() {
    if (!ctrl.enableReportOwner) {
      return $q.defer().resolve();
    }

    return userApiService
      .getUsersWithPermission(
        flowinglyConstants.permissions.FLOWMODEL_REPORTOWNER_CANDIDATE
      )
      .then(
        (users) =>
          (ctrl.reportOwnerUsers = users.sort((a, b) =>
            a.fullName.localeCompare(b.fullName)
          ))
      );
  }

  function loadExistingFlowModelOwners() {
    return flowModelOwnerDialogService
      .getExistingFlowModelOwners(ctrl.flowModelId)
      .then(function (response) {
        if (response && response.data && response.data.dataModel)
          ctrl.selectedFlowModelOwnerList = response.data.dataModel;
      });
  }

  function loadExistingReportOwners() {
    if (!ctrl.enableReportOwner) return $q.defer().resolve();

    return flowModelOwnerDialogService
      .getExistingReportOwners(ctrl.flowModelId)
      .then(function (response) {
        if (response && response.data && response.data.dataModel)
          ctrl.selectedReportOwnerList = response.data.dataModel;
      });
  }
}
