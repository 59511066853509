/**
 * @ngdoc service
 * @name authService
 * @module flowingly.runner.services
 *
 * @description A helper service for authenticating the user.
 *
 * ## Notes
 *
 * ###API
 * * userProfile - retunrs the users stored profile
 * * login - log the user in
 * * logout - log the user out
 * * registerAuthenticationListener - register callback for Auth0 login
 *
 */
'use strict';

import { SharedAngular } from '@Client/@types/sharedAngular';
import angular, {
  IHttpPromiseCallbackArg,
  IHttpService,
  IQService,
  IRootScopeService,
  IWindowService
} from 'angular';
import { IStateService } from 'angular-ui-router';

type LoginOptions = {
  auth0UseUniversalLogin: boolean;
  logoUrl: string;
  welcomeText: string;
  loginAlertMessage: string;
  favIcon: string;
  redirectUrl?: string;
};

export interface BusinessTenant {
  id: string;
  name: string;
}

export interface UserTokenResponse {
  user: UserDetail;
  token: string;
}

export interface UserDetail {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  businessId: string;
  businessName: string;
  businessCreatedDate: Date;
  businessIndustry: string;
  businessPhoneNumber: string;
  isOwner: boolean;
  identityId: string;
  inviteUser: boolean;
  userRole: string;
  delete: boolean;
  jobTitle: string;
  active: boolean;
  avatarUrl: string;
  fullName: string;
  inviteAcceptanceDate: Date;
  isAvatar: boolean;
  haveCheckedForAvatar: boolean;
  timeZone: string;
  userType: number;
  roles: any[];
  groups: any[];
  managerUserId: string;
  managerUser: UserDetail;
  managerFullName: string;
  numberOfUsersManaged: number;
  InDelegationMode: boolean;
  DelegateStepUserId: string;
  DelegateApprovalUserId: string;
  DelegateStepUserFullName: string;
  DelegateApprovalUserFullName: string;
}

angular.module('flowingly.runner.services').factory('authService', authService);

authService.$inject = [
  '$rootScope',
  '$q',
  '$http',
  '$window',
  'dialogService',
  'sessionService',
  'tokenService',
  'APP_CONFIG',
  'pubsubService',
  'permissionsService',
  'sideMenuService',
  'intercomService',
  'lock',
  'angularAuth0',
  'authManager',
  'flowListManager',
  'notificationService',
  '$state',
  'flowsUtilityService',
  'userApiService',
  'Enums',
  'lodashService',
  'redirectService',
  'authLoggingApiService'
];

function authService(
  $rootScope: IRootScopeService,
  $q: IQService,
  $http: IHttpService,
  $window: IWindowService,
  dialogService: SharedAngular.DialogService,
  sessionService: SharedAngular.SessionService,
  tokenService: SharedAngular.TokenService,
  APP_CONFIG: AppConfig,
  pubsubService: SharedAngular.PubSubService,
  permissionsService: SharedAngular.PermissionsService,
  sideMenuService: SharedAngular.SideMenuService,
  intercomService: SharedAngular.IntercomService,
  lock: AngularLock,
  angularAuth0: auth0.WebAuth,
  authManager: angular.jwt.IAuthManagerServiceProvider,
  flowListManager: FlowListManager,
  notificationService: SharedAngular.NotificationService,
  $state: IStateService,
  flowsUtilityService,
  userApiService: SharedAngular.UserApiService,
  enums: Enums,
  lodashService: Lodash,
  redirectService: RedirectService,
  authLoggingApiService: SharedAngular.AuthLoggingApiService
) {
  //See for more details
  //https://github.com/auth0-samples/auth0-angularjs-sample/blob/ui-router-html5/08-Calling-Api/public/components/auth/auth.service.js

  const userProfileJson = sessionService.getProfile();
  let userProfile = (userProfileJson && JSON.parse(userProfileJson)) || null;
  let user = sessionService.getUser();
  let redirectUrl = '';

  const deferredProfile = $q.defer();
  let deferredUser = $q.defer();

  if (userProfile) {
    deferredProfile.resolve(userProfile);
  }
  if (user) {
    deferredUser.resolve(user);
  }

  const service = {
    getUser: getUser,
    getUserDetails: getUserDetails,
    userProfile: userProfile,
    getProfileDeferred: getProfileDeferred,
    getUserPendingAcceptCondition: getUserPendingAcceptCondition,
    getUserDeferred: getUserDeferred,
    isAuthenticated: isAuthenticated,
    isWorkflowUser: isWorkflowUser,
    login: login,
    logout: logout,
    loginWithToken: loginWithToken,
    setUserAfterSuccessfulLogin: loginSuccessful,
    registerAuthenticationListener: registerAuthenticationListener,
    getTenants: getTenants,
    changeTenant: changeTenant
  };

  return service;

  //////////// Public API Methods

  // When the user logs on he/she is authenticated by Auth0. This happens before
  // we go and retrieve the user details from the API. Once we integrate our User properly
  // with the Auth0 User then this distinctioon will go away (TODO).
  function isAuthenticated() {
    return $rootScope.isAuthenticated;
  }

  function isWorkflowUser() {
    const permissions = sessionService.getPermissions();

    let setupPermission = false;
    lodashService.forEach(permissions, (permission) => {
      if (permission && permission.toLowerCase().indexOf('setup.') > -1) {
        setupPermission = true;
      }
    });

    return !setupPermission;
  }

  function login(options: LoginOptions) {
    const {
      auth0UseUniversalLogin,
      logoUrl,
      welcomeText,
      loginAlertMessage,
      favIcon,
      redirectUrl
    } = options;
    userProfile = null;
    user = null;
    tokenService.clearToken();
    sessionService.clear();
    authManager.unauthenticate();

    authLoggingApiService.log('Login box is about to be displayed.');

    if (auth0UseUniversalLogin) {
      const params = {
        logo: logoUrl,
        title: welcomeText,
        loginAlertMessage,
        favIcon,
        state: redirectUrl
      };
      angularAuth0.authorize(params);
    } else {
      lock.show(); //login using Auth0 Lock Login box
      if (loginAlertMessage) {
        setTimeout(() => {
          addAlertMessageToLoginScreen(loginAlertMessage);
        }, 0);
      }
    }
  }

  function logout() {
    authLoggingApiService.log('auth.service logout() called.');

    const userProfileObject = JSON.parse(sessionService.getProfile());

    let loginAuditIdentityProvider =
      enums.loginAuditIdentityProvider.UserAndPassword;
    if (sessionService.isSso()) {
      loginAuditIdentityProvider = enums.loginAuditIdentityProvider.SSO;
    }

    const isADFSUser =
      userProfileObject &&
      userProfileObject.identities &&
      userProfileObject.identities.length > 0 &&
      userProfileObject.identities[0].provider === 'adfs';

    authLoggingApiService.log(
      `auth.service logout() - value for isADFSUser was ${isADFSUser}`
    );

    // log user logout
    return userApiService
      .userLoginAudit({
        success: true,
        identityProvider: loginAuditIdentityProvider,
        action: enums.loginAuditAction.Logout
      })
      .then(logoutInner)
      .then(function () {
        sideMenuService.clearMenu();
        flowListManager.clearLists();

        if (isADFSUser) {
          authLoggingApiService.log(
            `auth.service logout() userLoginAudit().then() - navigating ADFS user to https://"${APP_CONFIG.auth0Domain}/v2/logout?federated`
          );
          $window.location.href =
            'https://' + APP_CONFIG.auth0Domain + '/v2/logout?federated';
        } else {
          authLoggingApiService.log(
            'auth.service logout() userLoginAudit().then() - navigating non-ADFS user to app.login'
          );
          $state.go('app.login');
        }
      });
  }

  async function logoutInner() {
    authLoggingApiService.log('auth.service logout() logoutInner() called.');
    await userApiService.updateLoginState(false);
    deferredUser = $q.defer();
    if (window.isFlowinglyCordova) {
      userApiService.unregisterUserMobileNotification();
    }
    userProfile = null;
    user = null;
    intercomService.shutdown();
    tokenService.clearToken();
    sessionService.clear();
    authManager.unauthenticate();
    deferredUser.resolve();
    return deferredUser.promise;
  }

  function loginWithToken(token: string) {
    tokenService.setToken(token);
    return sessionService
      .getUserDetails(null, token)
      .then(async function (response) {
        if (response === undefined) {
          return Promise.reject('Invalid user');
        }
        await loginSuccessful(response.data.dataModel);
        sessionService.setuserAuthenticated();
      });
  }

  // Set up the logic for when a user authenticates using Auth0 Lock.
  // See https://github.com/auth0/lock for a list of all events that Lock will emit during it's lifecycle
  function registerAuthenticationListener() {
    lock.on('authenticated', function (authResult) {
      // We must even run this script when we are already authenticated as some times we will be re-logging in
      // just because the user is not in local storage and so we need to get the user from the API
      sessionService.setInitialLogin();
      tokenService.setToken(authResult.idToken);
      authManager.authenticate();

      lock.getProfile(authResult.accessToken, function (error, profile) {
        if (error) {
          loginFailed();
          console.log(error);
        }
        // For easy access to the auth0.com flowinglyDB user record data
        sessionService.setProfile(JSON.stringify(profile));
        deferredProfile.resolve(profile);
        getUserPendingAcceptCondition(function () {
          let loginAuditIdentityProvider = 0;
          if (profile.identities && profile.identities.length > 0) {
            if (
              profile.identities[0].connection ===
              'Username-Password-Authentication'
            ) {
              sessionService.setIsSso(false);
              loginAuditIdentityProvider =
                enums.loginAuditIdentityProvider.UserAndPassword;
              authLoggingApiService.log(
                `auth.service registerAuthenticationListener() set isSso to false and user profile identity provider was ${profile.identities[0].provider}`
              );
            } else {
              sessionService.setIsSso(true);
              loginAuditIdentityProvider = enums.loginAuditIdentityProvider.SSO;
              authLoggingApiService.log(
                `auth.service registerAuthenticationListener() set isSso to true and user profile identity provider was ${profile.identities[0].provider}`
              );
            }
          }
          getUserDetails(profile.email);
          userApiService.userLoginAudit({
            success: true,
            identityProvider: loginAuditIdentityProvider,
            action: enums.loginAuditAction.Login
          });

          $rootScope.$emit('flowingly.onAuthentication');
        });
      });
      if (authResult.state !== '') {
        redirectUrl = decodeURIComponent(authResult.state);
        authLoggingApiService.log(
          `auth.service registerAuthenticationListener() setting re-dir-ect-url was ${authResult.state}`
        );
      }
    });

    lock.on('authorization_error', function (authResult) {
      if (authResult.description) {
        userApiService.userLoginErrorAudit({
          success: false,
          identityProvider: enums.loginAuditIdentityProvider.UserAndPassword,
          error: authResult.description,
          token: APP_CONFIG.passCode,
          action: enums.loginAuditAction.Login
        });
        authLoggingApiService.log(
          `auth.service registerAuthenticationListener() on authorization_error. failed with error ${authResult.description}`
        );
      }
    });
  }

  function getUserPendingAcceptCondition(successCallback) {
    return $http
      .get<IResponseData>(APP_CONFIG.apiBaseUrl + 'usercondition')
      .then((response) => {
        if (response.data && response.data.success && response.data.dataModel) {
          dialogService
            .showDialog({
              template:
                'Client/runner.user-condition/runner.user-condition.dialog.tmpl.html',
              controller: 'userConditionDialogController',
              appendClassName: `ngdialog-normal${
                flowsUtilityService.isMobileDevice()
                  ? ' terms-conditions-dialog'
                  : ''
              }`,
              data: response.data.dataModel,
              closeByEscape: false,
              closeByDocument: false
            })
            .then(function (result) {
              if (
                result === false ||
                (result !== undefined &&
                  dialogService.isCloseModalWithCancelAction(result))
              ) {
                //user closed modal by clicking on overlay (or cancel or press Esc key)
                loginFailed();
              } else if (response.data.dataModel.id == null) {
                // FLOW-6159 - there appears to be an edge case where this function could be called twice
                // resulting in the id being set to null. I have my suspicions about the auth token expiring
                // causing this. Worse case the user clicked accept and we failed to update the DB with this
                // then on the next login they will be presented with the terms and conditions again.
                successCallback();
              } else if (result && result.accepted) {
                $http
                  .post(
                    `${APP_CONFIG.apiBaseUrl}usercondition/${response.data.dataModel.id}/accept`,
                    {
                      userConditionId: response.data.dataModel.id
                    }
                  )
                  .then(function () {
                    successCallback();
                  });
              } else {
                loginFailed();
              }
            });
        } else {
          // At present we dont have our UserId (from our Flowingly database) stored at auth0.com
          // so am using the users email to locate him/her
          successCallback();
        }
      });
  }

  function getTenants() {
    return $http
      .get<BusinessTenant[]>(`${APP_CONFIG.apiBaseUrl}business/tenants`, {
        cache: true
      })
      .then((result) => {
        return result.data;
      });
  }

  function changeTenant(tenantId) {
    return $http
      .put<UserTokenResponse>(
        `${APP_CONFIG.apiBaseUrl}account/tenant/${tenantId}`,
        undefined
      )
      .then(async (result) => {
        await loginSuccessful(result.data.user);
        tokenService.setToken(result.data.token);
        sessionService.setuserAuthenticated();
        sessionService.getBusinessSettings().then((settings) => {
          APP_CONFIG.populateBusinessSettings(settings);
          APP_CONFIG.enableTenantSwitching = true;
          $state.go('app.login');
        });
      });
  }

  //////////// Private API Methods

  //After we logon we go back to our API to get full user details
  //As we are using the Auth0 Lock library (which has its own login box - lock.show()) we cant have login also grab our user details from our API
  //So after lock authenticates the User we need to then go back through our API to get the User's details
  //In effect that is a second level of authentication - if the user is not found there or is not active then the authentication will fail
  function getUserDetails(email, token?) {
    return sessionService
      .getUserDetails(email, token)
      .then(async function (response) {
        if (response === undefined) {
          loginFailed();
        } else {
          const res = response.data;

          if (res.success === true) {
            await loginSuccessful(res.dataModel);

            // @TODO merge this and the redirect.request.service
            // We must check that it is one of our URLs before we use it. Auth0 uses the state property as part of the auth process also.
            if (
              redirectUrl.indexOf('/flows/') > 0 ||
              redirectUrl.indexOf('/categoryId?') > 0 ||
              redirectUrl.indexOf('/form/') !== -1
            ) {
              authLoggingApiService.log(
                `auth.service getUserDetails() was called and about to be navigated to ${redirectUrl}`
              );
              $window.location.href = redirectUrl;
            }
            if (window.isFlowinglyCordova) {
              const userId = res.dataModel.id;
              userApiService
                .registerUserMobileNotification(
                  userId,
                  window.flowinglyCordova.isIos
                )
                .then(() => {
                  if ($window.location.href.indexOf('/flowsactive') < 0)
                    $window.location.href =
                      '/flowsactive?pushNotificationHandle=' +
                      window.flowinglyCordova.pushNotificationHandle;
                });
            }
          } else {
            loginFailed();
          }
        }

        return response;
      });
  }

  function getProfileDeferred() {
    return deferredProfile.promise;
  }

  function getUser() {
    if (sessionService.isOldUserVersion()) {
      user.ver = sessionService.getUserVersion(); // Need to do this so we dont repeatedly get the user details until it has happened

      return sessionService
        .getUserDetails(user.email)
        .then(function (response) {
          if (response === undefined) {
            user.ver = 0; //Invalid
          } else {
            if (response.data.success === true) {
              const userDetails = sessionService.formatUserForLocalStorage(
                response.data.dataModel
              );
              sessionService.setUser(userDetails);
              $rootScope.user = userDetails;
              user = userDetails;
              return user;
            } else {
              user.ver = 0; //Invalid
            }
          }
          return user;
        });
    } else return user;
  }

  function getUserDeferred() {
    return deferredUser.promise;
  }

  async function loginSuccessful(res) {
    authLoggingApiService.log('auth.service loginSuccessful() was called');

    if (res !== undefined) {
      const userDetails = sessionService.formatUserForLocalStorage(res);
      sessionService.setUser(userDetails);
      $rootScope.user = userDetails;
      user = userDetails;
      await userApiService.updateLoginState(true);
      deferredUser.resolve(userDetails);
    }
  }

  function loginFailed() {
    authLoggingApiService.log('auth.service loginFailed() called');
    authManager.unauthenticate();
    userProfile = null;
    user = null;
    sessionService.clear();
    flowListManager.clearLists();
    setTimeout(function () {
      authLoggingApiService.log(
        'auth.service loginFailed() about to call window.location.reload() in timeout of 3000'
      );
      window.location.reload();
    }, 3000);
  }

  /**
   * Show login alert message when user use Auth0 Lock Login box
   * @param message
   */
  function addAlertMessageToLoginScreen(message: string) {
    const authHeader =
      $window.document.getElementsByClassName('auth0-lock-header')[0];
    if (authHeader) {
      const element = document.createElement('div');
      element.style.backgroundColor = '#F46C42';
      element.style.color = 'white';
      element.style.padding = '1rem';
      element.textContent = message;
      authHeader.after(element);
    }
  }
}

export type AuthServiceType = ReturnType<typeof authService>;
