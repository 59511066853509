import * as angular from 'angular';

/*
 * Converted runner.enums.js to ts on 17/12/2019
 * To view the original version open this
 * https://bitbucket.org/flowingly-team/flowingly-source-code/src/3a5769f998d23e3923099dcb44996d7cf1dd02b1/src/Flowingly.Angular.Runner/Client/runner.enums.js
 */

/// This module contains modeler wide enums
///

export const Enums = {
  deleteEntityTypes: {
    userEntityType: 1,
    teamEntityType: 2,
    databaseEntityType: 3,
    categoryEntityType: 4,
    flowModelEntityType: 5,
    roleEntityType: 6
  },

  // Dynamic Actor Type Names
  dynamicActorTypeNames: {
    INITIATOR: 'Initiator',
    INITIATOR_MANAGER: 'InitiatorManager',
    INITIATOR_MANAGER_DISPLAY: 'Initiator Manager',
    PREVIOUS_ACTOR: 'PreviousActor',
    SELECT_DYNAMIC_ACTORS: 'SelectDynamicActors'
  },

  // Dynamic Actor Type Names
  dynamicActorTypeIds: {
    ASSIGNED_INITIATOR: 1,
    ASSIGNED_INITIATOR_MANAGER: 2,
    ASSIGNED_PREVIOUS_ACTOR: 3,
    SELECT_DYNAMIC_ACTORS: 4
  },

  loginAuditIdentityProvider: {
    UserAndPassword: 1,
    SSO: 2
  },
  loginAuditAction: {
    Login: 1,
    Logout: 2
  }
};

angular.module('flowingly.runner').constant('Enums', Enums);
