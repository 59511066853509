import React, { useEffect, useState } from 'react';
import ProcessMapsList from './List/ProcessMapsList';
import ProcessMapCategory from './Category/ProcessMapCategory';
import { useService } from '@Client/runner.hooks/useService';
import { SharedAngular } from '@Client/@types/sharedAngular';

type Props = {
  isMobile: boolean;
};

const ProcessMaps = (props: Props) => {
  const { isMobile } = props;

  const allCategoriesId = 'all-categories';
  const [selectedCategory, setSelectedCategory] =
    React.useState(allCategoriesId);

  const appConfig = useService<AppConfig>('APP_CONFIG');
  const flowListManager = useService<FlowListManager>('flowListManager');
  const sessionService =
    useService<SharedAngular.SessionService>('sessionService');
  const lodashService = useService<Lodash>('lodashService');
  const pubSubService =
    useService<SharedAngular.PubSubService>('pubsubService');

  const [allowProcessMap, setAllowProcessMap] = useState(false);
  const [categorisedProcessMaps, setCategorisedProcessMaps] = useState([]);
  const [processMaps, setProcessMaps] = useState([]);
  const [filteredProcessMaps, setFilteredProcessMaps] = useState([]);

  useEffect(() => {
    const processMapSubscriberId = 'flowingly.runner.processmap';
    pubSubService.subscribe(
      'SIGNALR_WORKFLOW_NAME_CHANGED',
      (event, params) => updateFlowModelName(params),
      processMapSubscriberId
    );
    pubSubService.subscribe(
      'SIGNALR_SETUP_CATEGORY_DELETED',
      onCategoryDeleted,
      processMapSubscriberId
    );
    pubSubService.subscribe(
      'SIGNALR_SETUP_FLOW_MODEL_DELETED',
      onFlowModelDeleted,
      processMapSubscriberId
    );
    pubSubService.subscribe(
      'SIGNALR_WORKFLOW_PUBLISHED',
      loadProcessMaps,
      processMapSubscriberId
    );
    pubSubService.subscribe(
      'SIGNALR_WORKFLOW_UNPUBLISHED',
      loadProcessMaps,
      processMapSubscriberId
    );
    pubSubService.subscribe(
      'SIGNALR_ACTOR_DELETED',
      (event, params) => removeDeletedActor(params),
      processMapSubscriberId
    );

    return () => {
      pubSubService.unsubscribeAll(processMapSubscriberId);
    };
  }, [categorisedProcessMaps, selectedCategory]);

  const fetchCategorizedProcessMaps = async () => {
    const categorisedProcessMaps =
      await flowListManager.getCategorizedProcessMaps();
    pubSubService.publish('PROCESSMAPV2_LOADED', {
      loaded: true,
      count: categorisedProcessMaps.length
    });
    setCategorisedProcessMaps(categorisedProcessMaps);
    setSelectedCategory(allCategoriesId);
  };

  const getProcesMapsFromCategorisedProcessmaps = () => {
    if (categorisedProcessMaps) {
      const processMaps = lodashService.flatMap(
        categorisedProcessMaps,
        'flowModels'
      );
      setProcessMaps(processMaps);
      setFilteredProcessMaps(processMaps);
    }
  };

  useEffect(() => {
    sessionService.onReady(() => {
      fetchCategorizedProcessMaps();
      if (appConfig) {
        setAllowProcessMap(appConfig.allowProcessMap);
      }
    });
  }, [sessionService, appConfig]);

  useEffect(() => {
    getProcesMapsFromCategorisedProcessmaps();
  }, [categorisedProcessMaps]);

  const handleCategoryClick = (name: string) => {
    setSelectedCategory(name);
    if (name === allCategoriesId) {
      setFilteredProcessMaps(processMaps);
    } else {
      const filteredMaps = processMaps.filter((map) => map.category === name);
      setFilteredProcessMaps(filteredMaps);
    }
  };

  const handleFlowModelClick = (id: string) => {
    pubSubService.publish('PROCESSMAPV2_CLICKED', id);
  };

  const updateFlowModelName = (jsonParams: string) => {
    if (!Array.isArray(categorisedProcessMaps)) {
      return;
    }

    const params = JSON.parse(jsonParams);
    let updatedCategoryIndex = -1;
    const updatedCategories = categorisedProcessMaps.map((category, index) => {
      const updatedFlowModels = category.flowModels.map((flowModel) => {
        if (flowModel.id === params.id) {
          return {
            ...flowModel,
            name: params.name
          };
        }
        return flowModel;
      });

      if (updatedFlowModels !== category.flowModels) {
        updatedCategoryIndex = index;
        return {
          ...category,
          flowModels: updatedFlowModels
        };
      }

      return category;
    });
    if (updatedCategoryIndex >= 0) {
      setCategorisedProcessMaps(updatedCategories);
      selectCategory(selectedCategory);
    }
  };

  const selectCategory = (categoryName: string) => {
    setSelectedCategory(
      categorisedProcessMaps.find(({ name }) => name === categoryName).name
    );
    setNullSelectedCategoryToAllCategory();
  };

  const setNullSelectedCategoryToAllCategory = () => {
    if (selectedCategory == allCategoriesId) {
      setSelectedCategory(allCategoriesId);
    }
  };

  const onCategoryDeleted = () => {
    fetchCategorizedProcessMaps();
  };

  const onFlowModelDeleted = () => {
    fetchCategorizedProcessMaps();
  };

  const loadProcessMaps = () => {
    fetchCategorizedProcessMaps();
  };

  const removeDeletedActor = (jsonParams: string) => {
    if (!Array.isArray(categorisedProcessMaps)) {
      return;
    }

    const params = JSON.parse(jsonParams);
    let updatedFlowModels = false;
    const updatedCategorisedProcessMaps = categorisedProcessMaps.map(
      (category) => {
        const updatedFlowModelsInCategory = category.flowModels.map(
          (flowModel) => {
            if (flowModel.processOwnerName === params.actorName) {
              updatedFlowModels = true;
              return { ...flowModel, processOwnerName: null };
            }
            return flowModel;
          }
        );
        return { ...category, flowModels: updatedFlowModelsInCategory };
      }
    );

    setCategorisedProcessMaps(updatedCategorisedProcessMaps);

    if (updatedFlowModels) {
      selectCategory(selectedCategory);
    }
  };

  return (
    <div className="categories-row row">
      {allowProcessMap && categorisedProcessMaps.length > 0 && (
        <div>
          <ProcessMapCategory
            data={categorisedProcessMaps}
            selectedCategory={selectedCategory}
            allCategoriesId={allCategoriesId}
            isMobile={isMobile}
            onCategoryClick={handleCategoryClick}
            onFlowModelListClick={handleFlowModelClick}
          />
          <div className={isMobile ? '' : 'cards-col col mt-20'}>
            {filteredProcessMaps && (
              <ProcessMapsList
                processMaps={filteredProcessMaps}
                isMobile={isMobile}
                onFlowModelClick={handleFlowModelClick}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProcessMaps;
