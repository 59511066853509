import { FlowinglyComments } from '@Client/runner.processmapsv2/bridges/FlowCommentsBridge';
import React from 'react';
import { Box } from '@mui/material';

export type Props = {
  commentList: string[];
  commentTargetId: string;
  commentTargetType: string;
};

const Feedback = (props: Props) => {
  const { commentList, commentTargetId, commentTargetType } = props;

  return (
    <Box className={'process-map-v2-view-feedback-flow-comments-container'}>
      <FlowinglyComments
        commentList={commentList}
        commentTargetId={commentTargetId}
        commentTargetTypeFromReact={commentTargetType}
        flowOwnerOnly={false}
        isMobile={false}
      ></FlowinglyComments>
    </Box>
  );
};
export default Feedback;
