'use strict';

import angular from 'angular';
import { BusinessSignup } from './signup.controller';

export class BusinessDetail {
  id: string;
  businessType: number;
  parentId: string;
  name: string;
  domainName: string;
  phoneNumber: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  addressLine4: string;
  postCode: string;
  city: string;
  country: string;
  address: string;
}

export class SettingDetail {
  name: string;
  value: string;
  comments: string;
  tags: string[];
  copy: boolean;
}

export default class SignupService {
  static $inject = ['$http', 'APP_CONFIG'];

  private apiBaseUri: string;

  constructor(private $http, private APP_CONFIG) {
    this.apiBaseUri = this.APP_CONFIG.apiBaseUrl + 'business';
  }

  //Note these teams are also setup in BusinessCommandService.cs!!!!! So you need to change them there also.
  public defaultTeams = [
    { name: 'Administration' },
    { name: 'Customer Support' },
    { name: 'Development' },
    { name: 'Devops' },
    { name: 'Finance' },
    { name: 'Human Resources' },
    { name: 'IT Services' },
    { name: 'Legal' },
    { name: 'Management' },
    { name: 'Marketing' },
    { name: 'Operations' },
    { name: 'Quality Assurance' },
    { name: 'Sales' },
    { name: 'Other' }
  ];

  public industries = [
    { name: 'Accounting' },
    { name: 'Construction' },
    { name: 'Consulting' },
    { name: 'Cloud Services Provider' },
    { name: 'Energy & Utilities' },
    { name: 'Engineering & Construction' },
    { name: 'Financial Services' },
    { name: 'Health/Medical' },
    { name: 'Hospitality' },
    { name: 'Human Resources' },
    { name: 'IT' },
    { name: 'Legal Services/Law Practice' },
    { name: 'Manufacturing' },
    { name: 'Marketing, Media & Entertainment' },
    { name: 'Non-Profit Organisation' },
    { name: 'Public Sector' },
    { name: 'Real Estate' },
    { name: 'Recruitment' },
    { name: 'Retail' },
    { name: 'Sales' },
    { name: 'Software Development' },
    { name: 'Tourism' },
    { name: 'Technology' },
    { name: 'Telecommunications' },
    { name: 'Transport & Logistics' },
    { name: 'Other' }
  ];

  public signupBusiness(signup: BusinessSignup) {
    return this.$http.post(this.apiBaseUri, signup);
  }

  public getTemplateBusinesses() {
    return this.$http
      .get(this.apiBaseUri + '/template')
      .then(function (response) {
        return response.data;
      });
  }

  public getExistingBusinesses(): Promise<BusinessDetail[]> {
    return this.$http.get(this.apiBaseUri).then(function (response) {
      return response.data;
    });
  }

  public getSignupSettings(businessId: string): Promise<SettingDetail[]> {
    return this.$http
      .get(`${this.apiBaseUri}/${businessId}/settings/signup`)
      .then(function (response) {
        return response.data;
      });
  }
}

angular.module('flowingly.signup').service('signupService', SignupService);
