/**
 * @ngdoc directive
 * @name flowHeader
 * @module flowingly.runner.flow
 * @description  This comppnent is used to display the area which the user clicks to hide /show a flow.
 * @usage
 * ```
    <flow-header ng-click="$ctrl.onClickMethod({ id: $ctrl.flow.Id })" flow="$ctrl.flow" is-mobile="$ctrl.isMobile"></flow-header>
 * ``` 
 * ### Notes
 * See Also: https://bizflo.atlassian.net/wiki/display/TECH/Angular+Flow+Components
 * ### Properties
 * #### Inputs
 * * flow: the flow data to display (JSON)
 * * isMobile: show mobile view if set
 */

'use strict';
import { RunnerFlowsFormatterService } from '@Client/runner.services/flows.formatter';
import angular from 'angular';

angular.module('flowingly.runner.flow').component('flowHeader', {
  bindings: {
    flow: '<',
    isMobile: '<' //show mobile view if set
  },

  controller: [
    'runnerFlowsFormatter',
    'APP_CONFIG',
    function (
      runnerFlowsFormatter: RunnerFlowsFormatterService,
      APP_CONFIG: AppConfig
    ) {
      const $ctrl = this;

      if (!$ctrl.flow) {
        return;
      }

      $ctrl.showConfidentialFlowOption = APP_CONFIG.showConfidentialFlowOption;

      // if converting to TS remember to replace this with @TODO Guid.Empty
      if (
        $ctrl.flow.FlowId == '00000000-0000-0000-0000-000000000000' ||
        $ctrl.flow.FlowId == null
      ) {
        $ctrl.startedByText = 'Started by';
      } else {
        $ctrl.startedByText = runnerFlowsFormatter.getStartedByText($ctrl.flow);
      }

      updateFileIcon();

      this.$onChanges = function (changes) {
        if (changes.flow && changes.flow.currentValue) {
          $ctrl.progress = changes.flow.currentValue.PercentageComplete;
          updateFileIcon();
        }
      };

      function updateFileIcon() {
        let count = 0;
        if ($ctrl.flow.Steps) {
          for (let i = 0; i < $ctrl.flow.Steps.length; i++) {
            const step = $ctrl.flow.Steps[i];
            for (let j = 0; j < step.Fields.length; j++) {
              const field = step.Fields[j];
              if (field.HasFiles) {
                count++;
              } else if (field.Type === 'table') {
                if (field.TableHasRows) {
                  const table = JSON.parse(field.Text);
                  angular.forEach(table.rows, function (row) {
                    angular.forEach(row.cells, function (cell) {
                      if (cell.type === 4) {
                        if (cell.value) {
                          count++;
                        }
                      }
                    });
                  });
                }
              }
            }
          }
          $ctrl.flow.FilesCount = count;
        }
      }
    }
  ],
  controllerAs: '$ctrl',
  templateUrl:
    'Client/runner.flow/runner.flow.header/runner.flow.header.component.tmpl.html'
});
