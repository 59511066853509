import * as angular from 'angular';

export class NonceService {
  public static ID = 'nonceService';
  public static $inject = ['$http', 'APP_CONFIG'];

  public constructor(private $http: angular.IHttpService, private APP_CONFIG) {}

  // maybe reusable in the future
  private apiEndpoint = 'reports/gettempcode';

  public getToken(): angular.IPromise<string> {
    return this.$http
      .get<string>(`${this.APP_CONFIG.apiBaseUrl}${this.apiEndpoint}`, {})
      .then((response) => {
        return response.data;
      });
  }
}

angular
  .module('flowingly.runner.services')
  .service(NonceService.ID, NonceService);
