/**
 * @ngdoc directive
 * @name runnerSetupCategories
 * @module flowingly.runner.setup
 * @description  This component is the root component for the setup categories area
 * @usage
 * ```
     
 * ``` 
 * ### Notes
 *
 * 
 * ### Properties
 * #### Inputs
 * * categories: the lsit of available categoriess (JSON[])
 */
(function () {
  'use strict';

  angular.module('flowingly.runner.setup').component('runnerSetupCategories', {
    bindings: {
      categories: '<'
    },
    templateUrl:
      'Client/runner.setup/runner.setup.categories/runner.setup.categories.tmpl.html',
    controller: [
      'categoryApiService',
      function (categoryApiService) {
        var $ctrl = this;

        $ctrl.handleCategoriesUpdated = handleCategoriesUpdated;

        function handleCategoriesUpdated() {
          // For an array that is bounded via one-way binding, a watch expression is added that does not check for object equality
          // but uses reference checking. This means that adding an element to the array will never fire the '$onChanges' method,
          // since the watcher will never be 'dirty'. Therefore, we use Angular copy ensure a new reference

          //get latest categories list
          $ctrl.categories = angular.copy(categoryApiService.categories);
        }
      }
    ]
  });
})();
