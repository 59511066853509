import angular, { IComponentOptions, IController } from 'angular';
import { SharedAngular } from '@Client/@types/sharedAngular';
class PageEmptyStateComponentController implements IController {
  static $inject = [
    '$state',
    'sessionService',
    'tempModelerUrlService',
    'flowsUtilityService',
    'permissionsService',
    'flowinglyConstants'
  ];
  private summary: string;
  private displayText: string;
  private buttonText: string;

  constructor(
    private $state: angular.ui.IStateService,
    private sessionService: SharedAngular.SessionService,
    private tempModelerUrlService: SharedAngular.TempModelerUrlService,
    private flowsUtilityService: SharedAngular.FlowsUtilityService,
    private permissionsService: SharedAngular.PermissionsService,
    private flowinglyConstants: SharedAngular.FlowinglyConstants
  ) {}

  $onInit() {
    const adminMode = this.permissionsService.currentUserHasPermission(
      this.flowinglyConstants.permissions.MODELER_ACCESS
    );
    const isMobileDevice = this.flowsUtilityService.isMobileDevice();

    switch (this.$state.current.name) {
      case 'app.runner.flowsactive':
        this.summary =
          'Hi ' + this.sessionService.getUser().fullName.split(' ')[0];
        this.displayText = adminMode
          ? 'Use Flowingly to easily map and automate your repetitive business processes.<br /> To start a Flow, you must have at least one Workflow published to you.'
          : 'To start a Flow, you must have at least one Workflow published to you. <br />Contact your administrator to have Flows shared with you.';
        this.buttonText =
          adminMode && !isMobileDevice ? 'Create and Publish a Flow' : '';
        break;

      case 'app.runner.flowstodo':
        this.summary = "Hooray, you're up to date!";
        this.displayText =
          "There are no steps requiring action from you right now that match your filter criteria.<br /> We'll notify you when there is. You can see the status of Flows that you are currently in by clicking 'Flows I'm in'.";
        this.buttonText = "See Flows I'm in";
        break;

      case 'app.runner.flowsin':
        this.summary = 'Looks like there is nothing here';
        this.displayText =
          "This means that there are no Flows you are involved in that match your filter criteria.<br /> To start a Flow, use the 'Start Flows' tab, or click the button below.";
        this.buttonText = 'Start a Flow';
        break;

      case 'app.runner.processmap':
        this.summary = adminMode
          ? 'You have no Process Maps'
          : 'View Process Maps';
        this.displayText = adminMode
          ? 'We make it easy for you to create, view and share your organizational Process Maps in one place.<br /> You do not currently have any Process Maps published.'
          : 'You do not currently have any Process Maps available to view. <br /> Please contact your administrator to have relevant Process Maps shared with you.';
        this.buttonText = adminMode ? 'Publish a Process Map' : '';
        break;

      case 'app.runner.processmapv2':
        this.summary = adminMode
          ? 'You have no Process Maps'
          : 'View Process Maps';
        this.displayText = adminMode
          ? 'We make it easy for you to create, view and share your organizational Process Maps in one place.<br /> You do not currently have any Process Maps published.'
          : 'You do not currently have any Process Maps available to view. <br /> Please contact your administrator to have relevant Process Maps shared with you.';
        this.buttonText = adminMode ? 'Publish a Process Map' : '';
        break;

      case 'app.runner.library':
        this.summary = adminMode ? 'Build your process Library' : '';
        this.displayText = adminMode
          ? 'Get started and create your first Flow Model. <br /> Choose to publish as a Process Map that can be viewed and shared with your team. <br /> Alternatively, include powerful automation, publish as a Workflow and start running your processes.'
          : '';
        this.buttonText = adminMode ? 'Add a Flow Model' : '';
        break;

      case 'app.runner.search':
        this.summary = '';
        this.displayText = 'No results found.';
        this.buttonText = '';
        break;
    }
  }

  onButtonClick(): void {
    switch (this.$state.current.name) {
      case 'app.runner.flowsactive':
      case 'app.runner.processmap':
      case 'app.runner.processmapv2':
      case 'app.runner.library':
        this.tempModelerUrlService.openModeler();
        break;

      case 'app.runner.flowstodo':
        this.$state.go('app.runner.flowsin');
        break;

      case 'app.runner.flowsin':
        this.$state.go('app.runner.flowsactive');
        break;
    }
  }
}

class PageEmptyStateComponent implements IComponentOptions {
  public bindings: Bindings;

  constructor() {
    this.bindings = {};
  }

  transclude = true;

  controller = PageEmptyStateComponentController;

  template = `
            <div class="empty-state align-center pb-30 pt-50" ng-cloak> 
                <div class="mt-50">
                    <h4 class="empty-state__summary">{{::$ctrl.summary}}</h4>
                    <h5 class="empty-state__text" ng-bind-html="$ctrl.displayText"></h5>
                    <br />
                    <button ng-if="$ctrl.buttonText !== ''" ng-click="$ctrl.onButtonClick()" class="btn green" tabindex="auto">{{::$ctrl.buttonText}}</button>
                </div>
            </div>
        `;
}

angular
  .module('flowingly.runner.directives')
  .component('runnerPageEmptyState', new PageEmptyStateComponent());
