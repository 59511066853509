import React from 'react';

type Props = {
  isMobile: boolean;
  data: FlowCategory[];
  allCategoriesId: string;
  selectedCategory: string;
  onCategoryClick: (categoryName: string) => void;
  onFlowModelListClick: (id: string) => void;
};

const ProcessMapCategory = (props: Props) => {
  const { data, isMobile, allCategoriesId, selectedCategory } = props;

  const handleCategoryClick = (categoryName: string) => {
    props.onCategoryClick(categoryName);
  };

  return (
    <div>
      {isMobile && (
        <div className="imin-groupsandfilters m-0 mr-30 row p-0 runner-contents-area mt-40">
          <div className="col s10 m6 l4 p-0">
            <div className="row valign-wrapper mt-10 mb-0 mr-0 ml-15">
              <select
                className="col s6 m6 l12 browser-default select"
                value={selectedCategory}
                onChange={(e) => handleCategoryClick(e.target.value)}
              >
                <option value={allCategoriesId}>All Categories</option>
                {data.map((category) => (
                  <option key={category.name} value={category.name}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      )}

      {!isMobile && (
        <div className="categories-col col ml-15">
          <h4>
            <b>Categories</b>
          </h4>
          {data.length > 0 && (
            <ul className="p-0 categories-list" id="categorylist">
              <li
                className={`pt-5 pb-5 categories-list-item ${
                  selectedCategory === allCategoriesId
                    ? 'selected-category'
                    : ''
                }`}
                title="All"
                onClick={() => handleCategoryClick(allCategoriesId)}
              >
                All
              </li>
              {data.map((category) => (
                <li
                  key={category.name}
                  className={`pt-5 pb-5 categories-list-item ${
                    selectedCategory === category.name
                      ? 'selected-category'
                      : ''
                  }`}
                  onClick={() => handleCategoryClick(category.name)}
                >
                  {category.name}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

export default ProcessMapCategory;
