import { useService } from '@Client/runner.hooks/useService';
import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { SharedAngular } from '@Client/@types/sharedAngular';
import CustomButton from '../CustomButton/CustomButton';
import ProcessMap from '@Client/runner.processmapsv2/@types/processMap';

const ProcessDetails = (props: ProcessMap) => {
  const {
    Id,
    isFlowModelOwner,
    processOwner,
    category,
    processReviewDate,
    description,
    objective,
    background,
    processInput,
    processOutput
  } = props;

  const tempModelerUrlService = useService<SharedAngular.TempModelerUrlService>(
    'tempModelerUrlService'
  );

  const permissionsService =
    useService<SharedAngular.PermissionsService>('permissionsService');

  const flowinglyConstants =
    useService<SharedAngular.FlowinglyConstants>('flowinglyConstants');

  const appConfig = useService<AppConfig>('APP_CONFIG');

  const isEdit =
    permissionsService.currentUserHasPermission(
      flowinglyConstants.permissions.FLOWMODEL_UPDATE
    ) ||
    (isFlowModelOwner && appConfig.fmoCanPublish);

  const onEditFlowModelHandler = () => {
    tempModelerUrlService.openModeler(Id, false, false, null);
  };

  const typographyWithEmptyString = (text: string, emptyString: string) => {
    if (text) {
      return <Typography paddingTop={1}>{text}</Typography>;
    } else {
      return (
        <Typography paddingTop={1} fontStyle="italic" color="#a9a9a9">
          {emptyString}
        </Typography>
      );
    }
  };

  const formatDate = (dateString: string) => {
    if (dateString == null) {
      return null;
    }
    const date = new Date(dateString);
    const day = date.toLocaleString('en-US', { day: '2-digit' });
    const month = date.toLocaleString('en-US', { month: 'short' });
    const year = date.toLocaleString('en-US', { year: 'numeric' });
    return `${day}-${month}-${year}`;
  };

  return (
    <Box
      width={'100vw'}
      height={'calc(100vh - 190px)'}
      paddingTop={'0px'}
      paddingLeft={'0px'}
      paddingRight={'70px'}
      marginTop={'-25px'}
    >
      <Grid
        container
        spacing={2}
        width={'100%'}
        padding={'20px'}
        className="process-map-view-v2-left-panel-process-detail-container"
      >
        <Grid item xs={4}>
          <Typography fontWeight={600}>Category</Typography>
          <Typography paddingTop={1}>{category}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography fontWeight={600}>Process Owner</Typography>
          {typographyWithEmptyString(
            processOwner,
            'Process Owner not yet defined'
          )}
        </Grid>
        <Grid item xs={4}>
          <Typography fontWeight={600}>Process Review Date</Typography>
          {typographyWithEmptyString(
            formatDate(processReviewDate),
            'Process Review Date not yet defined'
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography fontWeight={600}>Description</Typography>
          <Typography paddingTop={1}>{description}</Typography>
        </Grid>
        <Grid item xs={12} overflow="auto">
          <Typography fontWeight={600}>Objective</Typography>
          {typographyWithEmptyString(objective, 'Objective not yet defined')}
        </Grid>
        <Grid item xs={12} overflow="auto">
          <Typography fontWeight={600}>Background</Typography>
          {typographyWithEmptyString(background, 'Background not yet defined')}
        </Grid>
        <Grid item xs={4} overflow="auto">
          <Typography fontWeight={600}>Process Input</Typography>
          {typographyWithEmptyString(
            processInput,
            'Process Input not yet defined'
          )}
        </Grid>
        <Grid item xs={4} overflow="auto">
          <Typography fontWeight={600}>Process Output</Typography>
          {typographyWithEmptyString(
            processOutput,
            'Process Output not yet defined'
          )}
        </Grid>
        <Grid item xs={12} display={'flex'} justifyContent={'flex-end'}>
          {isEdit ? (
            <CustomButton
              buttonText="Edit Flow Model"
              onClick={onEditFlowModelHandler}
            />
          ) : (
            <CustomButton
              buttonText="View Flow Model"
              onClick={onEditFlowModelHandler}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProcessDetails;
