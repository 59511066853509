/**
 * @ngdoc type
 * @module flowingly.runner.flows.imin
 * @name runnerFlowsIminController
 *
 * @description
 */

'use strict';
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular, { IScope, ITimeoutService } from 'angular';

angular
  .module('flowingly.runner.flows.imin')
  .controller('runnerFlowsIminController', runnerFlowsIminController);

runnerFlowsIminController.$inject = [
  '$scope',
  'flowListManager',
  'authService',
  'pubsubService',
  'devLoggingService',
  '$timeout',
  'APP_CONFIG',
  'permissionsService',
  'flowinglyConstants'
];

function runnerFlowsIminController(
  $scope: IScope,
  flowListManager: FlowListManager,
  authService: AuthService,
  pubsubService: SharedAngular.PubSubService,
  logger: SharedAngular.DevLoggingService,
  $timeout: ITimeoutService,
  APP_CONFIG: AppConfig,
  permissionsService: SharedAngular.PermissionsService,
  flowinglyConstants: SharedAngular.FlowinglyConstants
) {
  const ctrl = this;

  ctrl.$onInit = () => {
    init();
  };

  //-----------------PRIVATE METHODS----------------------------
  function init() {
    const user = authService.getUser();
    ctrl.userId = user.id;
    ctrl.canStartFlows = permissionsService.currentUserHasPermission(
      flowinglyConstants.permissions.FLOW_START
    );
    ctrl.groups = flowListManager.groupedFlowsImin;
    ctrl.categories = flowListManager.flowImInCategories;
    ctrl.showDefaultMessage = showDefaultMessage;
    ctrl.changeCategoryFilter = changeCategoryFilter;
    ctrl.changedFlowFilter = changedFlowFilter;
    ctrl.selectedCategory = flowListManager.getIminFlowCategory();
    ctrl.allCategoriesId = 'all-categories';
    ctrl.categoryClicked = categoryClicked;
    ctrl.tabStrip = null;
    ctrl.initiating = true;

    ctrl.pageDescription = 'A list of all flows started by you.';

    ctrl.tabOptions = {
      scrollable: false,
      select: function (e) {
        let changed = false;

        if (e.item.innerText.trim() === 'All flows') {
          if (flowListManager.getOnlyStartedByMe()) {
            flowListManager.setOnlyStartedByMe(false);
            changed = true;
          }

          // set page description and help link
          ctrl.pageDescription = 'A list of all flows you are involved in.';
        } else {
          if (!flowListManager.getOnlyStartedByMe()) {
            flowListManager.setOnlyStartedByMe(true);
            changed = true;
          }

          // set page description and help link
          ctrl.pageDescription = 'A list of all flows started by you.';
        }
        if (changed) {
          refreshFlows().then(function () {
            selectedCategoryOption();
          });
        }
      }
    };

    if (ctrl.groups.length === 0) {
      logger.log('Imin controller: no imin flows, so go to API to get them.');
      refreshFlows().then(() => {
        selectedCategoryOption();
      });
    } else {
      selectedCategoryOption();
      flowListManager.applyFlowIminFilter();
      ctrl.initiating = false;
      setFilterOptions();
    }

    const flowsImInSubscriberId = 'runnerFlowsIminController';
    pubsubService.subscribe(
      'SIGNALR_WORKFLOW_NAME_CHANGED',
      onFlowModelNameChanged,
      flowsImInSubscriberId
    );
    $scope.$on('$destroy', () => {
      pubsubService.unsubscribeAll(flowsImInSubscriberId);
    });

    $scope.$on('kendoWidgetCreated', function (ev, widget) {
      if (widget === ctrl.tabStrip) {
        const onlyStartedByMe = flowListManager.getOnlyStartedByMe();

        if (onlyStartedByMe && ctrl.canStartFlows) {
          ctrl.tabStrip.select(0);
        } else {
          ctrl.tabStrip.select(1);
        }
      }
    });
  }

  // After retrieving the flow list from server wide we need to reset the Flow Filter DropDown and the Flow Status Dropdown
  function setFilterOptions() {
    setFlowFilters();
    ctrl.statusOptions = flowListManager.statusOptions;
    ctrl.selectedFlowFilter = flowListManager.getIminFlowFilter();
    ctrl.selectedStatusOption = flowListManager.getIminStatus();
  }
  // truncate lengthy flownames for displaying flow filter dropdown to fit in the screen. tooltip will show full flow name
  function setFlowFilters() {
    ctrl.flowFilters = [];
    const maxchars = APP_CONFIG.flowFilterMaxCharacters;
    flowListManager.getIminFlowFilters().forEach((filter) => {
      let fname = filter.name;
      if (fname.length > maxchars) {
        fname = fname.substring(0, maxchars) + '...';
      }
      const flowFilter = {
        id: filter.id,
        name: fname,
        title: filter.name
      };
      ctrl.flowFilters.push(flowFilter);
    });
  }

  function refreshFlows() {
    return flowListManager.refreshFlowsImin().then(function () {
      setFilterOptions();
      ctrl.initiating = false;
    });
  }

  function onFlowModelNameChanged(msg, data) {
    const msgdata = JSON.parse(data);
    flowListManager.updateFlowNames(msgdata);
    ctrl.groups = flowListManager.groupedFlowsImin;
  }

  //-----------------PUBLIC METHODS----------------------------
  ctrl.changeStatusOption = function () {
    flowListManager.setIminStatus(ctrl.selectedStatusOption);
    //refreshing the categories will unselect the highlighted category because ctrl.categories will get modified in category refresh
    refreshFlows().then(() => {
      selectedCategoryOption();
    });
  };

  function changedFlowFilter() {
    flowListManager.setIminFlowFilter(ctrl.selectedFlowFilter);
    flowListManager.applyFlowIminFilter();

    const visibleFlows = ctrl.groups.filter((group) => group.show);
    if (visibleFlows && visibleFlows.length > 0) {
      // All the visible flows are for the same flow model which belongs to one flow category.
      //here we dont need to set the flow filter to default. so defaultFlowFilterRequired is set to false
      flowListManager.setIminFlowCategory(
        visibleFlows[0].FlowCategoryId,
        false
      );
      selectedCategoryOption();
    }
  }

  function changeCategoryFilter() {
    flowListManager.setIminFlowCategory(ctrl.selectedCategory);
    setFilterOptions();
    flowListManager.applyFlowIminFilter();
  }

  function showDefaultMessage() {
    const hasVisibleGroup = ctrl.groups.some((g) => {
      return g.show;
    });

    return !hasVisibleGroup;
  }

  function categoryClicked(categoryName) {
    const category = ctrl.categories.find(({ name }) => name === categoryName);
    if (category) {
      ctrl.selectedCategory = category.id;
      highlightSelectedCategory(category.name);
    } else {
      ctrl.selectedCategory = null; // All Categories.
      highlightSelectedCategory(ctrl.allCategoriesId);
    }

    changeCategoryFilter();
  }

  function selectedCategoryOption() {
    $timeout(function () {
      const category = ctrl.categories.find(
        ({ id }) => id === flowListManager.getIminFlowCategory()
      );
      if (category) {
        highlightSelectedCategory(category.name);
      } else {
        highlightSelectedCategory(ctrl.allCategoriesId);
      }
    });
  }

  function highlightSelectedCategory(categoryName) {
    const selectedCategoryClassName = 'selected-category';
    // First unselect all.
    const allSelected = document.getElementsByClassName(
      selectedCategoryClassName
    );
    if (allSelected) {
      Array.from(allSelected).forEach((elem) => {
        elem.classList.remove(selectedCategoryClassName);
      });
    }
    // Select the category.
    if (categoryName != null && categoryName !== '') {
      const categoryNameElement = document.getElementById(categoryName);
      if (categoryNameElement) {
        categoryNameElement.classList.toggle(selectedCategoryClassName);
      }
    }
  }
}
