/**
 * @ngdoc directive
 * @name teamsList
 * @module flowingly.runner.setup
 * @description This comppnent is used to display the list of teams
 * @usage
 * ```
     <teams-list teams="$ctrl.teams" on-teams-updated="$ctrl.handleTeamsUpdated()"></teams-list>
 * ``` 
 * ### Notes
 * See Also: 
 * ### Properties
 * #### Inputs
 * * teams: the list of available teams (JSON[])
 */
(function () {
  'use strict';

  angular.module('flowingly.runner.setup').component('teamsList', {
    bindings: {
      teams: '<',
      onTeamsUpdated: '&'
    },
    templateUrl:
      'Client/runner.setup/runner.setup.teams/runner.setup.teams.list.tmpl.html',
    controller: [
      '$scope',
      'APP_CONFIG',
      'teamApiService',
      function ($scope, APP_CONFIG, teamApiService) {
        let $ctrl = this;

        $ctrl.options = {
          dataSource: {
            transport: {
              read: function (options) {
                teamApiService
                  .getTeamsWithOptions(options.data)
                  .then((response) => {
                    options.success({
                      data: response.teams,
                      total: response.total
                    });
                    $ctrl.gridNeedResize = true;
                  });
              }
            },
            schema: {
              data: 'data',
              total: 'total'
            },
            pageSize: APP_CONFIG.gridReportPageSize,
            serverPaging: true,
            serverSorting: true,
            serverFiltering: true,
            serverGrouping: true,
            serverAggregates: true
          },
          noRecords: {
            template: '<teams-no-results></teams-no-results>'
          },
          scrollable: true,
          pageable: true,
          sortable: true,
          resizable: true,
          filterable: {
            mode: 'row'
          },
          columns: getColumns()
        };

        $ctrl.handleTeamsUpdated = () => {
          $scope.teamsGrid.dataSource.read();
        };

        $ctrl.$onChanges = function (changes) {
          if (!changes.teams.isFirstChange()) {
            $scope.teamsGrid.dataSource.read();
            $ctrl.gridNeedResize = true;
          }
        };

        $ctrl.gridInitDone = true;

        /// PRIVATE METHODS //////////////////////////////////

        function getColumns() {
          //Note: field needs to match the name (and case) of the returned data
          return [
            {
              field: 'name',
              title: 'Name',
              filterable: {
                cell: {
                  operator: 'contains', //default filtering uses 'equal to' operator, we want to use contains.
                  showOperators: false
                }
              }
            },
            {
              field: 'email',
              title: 'Email',
              filterable: false
            },
            {
              field: 'ppoc',
              title: 'Primary Point Of Contact',
              filterable: false
            },
            {
              field: 'usersCount',
              title: 'Users',
              filterable: false
            },
            {
              title: 'Actions',
              width: '120px',
              filterable: false,
              template:
                "<teams-list-actions team='dataItem' on-teams-updated='$ctrl.handleTeamsUpdated()'></teams-list-actions>"
            }
          ];
        }
      }
    ]
  });
})();
