/**
 * @ngdoc directive
 * @name flowLibrary
 * @module flowingly.runner.library
 * @description  This is the ROOT level SMART comppnent.
 *               It is responsible for updating the data in the list
 * @usage
 * ```
      <flow-library></flow-library>
 * ``` 
 * NOTE: Minimal content for now, but this might grow
 */
'use strict';
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular from 'angular';

angular.module('flowingly.runner.library').component('flowLibrary', {
  bindings: {
    flowModels: '<' //this will come from route resolve
  },
  templateUrl: 'Client/runner.library/runner.library.tmpl.html',
  controller: [
    'sessionService',
    'flowModelApiService',
    'APP_CONFIG',
    'tokenService',
    function (
      sessionService: SharedAngular.SessionService,
      flowModelApiService: FlowModelApiService,
      APP_CONFIG: AppConfig,
      tokenService: SharedAngular.TokenService
    ) {
      const $ctrl = this;

      $ctrl.businessName =
        tokenService.getTenant().name || sessionService.getUser().businessName;
      $ctrl.onListUpdated = onListUpdated;

      sessionService.onReady(() => {
        $ctrl.canCreateFlows = !APP_CONFIG.disableFlowModelCreation;
      });

      function onListUpdated(needResizeGrid) {
        getFlowModels().then(function () {
          if (needResizeGrid) {
            $ctrl.gridNeedResize = true;
          }
        });
      }

      function getFlowModels() {
        return flowModelApiService.getFlowModels().then(function (models) {
          const updatedModel = angular.copy(models);
          $ctrl.flowModels = updatedModel;
          return;
        });
      }
    }
  ]
});
