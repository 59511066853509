/**
 * @ngdoc service
 * @name categoryApiService
 * @module flowingly.runner.services
 *
 * @description A service responsible solely for fetching categories data
 *
 * ## Notes
 * No Caching or formatting to be performed here.
 *
 */

'use strict';
import angular from 'angular';
/// i.e. we return response.data.DataModel so the consumer can work with the data directly

angular
  .module('flowingly.runner.services')
  .factory('categoryApiService', categoryApiService);

categoryApiService.$inject = ['$http', 'APP_CONFIG'];

function categoryApiService(
  $http: angular.IHttpService,
  APP_CONFIG: AppConfig
) {
  const service = {
    categories: [],
    addCategory: addCategory,
    editCategory: editCategory,
    deleteCategory: deleteCategory,
    getCategories: getCategories
  };

  return service;

  //////////// Public API Methods
  function addCategory(category) {
    return $http.post(APP_CONFIG.apiBaseUrl + `categories/`, category);
  }

  function editCategory(category) {
    return $http.put(APP_CONFIG.apiBaseUrl + `categories/`, category);
  }

  function deleteCategory(categoryId, replaceCategoryId) {
    //send complex object in delete request
    return $http({
      url: APP_CONFIG.apiBaseUrl + 'categories/',
      method: 'DELETE',
      data: {
        categoryId: categoryId,
        replaceCategoryId: replaceCategoryId
      },
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      }
    });
  }

  function getCategories(noSpinner = false) {
    return $http
      .get<IResponseData>(APP_CONFIG.apiBaseUrl + 'categories', {
        noSpinner: noSpinner || false
      })
      .then(function (response) {
        angular.copy(response.data.dataModel, service.categories);
        return response.data.dataModel;
      });
  }

  // PRIVATE METHODS /////////////////////////////////////////////////////////////////
}

export type CategoryApiServiceType = ReturnType<typeof categoryApiService>;
