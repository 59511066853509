import angular from 'angular';
import RunnerFlowService from './runner.flow.service';
import { IFormattedFlow } from '../../interfaces/IFormattedFlow';
import { IStateProvider } from 'angular-ui-router';

///
/// This state displays flows that the user can initiaite
///

// Register the application's routes configuration.
angular.module('flowingly.runner.flow').config(config);

// Inject the dependencies.
config.$inject = ['$stateProvider'];

const GUID_EMPTY = '00000000-0000-0000-0000-000000000000';

function config($stateProvider: IStateProvider) {
  $stateProvider.state('app.runner.flow', {
    url: 'flows/{flowId}?showComments&flowModelId&stepTask',
    params: { title: 'Flow Details', searchJump: false },
    controller: 'runnerFlowController',
    controllerAs: '$ctrl',
    templateUrl: 'Client/runner.flow/runner.flow/runner.flow.tmpl.html',
    resolve: {
      flow: [
        '$stateParams',
        'flowListManager',
        '$q',
        'workflowApiService',
        'sessionService',
        'runnerFlowService',
        function (
          $stateParams,
          flowListManager,
          $q: angular.IQService,
          workflowApiService,
          sessionService,
          runnerFlowService: RunnerFlowService
        ) {
          runnerFlowService.resetBusy();

          const flowId = $stateParams.flowId;
          if (flowId == GUID_EMPTY) {
            const flowModelId = $stateParams.flowModelId;

            return $q
              .all([
                workflowApiService.getById(flowModelId),
                sessionService.getUser()
              ])
              .then(([flowModel, currentUser]) => {
                const placeholder =
                  flowModel.customPlaceholder != null &&
                  flowModel.customPlaceholder != ''
                    ? flowModel.customPlaceholder
                    : 'Enter a descriptive subject';
                return <IFormattedFlow>{
                  FlowModelId: flowModelId,
                  FlowId: GUID_EMPTY,
                  FlowIdentifier: 'FLOW-',
                  MomentDue: '',
                  DueDate: '',
                  Subject: '...',
                  CurrentStepsHeader: 'Enter Subject',
                  Name: flowModel.name,
                  StartedByName: currentUser.fullName,
                  PercentageComplete: 0,
                  WaitingForName: currentUser.fullName,
                  CustomPlaceholder: placeholder
                };
              });
          } else {
            return runnerFlowService.getFlowById(flowId);
          }
        }
      ]
    }
  });
}
